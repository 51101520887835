import { Injectable, Inject } from '@angular/core';
import { CRUDService } from '../crud/crud.service';
import { HttpClient } from '@angular/common/http';
import { IStorageService, IUserCredentials } from '../../models/interfaces';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LibraryConfigService, LibraryConfig } from '../../../config';


@Injectable({
  providedIn: 'root'
})
export class AuthApiClientService extends CRUDService<any> {
  static urlClassName = 'rest-auth';
  storage: IStorageService;

  get isLoggedIn() { return !!this.storage.sessionToken }
  get sessionToken() { return this.storage.sessionToken }

  isLoggedInEvent: Subject<boolean> = new Subject<boolean>();
  isLoggedOutEvent: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected override http: HttpClient,
    protected router: Router,
    @Inject(LibraryConfigService) protected override config: LibraryConfig
  ) {
    super(http, AuthApiClientService.urlClassName, config);
    this.storage = config.storage!;
  }

  submitLogin(userCredentials: IUserCredentials) {
    const urlPostfix = 'login'
    return this.postItem(userCredentials, urlPostfix);
  }

  emitSuccessfulLogin() {
    this.isLoggedInEvent.next(true);
  }

  saveSessionToken(token: string) {
    this.storage.sessionToken = token;
  }

  saveExpirationDate(time: number) {
    let expiresInMilliseconds = time * 1000; // transform time to milliseconds
    let now = new Date(Date.now()); // get actual date and time
    this.storage.expirationDate = new Date(now.getTime() + expiresInMilliseconds);
  }

  redirectTo(url: string) {
    this.router.navigate([url]);
  }

  scheduleLogout() {
    let expirationDate = this.storage.expirationDate;
    let now = new Date(Date.now());
    if (expirationDate > now) {
      let timeout = expirationDate.getTime() - now.getTime();
      this.logoutAfterHours(timeout);
    } else {
      this.logout();
    }
  }

  logoutAfterHours(timeout: number) {
    return setTimeout(() => this.logout(), Math.floor(timeout))
  }

  emitSuccessfulLogout() {
    this.isLoggedOutEvent.next(true);
  }

  logout() {
    const body = {};
    const urlPostfix = 'logout';
    this.postItem(body, urlPostfix).subscribe(r => {
      this.emitSuccessfulLogout();
      this.storage.deleteSessionData();
    })
  }

  /**
   * This method should be called in app constructor
   */
  redirectAfterLoginTo(path: string = "/", unsubscribe: boolean = false) {
    let subEventLogin = this.isLoggedInEvent.subscribe(e => {
      this.redirectTo(path);
      if (unsubscribe) {
        subEventLogin.unsubscribe();
      }
    })
  }

  /**
   * This method should be called in app constructor
   */
  redirectAfterLogOutTo(path: string = "/", unsubscribe: boolean = false) {
    let subEventLogin = this.isLoggedOutEvent.subscribe(e => {
      this.redirectTo(path);
      if (unsubscribe) {
        subEventLogin.unsubscribe();
      }
    })
  }

}
