// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.container {
  display: flex;
  align-items: center;
}
.container ion-thumbnail {
  --size: 29px;
  --border-radius: 100%;
  background: var(--ion-color-background);
  border: 1px solid var(--ion-color-lines);
  margin-right: 10.7px;
}
.container ion-thumbnail.with-no-image {
  padding: 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/sections/avatar-name-section/avatar-name-section.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACA,YAAA;EACA,qBAAA;EACA,uCAAA;EACE,wCAAA;EACA,oBAAA;AACJ;AACE;EACC,YAAA;AACH","sourcesContent":[":host {\n  display: block;\n}\n\n.container {\n  display: flex;\n  align-items: center;\n\n  ion-thumbnail {\n\t\t--size: 29px;\n\t\t--border-radius: 100%;\n\t\tbackground: var(--ion-color-background);\n    border: 1px solid var(--ion-color-lines);\n    margin-right: 10.7px;\n\n\t\t&.with-no-image {\n\t\t\tpadding: 7px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
