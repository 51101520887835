import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

	public alertEvent = new Subject<any>();
	public alertIsPresent = false;

	constructor() {}

	showCustom(header: string, subheader: string, message: string) {
		this.alertIsPresent = true;
		this.alertEvent.next({
			header,
			subheader,
			message,
			buttons: [
				{
					text: 'Ok',
					handler: () => {
						console.log('Confirm Okay');
						this.alertIsPresent = false;
					},
				},
			],
		});
	}
}
