// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  background: var(--ion-color-white);
  z-index: 5;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 15px 24px 40px;
}
.modal-container ion-icon {
  height: 100%;
  max-height: 25px;
  cursor: pointer;
  font-size: 25px;
  margin-left: -8px;
  margin-bottom: 6px;
}
.modal-container .title {
  line-height: 41px;
  margin-bottom: 14px;
}
.modal-container .subtitle {
  height: 100%;
  min-height: 60px;
  margin-bottom: 5px;
}
.modal-container .buttons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.modal-container .buttons merz-button:first-of-type {
  margin-bottom: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/poll-modal/poll-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,kBAAA;EACA,SAAA;EACA,kCAAA;EACA,UAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;AACF;AACE;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAEE;EACE,iBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,YAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;AAFJ;AAKM;EACE,mBAAA;AAHR","sourcesContent":[".modal-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  position: absolute;\n  bottom: 0;\n  background: var(--ion-color-white);\n  z-index: 5;\n  overflow: auto;\n  width: 100%;\n  height: 100%;\n  padding: 15px 24px 40px;\n\n  ion-icon {\n    height: 100%;\n    max-height: 25px;\n    cursor: pointer;\n    font-size: 25px;\n    margin-left: -8px;\n    margin-bottom: 6px;\n  }\n\n  .title {\n    line-height: 41px;\n    margin-bottom: 14px;\n  }\n\n  .subtitle {\n    height: 100%;\n    min-height: 60px;\n    margin-bottom: 5px;\n  }\n\n  .buttons {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n\n    merz-button {\n      &:first-of-type {\n        margin-bottom: 14px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
