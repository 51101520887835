import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'merz-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {

  @Input() text = '';
  @Input() disabled = false;
  @Input('color') offColor = 'transparent';
  @Input() shape = 'normal';
  @Input('border-type') offBorderType = 'none';
  @Input('text-color') offTextColor = 'medium';
  @Input('text-size') offTextSize = '16';
  @Input('text-weight') offTextWeight = 'regular';
  @Input() on = false;
  @Input('on-color') onColor = '';
  @Input('on-border-type') onBorderType = '';
  @Input('on-text-color') onTextColor = '';
  @Input('on-text-size') onTextSize = '';
  @Input('on-text-weight') onTextWeight = '';
  @Input('is-small') isSmall = false;

  constructor() { }

  ngOnInit() { }

  get color() {
    return this.getVariableUsingOn(this.onColor, this.offColor);
  }

  get borderType() {
    return this.getVariableUsingOn(this.onBorderType, this.offBorderType);
  }

  get textColor() {
    return this.getVariableUsingOn(this.onTextColor, this.offTextColor);
  }

  get textSize() {
    return this.getVariableUsingOn(this.onTextSize, this.offTextSize);
  }

  get textWeight() {
    return this.getVariableUsingOn(this.onTextWeight, this.offTextWeight);
  }

  getVariableUsingOn(onValue, offValue) {
    if (!this.on || !onValue) {
      return offValue;
    }
    return onValue;
  }

}
