import { Injectable } from '@angular/core';
import { Poll } from 'src/app/models/poll';
import { PollApiClientService } from '../api/poll-api-client/poll-api-client.service';
import { PollsService } from '../polls-service/polls-service.service';
import { NavController } from '@ionic/angular';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { BaseDetailResolver } from './base-detail.resolver';

@Injectable({
  providedIn: 'root'
})
export class PollResolver extends BaseDetailResolver<Poll> {
  protected override idParam = 'pollId';

  constructor(
    protected service: PollApiClientService,
    protected pollsService: PollsService,
    protected navController: NavController
  ) {
    super();
  }

  override resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const pollId =
    route.paramMap.get(this.idParam) || route.queryParamMap.get(this.idParam);

    return this.service.get(pollId!).pipe(
      map((poll: Poll) => {
        this.pollsService.poll = poll;
      }),
      catchError((error) => {
        this.navController.navigateBack([MERZ_ROUTES.homeComplete]);
        return of(undefined);
      })
    );
  }
}
