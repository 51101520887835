import { Component, OnInit, Input } from '@angular/core';
import { SubmenuItem } from '../../models/submenu-item/submenu-item';
import { Router } from '@angular/router';

@Component({
  selector: 'dnest-core-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  host: {
    "(window:click)": "onWindowClick()"
  }
})
export class SubmenuComponent implements OnInit {
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";

  @Input('options') options: SubmenuItem[] = [];
  @Input('open') open: boolean = false;
  @Input('item') item: any;

  static submenuOpen: SubmenuComponent;

  constructor(
    protected router: Router
  ) { }

  ngOnInit() {
  }

  public toggleMenu(event: any) {
    event.stopPropagation();
    this.open = !this.open;

    if (this.open && SubmenuComponent.submenuOpen && SubmenuComponent.submenuOpen != this) {
      SubmenuComponent.submenuOpen.open = false;
    }
    SubmenuComponent.submenuOpen = this;
  }

  onWindowClick() {
    this.open = false;
  }

  onClick(option: SubmenuItem) {
    this.open = false;
    if (option.callbackFn) {
      option.callbackFn(this.item);
    }
    if (option.routerLink) {
      this.router.navigate(option.routerLink);
    }
  }

}
