import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Filter, FilterType } from 'src/app/models/filter';
import { KamUserApiClientService } from 'src/app/services/api/kam-user-api-client/kam-user-api-client.service';
import { PlanApiClientService } from 'src/app/services/api/plan-api-client/plan-api-client.service';
import { UserWorkCenterApiClientService } from 'src/app/services/api/user-work-center-api-client/user-work-center-api-client.service';
import { FiltersService } from 'src/app/services/filters-service/filters.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { FiltersGetOptionsService } from 'src/app/services/filters-get-options-service/filters-get-options.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'merz-filter-users',
  templateUrl: './filter-users.component.html',
  styleUrls: ['./filter-users.component.scss'],
})
export class FilterUsersComponent implements OnInit {
  filters: Filter[];
  routerEvent: Subscription;

  constructor(
    private modalController: ModalController,
    protected userWorkCenterApiClientService: UserWorkCenterApiClientService,
    protected planApiClientService: PlanApiClientService,
    protected kamUserApiClientService: KamUserApiClientService,
    protected filtersService: FiltersService,
    protected router: Router,
    protected filtersGetOptionsService: FiltersGetOptionsService,
    protected translateService: TranslateService
  ) {
    this.routerEvent = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tryCreateFilters();
      }
    });
  }

  ngOnInit() {
    this.tryCreateFilters();
  }

  tryCreateFilters() {
    if (
      this.filtersGetOptionsService?.planOptions?.length > 0 &&
      this.filtersGetOptionsService?.userWorkCenterOptions?.length > 0
    ) {
      this.createFilters();
    } else {
      setTimeout(() => {
        this.tryCreateFilters();
      }, 100);
    }
  }

  get filterParams() {
    return this.filtersService.filterParams;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  createFilters() {
    this.filters = [
      new Filter({
        type: FilterType.CHECKBOX,
        field: 'registered',
        options: [{ text: this.translateService.instant('FILTERS.USERS.REGISTERED.OPTIONS.COMPLETED'), value: 'true' }],
        title: this.translateService.instant('FILTERS.USERS.REGISTERED.TITLE'),
        optionSelectedValue: this.filterParams['registered'],
      }),
      new Filter({
        type: FilterType.CHECKBOX,
        field: 'pending_action',
        options: [{ text: this.translateService.instant('FILTERS.USERS.PENDING_ACTION'), value: 'true' }],
        optionSelectedValue: this.filterParams['pending_action'],
      }),
      new Filter({
        type: FilterType.CHECKBOX,
        field: 'plan',
        options: this.filtersGetOptionsService.planOptions,
        title: this.translateService.instant('FILTERS.USERS.PLAN'),
        optionSelectedValue: this.filterParams['plan'],
      }),
      new Filter({
        type: FilterType.SELECT,
        field: 'workcenter',
        options: this.filtersGetOptionsService.userWorkCenterOptions,
        title: this.translateService.instant('FILTERS.USERS.WORKCENTER.TITLE'),
        placeholder: this.translateService.instant('FILTERS.USERS.WORKCENTER.PLACEHOLDER'),
        optionSelectedValue: this.filterParams['workcenter'],
      }),
      new Filter({
        type: FilterType.CHECKBOX,
        field: 'career',
        options: [
          { text: this.translateService.instant('FILTERS.USERS.CAREER.OPTIONS.MS'), value: 'MS' },
          {  text: this.translateService.instant('FILTERS.USERS.CAREER.OPTIONS.NMS'), value: 'NMS' },
        ],
        title: this.translateService.instant('FILTERS.USERS.CAREER.TITLE'),
        optionSelectedValue: this.filterParams['career'],
      }),
      new Filter({
        type: FilterType.CHECKBOX,
        field: 'master_course',
        options: [{ text: this.translateService.instant('FILTERS.USERS.MASTER.OPTIONS.STUDENT'), value: 'true' }],
        title: this.translateService.instant('FILTERS.USERS.MASTER.TITLE'),
        optionSelectedValue: this.filterParams['master_course'],
      }),
    ];
  }

  get buttonText() {
    // return 'Ver Resultados';
    return this.translateService.instant('FILTERS.USERS.SEE_RESULTS');
  }

  goToUsers() {
    this.dismiss();
  }

  cleanFilters() {
    this.filtersService.deleteFilters();
  }
}

