import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Paginator } from './paginator';
import { Manager } from './manager.service';

export class Query<T extends { id: string }> {
  constructor(
    protected manager: Manager<T>,
    protected filterParams: any = {},
    protected excludeParams: any = {},
    protected orderByFields: [] = [],
    protected pageSize: any =  undefined,
    protected page: number = 1,
  ) {
  }
	clone() {
		return new Query<T>(this.manager, Object.assign({}, this.filterParams), Object.assign({}, this.excludeParams), Object.assign([], this.orderByFields), this.pageSize, this.page);
	}

	addFilterParams(filterParams: any) {
		Object.assign(this.filterParams, filterParams);
	}

	addExcludeParams(excludeParams: any) {
		Object.assign(this.excludeParams, excludeParams);
	}

	addOrderByFields(orderByFields: any) {
		this.orderByFields = orderByFields;
	}

	setPageSize(pageSize: number) {
		this.pageSize = pageSize;
	}

	setPage(page: number) {
		this.page = page;
	}

	removeCamelCase(params: any) {
		const newParams = {};
		for (let key in params) {
			let value = params[key];
			key = key.replace(/([A-Z])/g, (group) => '_' + group.toLowerCase());
			(newParams as any)[key] = value;
		}
		return newParams;
	}

	getParams(getQuery: boolean = false) {
		let params = Object.assign({}, this.filterParams);
		for (let key in this.excludeParams) {
			let value = this.excludeParams[key];
			params['ex__' + key] = value;
		}

		if (getQuery) {
			return params;
		}
		if (this.pageSize) {
			params['pageSize'] = this.pageSize;
		}
		params['page'] = this.page;
		params['ordering'] = this.orderByFields.join(',');
		return this.removeCamelCase(params);
	}

	executeCreate(item: T | FormData, showMessage = false) {
		return this.postItem(item, showMessage, this.getParams(true));
	}

	executeEdit(item: T | { id: string; form: FormData }, showMessage = false) {
		return this.patchItem(item, showMessage);
	}

	executeEditAction(item: T | { id: string; form: FormData }, action: string, showMessage = false) {
		return this.patchItemAction(item, action, showMessage);
	}

	executeDelete(item: T, showMessage = false) {
		return this.deleteItem(item, showMessage);
	}

	executeAction(item: T, action: string, body: any, showMessage = false) {
		return this.postItemAction(item, action, body, showMessage, this.getParams(true));
	}

	executePostItemQueryParams(item: T, queryParams: {}, showMessage = false) {
		return this.postItemQueryParams(item, this.getPostQueryParams(queryParams), showMessage, this.getParams(true));
	}

	getPostQueryParams(queryParams: {}) {
		let result = '';
		Object.keys(queryParams).forEach((key) => {
			result = result + `?${key}=${(queryParams as any)[key]}`;
		});
		return result;
	}

	executeDeleteAction(item: T, action: string, showMessage = false, body = {}) {
		return this.deleteItemAction(item, action, showMessage, body, this.getParams(true));
	}

	executeActionGet(item: T, action: string, responseType = '') {
		return this.getItemAction(item, action, this.getParams(true), responseType);
	}

	executeGet(idOrSlug?: string, responseType = undefined) {
		return this.getItem(idOrSlug, this.getParams(true), responseType);
	}

	execute() {
		return this.getList(this.getParams());
	}

	createObservable(funcRequest: any, showMessage = false) {
		const observers: any[] = [];
		return new Observable<T>((observer) => {
			observers.push(observer);
			if (observers.length === 1) {
				funcRequest()
					.pipe(
						catchError((error) => {
							if (error.status == 401) {
								this.manager.loginService.cleanToken();
								location.reload();
								return of(error);
							}
							if (this.manager.notificationService) {
								if (error.error.detail) {
									this.manager.notificationService.showError(error.error.detail);
								}
								if (error.error.non_field_errors) {
									this.manager.notificationService.showError(error.error.non_field_errors);
								}
							}
							return of(error);
						})
					)
					.subscribe((result: { error: { detail: any } }) => {
						if (!result) {
							observers.forEach((obs) => obs.next(result));
						} else if (!result.error) {
							if (showMessage && this.manager.notificationService) {
								this.manager.notificationService.showSuccess('Operaci�n satisfactoria');
							}
							observers.forEach((obs) => obs.next(result));
						} else {
							if (showMessage && this.manager.notificationService && result.error.detail) {
								this.manager.notificationService.showError(result.error.detail);
							}
							observers.forEach((obs) => obs.error(result));
							// this.errorManagement.manageFieldError(result.error);
							// let errorMsg = this.errorManagement.allErrorMessages;
							// this.generalNotification.showError(errorMsg, 5000);
						}
						observers.forEach((obs) => obs.complete());
					});
			}
			return { unsubscribe() {} };
		});
	}

	getList(params: any) {
		return this.createObservable(() => {
			return this.manager.httpAngular().get<Paginator<T>>(this.manager.getUrl(), this.manager.getOptions(params));
		});
	}

	getItem(idOrSlug: any, params: any, responseType = undefined) {
		return this.createObservable(() => {
			const options = this.manager.getOptions(params);
			if (responseType) {
				(options as any)['responseType'] = responseType;
			}
			return this.manager.httpAngular().get<T>(this.manager.getUrl(idOrSlug), options);
		});
	}

	postItem(item: T | FormData, showMessage = false, params: any) {
		return this.createObservable(() => {
			return this.manager.httpAngular().post<T>(this.manager.getUrl(), item, this.manager.getOptions(params, item));
		}, showMessage);
	}

	postItemAction(item: T, action: string, body: any, showMessage = false, params: any) {
		return this.createObservable(() => {
			return this.manager.httpAngular().post(`${this.manager.getUrl(item.id)}${action}/`, body, this.manager.getOptions(params, body));
		}, showMessage);
	}

	postItemQueryParams(item: T | FormData, queryParams: string, showMessage = false, params: any) {
		return this.createObservable(() => {
			return this.manager.httpAngular().post<T>(`${this.manager.getUrl()}${queryParams}`, item, this.manager.getOptions(params, item));
		}, showMessage);
	}

	deleteItemAction(item: T, action: string, showMessage = false, body = {}, params: any) {
		return this.createObservable(() => {
			return this.manager.httpAngular().delete(`${this.manager.getUrl(item.id)}${action}/`, { ...this.manager.getOptions(params, {}), body });
		}, showMessage);
	}

	getItemAction(item: T, action: string, params: any, responseType = '') {
		return this.createObservable(() => {
			const options = this.manager.getOptions(params);
			if (responseType) {
				(options as any)['responseType'] = responseType;
				if (responseType === 'arraybuffer') {
					(options as any)['observe'] = 'response';
				}
			}
			return this.manager.httpAngular().get<T>(`${this.manager.getUrl(item.id)}${action}/`, options);
		});
	}

	patchItem(item: T | { id: string; form: FormData }, showMessage = false) {
		let pathItem = item;
		if ((item as any)['form']) {
			pathItem = (item as any)['form'];
		}
		return this.createObservable(() => {
			return this.manager.httpAngular().patch<T>(this.manager.getUrl(item.id), pathItem, this.manager.getOptions({}, pathItem));
		}, showMessage);
	}

	patchItemAction(item: T | { id: string; form: FormData }, action: string, showMessage = false) {
		let pathItem = item;
		if ((item as any)['form']) {
			pathItem = (item as any)['form'];
		}
		return this.createObservable(() => {
			return this.manager.httpAngular().patch<T>(`${this.manager.getUrl(item.id)}${action}/`, pathItem, this.manager.getOptions({}, pathItem));
		}, showMessage);
	}

	deleteItem(item: T, showMessage = false) {
		return this.createObservable(() => {
			return this.manager.httpAngular().delete<T>(this.manager.getUrl(item.id), this.manager.getOptions({}, item));
		}, showMessage);
	}
}
