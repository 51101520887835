import { Component, Input, OnInit } from '@angular/core';
import { SuccessStory } from 'src/app/models/success-story';

@Component({
  selector: 'merz-success-story-card',
  templateUrl: './success-story-card.component.html',
  styleUrls: ['./success-story-card.component.scss'],
})
export class SuccessStoryCardComponent implements OnInit {
  @Input() story: SuccessStory;
  constructor() { }

  ngOnInit() {}

}
