import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { PlansModalComponent } from 'src/app/components/modal/plans-modal/plans-modal.component';
import { UtilsService } from '../../utils/utils.service';
import { UserDetailService } from '../../user-detail-service/user-detail.service';

@Injectable({
  providedIn: 'root',
})
export class ProductBasicGuardGuard  {
  constructor(
    private userDetailService: UserDetailService,
    private modalController: ModalController,
    private utilsService: UtilsService
  ) {}

  async openPlans(event?) {
    const modal = await this.modalController.create({
      component: PlansModalComponent,
      componentProps: {
        userPlanId: this.userDetailService.userDetail?.plan?.id,
        plans: this.utilsService.plans,
        adminPhone: this.userDetailService.userDetail?.kam?.phoneMobile,
      },
      cssClass: 'modal-plans-options',
    });
    return await modal.present();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.userDetailService.userDetail?.accessLevel === 'basic') {
      this.openPlans();
      return false;
    } else {
      return true;
    }
  }
}
