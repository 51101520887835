import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FilterGalleryModalComponent } from '../../modal/filter-gallery-modal/filter-gallery-modal.component';
import { enterAnimation, leaveAnimation } from 'src/app/animations/enter';
import { FiltersService } from 'src/app/services/filters-service/filters.service';

@Component({
  selector: 'merz-header-gallery-contents',
  templateUrl: './header-gallery-contents.component.html',
  styleUrls: ['./header-gallery-contents.component.scss'],
})
export class HeaderGalleryContentsComponent implements OnInit {
  constructor(
    protected modalController: ModalController,
    protected filtersService: FiltersService
  ) {}
  ngOnInit() {}

  async openFilter(event) {
    const modal = await this.modalController.create({
      component: FilterGalleryModalComponent,
      enterAnimation,
      leaveAnimation,
      cssClass: 'modal-options',
    });

    return await modal.present();
  }

  get filtersIcon() {
    return this.filtersService.isFiltersEmpty
      ? '/assets/svg/options-outline.svg'
      : '/assets/svg/options-outline-mark.svg';
  }
}
