// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-toolbar {
  --padding-start: 23px;
  --padding-end: 15px;
  --background: var(--ion-color-white);
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header-gallery-contents/header-gallery-contents.component.scss"],"names":[],"mappings":"AAEE;EACE,qBAAA;EACA,mBAAA;EACA,oCAAA;AADJ","sourcesContent":["\nion-header {\n  ion-toolbar {\n    --padding-start: 23px;\n    --padding-end: 15px;\n    --background: var(--ion-color-white);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
