import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CalendarView, CalendarMonthViewDay, CalendarDateFormatter } from 'angular-calendar';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { DatePipe, registerLocaleData } from '@angular/common';
import { CalendarService } from '../../services/calendar/calendar.service';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);

@Component({
  selector: 'dnest-core-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ],
  host: {
    "(window:click)": "onWindowClick()"
  },
  //encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit {
  @Input('calendar-image') calendarImage: string = "";
  @Input('arrow-image') arrowImage: string = "";
  @Input('input-controller') inputController: UntypedFormControl = new UntypedFormControl();
  @Input('placeholder') placeholder: string = "";
  @Input('title') title: string = "";
  @Input('calendar-id') calendarID: string = "";
  @Input('theme') theme: string = "primary";
  @Input('date-format') dateFormat: string = "yyyy-MM-dd";

  get showCalendar() { return this.calendarService.showCalendar }

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  locale = 'es';
  calendarBody: CalendarMonthViewDay[] = [];
  minDate: Date = new Date();
  maxDate: Date = new Date();
  selectedMonthViewDay: any;
  selectingMinDate = true;
  hideCalendarTimeout: any;
  minDateFocus = false;
  maxDateFocus = false;
  calendarCloseTimeout: any;

  constructor(
    protected calendarService: CalendarService,
  ) { }


  ngOnInit(): void {
    this.calendarService.listOfCalendars.push(this.calendarID);
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    this.calendarBody = body;
    this.updateMonthDays();
  }

  updateMonthDays() {
    if (!this.minDate && !this.maxDate) {
      return;
    }
    this.calendarBody.forEach(day => {
      delete day.cssClass;
      if (this.minDate) {
        if (this.minDate.getTime() === day.date.getTime()) {
          day.cssClass = 'cal-day-selected';
        }
      }
      if (this.maxDate) {
        if (this.maxDate.getTime() === day.date.getTime()) {
          day.cssClass = 'cal-day-selected';
          return;
        }
      }
      if ((this.minDate && this.minDate < day.date) && (this.maxDate && day.date < this.maxDate)) {
        day.cssClass = 'cal-day-selected cal-day-selected-inner';
      }
    });
  }

  dayClicked(day: CalendarMonthViewDay): void {
    this.setDays(day);
    this.updateMonthDays();
    this.emitDates();
  }

  private setDays(day: CalendarMonthViewDay<any>) {
    this.selectedMonthViewDay = day;
    if (this.selectingMinDate) {
      this.minDate = this.selectedMonthViewDay.date;
      this.selectingMinDate = false;
      if (this.minDate > this.maxDate) {
        this.maxDate = this.minDate;
      }
    }
    else {
      this.maxDate = this.selectedMonthViewDay.date;
      if (this.maxDate < this.minDate) {
        this.minDate = this.maxDate;
      }
      else {
        this.selectingMinDate = true;
      }
    }
  }

  @Output('selected-dates') selectedDatesEvent = new EventEmitter<{min: string, max: string}>();
  private emitDates() {
    this.selectingMinDate = true;
    const formatedMinDate = this.formatDate(this.minDate);
    const formatedMaxDate = this.formatDate(this.maxDate);
    this.inputController.setValue(formatedMinDate);
    this.selectedDatesEvent.emit({ min: formatedMinDate!, max: formatedMaxDate! });

    this.calendarService.closeCalendar(this.calendarID);
  }

  formatDate(date: Date) {
    return new DatePipe(this.locale).transform(date, this.dateFormat, this.locale);
  }

  getFocus(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.hideCalendarTimeout) {
      clearTimeout(this.hideCalendarTimeout);
    }
    if (this.selectingMinDate) {
      this.minDateFocus = !this.minDateFocus;
    } else {
      this.maxDateFocus = !this.maxDateFocus;
    }
  }

  openCalendar(event: Event) {
    event.stopPropagation();
    this.calendarService.openedCalendarID = this.calendarID;
    this.showCalendar ? this.calendarService.closeCalendar(this.calendarID) : this.calendarService.openCalendar(this.calendarID);
  }


  onWindowClick() {
    if(this.showCalendar) {
      this.calendarService.listOfCalendars.forEach(c => this.calendarService.closeCalendar(c))
      //this.customSelectService.closeCalendar(this.customSelectService.openedCalendarID);
    }
  }


}
