// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.content {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-box {
  width: 120px;
  min-width: 120px;
  height: 120px;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--ion-color-background);
  border: 4px solid var(--ion-color-lines);
}
.image-box .image {
  width: 50px;
  height: 50px;
}

.texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  gap: 6px;
}
.texts .text {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/sections/not-found/not-found.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,WAAA;EACA,2BAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,6CAAA;EACA,wCAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,QAAA;AAAF;AAEE;EACE,kBAAA;AAAJ","sourcesContent":[":host {\n  display: block;\n}\n\n.content {\n  width: 100%;\n  height: calc(100vh - 200px);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.image-box {\n  width: 120px;\n  min-width: 120px;\n  height: 120px;\n  min-height: 120px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  background-color: var(--ion-color-background);\n  border: 4px solid var(--ion-color-lines);\n\n  .image {\n    width: 50px;\n    height: 50px;\n  }\n}\n\n.texts {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 27px;\n  gap: 6px;\n\n  .text {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
