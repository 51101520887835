import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CancelScrollService {

  constructor() { }

  cancelBodyScroll() {
    let body = document.querySelector('body');
    body!.style.overflow = 'hidden';
  }

  restoreBodyScroll() {
    let body = document.querySelector('body');
    body!.style.overflow = 'unset';
  }

}
