import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthApiClientService } from '../../crud/auth-api-client/auth-api-client.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/internal/operators/catchError';
import { LibraryConfigService, LibraryConfig } from '../../../config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  routes: any;
  storage: any;
  
  constructor(
    protected authService: AuthApiClientService,
    protected router: Router,
    @Inject(LibraryConfigService) config: LibraryConfig
  ) {
    this.routes = config.routes;
    this.storage = config.storage;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token: string = this.authService.sessionToken;
    let request = req;
    if (token) {
      request = req.clone({
        setHeaders: {
          Authorization: `Token ${token}`
        },
        withCredentials: true
      })
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 || err.status === 403) {
          this.router.navigate([this.routes.login]);
          this.storage.deleteSessionData();
        }
        return throwError(err);
      })
    )
  }
}
