// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://api2-pruebas.merzapp.es/',

  //without the backslach at the end
  share_url: 'https://go.merzapp.es',

  front_url: 'https://web-stg.merzapp.es/',
};
