import { Injectable } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Gallery } from 'src/app/models/gallery';
import { GalleryApiClientService } from '../api/gallery-api-client/gallery-api-client.service';
import { GalleryDetailModalComponent } from 'src/app/components/modal/gallery-detail-modal/gallery-detail-modal.component';
import { NotFoundService } from '../not-found/not-found.service';

@Injectable({
  providedIn: 'root',
})
export class GalleryDetailService {
  galleryDetail: Gallery;

  constructor(
    protected modalController: ModalController,
    protected galleryApiClientService: GalleryApiClientService,
    protected notFoundService: NotFoundService,
    protected loadingController: LoadingController
  ) {}

  async openGalleryDetailModal() {
    const modal = await this.modalController.create({
      component: GalleryDetailModalComponent,
      cssClass: 'gallery-modal',
    });

    return await modal.present();
  }

  getGalleryContent(galleryContentId: any) {
    this.loadingGalleryDetail();

    this.galleryApiClientService.get(galleryContentId).subscribe(
      (data) => {
        this.notFoundService.showNotFound = false;
        this.galleryDetail = data;
        this.loadingController.dismiss();
        this.openGalleryDetailModal();
      },
      (error) => {
        if (error.status === 404) {
          this.notFoundService.showNotFound = true;
          this.openGalleryDetailModal();
        }
      }
    );
  }

  async loadingGalleryDetail() {
    const loading = await this.loadingController.create({
      cssClass: 'loading-gallery-detail',
      mode: 'md',
    });
    await loading.present();
  }
}
