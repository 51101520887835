import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'merz-datetime-modal',
  templateUrl: './datetime-modal.component.html',
  styleUrls: ['./datetime-modal.component.scss']
})
export class DatetimeModalComponent implements OnInit {
  @Input() doneText = '';
  @Input() cancelText = '';
  @Input() maxDate: string;
  @Input() date: Date;
  @Input() pickerFormat = '';

  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  cancel($event) {
    this.modalController.dismiss();
  }

  changeDate(event: any) {
    console.log(event);

    this.modalController.dismiss({
      date: event.detail.value,
    });
  }
}
