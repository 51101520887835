import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // TODO: add explicit constructor

    // you can override any of the methods defined in the parent class

    public override monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
        return new DatePipe(locale!).transform(date, 'EEEEEE', locale)!.toLocaleLowerCase();
    }

    public override monthViewTitle({ date, locale }: DateFormatterParams): string {
        return new DatePipe(locale!).transform(date, 'MMMM yyyy', locale)!;
    }

    public override weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
        return new DatePipe(locale!).transform(date, 'EEEEEE', locale)!.toLocaleLowerCase();
    }

    public override dayViewHour({ date, locale }: DateFormatterParams): string {
        return new DatePipe(locale!).transform(date, 'HH:mm', locale)!;
    }
}
