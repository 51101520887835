// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookies-section {
  position: fixed;
  bottom: 17px;
  z-index: 90;
}

.desktop-margin {
  margin-top: 100px;
  margin-bottom: 52px;
}

merz-footer {
  border-top: 1px solid var(--ion-color-lines);
}

ion-app {
  background: var(--ion-color-white);
}
ion-app ion-select {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,4CAAA;AACF;;AAEA;EACE,kCAAA;AACF;AACE;EACE,uBAAA;AACJ","sourcesContent":[".cookies-section {\n  position: fixed;\n  bottom: 17px;\n  z-index: 90;\n}\n\n.desktop-margin {\n  margin-top: 100px;\n  margin-bottom: 52px;\n}\n\nmerz-footer {\n  border-top: 1px solid var(--ion-color-lines);\n}\n\nion-app {\n  background: var(--ion-color-white);\n  \n  ion-select {\n    background-color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
