import { Component, Input, OnInit } from '@angular/core';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { User } from 'src/app/models/user';
import { UserDetailService } from 'src/app/services/user-detail-service/user-detail.service';

@Component({
  selector: 'merz-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  MERZ_ROUTES = MERZ_ROUTES;
  @Input() user: User;

  constructor(private userDetailService: UserDetailService) {}

  ngOnInit() {
    //Pideremos el usuario cada cierto tiempo para revisar si hay nuevo contenido
      setInterval(() => {
        this.userDetailService.getUserNewNotifications();
      }, 60000); // 1 minuto
  }

  get showVideoNotification() {
    return this.userDetailService.showVideoNotification;
  }

  get showEventNotification() {
    return this.userDetailService.showEventNotification;
  }

  get showGalleryNotification() {
    return this.userDetailService.showGalleryNotification;
  }
}
