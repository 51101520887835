import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LibraryConfigService, LibraryConfig } from '../../../../config';
import { AuthApiClientService } from '../../../crud/auth-api-client/auth-api-client.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardOldService {
  routes: any;

  constructor(
    protected authApiClientService: AuthApiClientService,
    protected router: Router,
    @Inject(LibraryConfigService) config: LibraryConfig
  ) {
    this.routes = config.routes;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authApiClientService.isLoggedIn) {
      return true
    } else {
      this.router.navigate([this.routes.login], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
