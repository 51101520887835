import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'merz-filter-radio-button',
  templateUrl: './filter-radio-button.component.html',
  styleUrls: ['./filter-radio-button.component.scss'],
})
export class FilterRadioButtonComponent implements OnInit {
	@Output('filter-data') filterDataEvent = new EventEmitter<any>();
  @Input() field: string;
	@Input() options: Option[] = [];
	@Input('option-selected-value') optionSelectedValue: string;

  constructor() { }

  ngOnInit() {
    if(this.optionSelectedValue){
      this.options.find((option) => option.value === this.optionSelectedValue).checked = true;
    }
    else {
      this.options[0].checked = true;
      // this.options.find((option) => option.value === 'both').checked = true;
    }
  }

  selectOption(option: Option){
    const response = {};
    this.options.forEach(elem => {
      elem.checked = false;
    });
    option.checked = true;

    response[this.field] = option.value;
    this.filterDataEvent.emit(response);
  }
}
