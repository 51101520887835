import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Login } from '../../../models/login';
import { ApiClientService } from '../api-client.service';


@Injectable({
  providedIn: 'root'
})
export class LoginApiClientService extends ApiClientService<Login> {
  override endpoint = 'rest-auth/login';

  AUTH_TOKEN = 'auth-token';
  TOKEN_HEADER_NAME = 'Token';

  isLoggedInEvent: Subject<boolean> = new Subject<boolean>();
  isLoggedOutEvent: Subject<boolean> = new Subject<boolean>();

  static authToken = undefined;

  get headerToken() {
    let token = LoginApiClientService.authToken;
    if (!token) {
      token = this.localStorageService.getObject(this.AUTH_TOKEN);
      LoginApiClientService.authToken = token;
    }
    return {
      'Authorization': this.TOKEN_HEADER_NAME + ' ' + token
    };
  }

  login(payload: any) {
    return this.create(payload);
  }

  useAuthToken(token: any) {
    LoginApiClientService.authToken = token;
  }

  saveToken(token: any) {
    this.useAuthToken(token);
    this.localStorageService.saveObject(this.AUTH_TOKEN, token);
    this.emitIsLoggedInEvent();
  }

  cleanToken() {
    LoginApiClientService.authToken = undefined;
    this.localStorageService.deleteObject(this.AUTH_TOKEN);
    this.emitIsLoggedOutEvent();
  }

  isLoggedIn() {
    if (LoginApiClientService.authToken || this.localStorageService.getObject(this.AUTH_TOKEN)) {
      return true;
    }
    return false;
  }

  emitIsLoggedInEvent() {
    this.isLoggedInEvent.next(true);
  }

  emitIsLoggedOutEvent() {
    this.isLoggedOutEvent.next(true);
  }

  /* get loginQuestions(): Question<any>[] {
    return [
      new InputQuestion({
        key: 'email',
        label: 'Correo electrónico',
        required: true,
        type: 'email'
      }),
      new InputQuestion({
        key: 'password',
        label: 'Contraseña',
        required: true,
        type: 'password'
      })
    ];
  } */
}
