import { Component, OnInit } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { Camera, CameraResultType } from '@capacitor/camera';


@Component({
  selector: 'merz-input-avatar',
  templateUrl: './input-avatar.component.html',
  styleUrls: ['./input-avatar.component.scss']
})
export class InputAvatarComponent extends InputComponent {
  imageLogo = '';
  constructor(
  ) {
    super();
  }

  ngOnInit() {
  }

  async takePicture(){
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl
    });

    this.imageLogo = image.dataUrl;
    this.formControl.setValue(this.imageLogo);

    // userwork.changeLogo = this.workCenter.logo;

  };
}
