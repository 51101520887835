// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-top {
  width: 100%;
  height: 50px;
  background: var(--ion-color-primary);
}
.header-top .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.header-top .top .left {
  display: flex;
  align-items: center;
  gap: 26px;
}
.header-top .top .right {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header-desktop/header-desktop.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,oCAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACN;AAEI;EACE,aAAA;EACA,mBAAA;AAAN","sourcesContent":[".header-top {\n  width: 100%;\n  height: 50px;\n  background: var(--ion-color-primary);\n\n  .top {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: 100%;\n\n    .left {\n      display: flex;\n      align-items: center;\n      gap: 26px;\n    }\n\n    .right {\n      display: flex;\n      align-items: center;\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
