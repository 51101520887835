import { NgModule } from '@angular/core';
import {
  BrowserModule,
} from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { LibraryConfig } from 'projects/dnest-core-angular/src/config';
import { DnestCoreAngularModule } from 'projects/dnest-core-angular/src/dnest-core-angular.module';
import { LoginApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertModule } from './components/alert/alert.module';
import { MERZ_ROUTES } from './merz-routes';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { LoadAnimationModule } from './components/load-animation/load-animation.module';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { HeaderModule } from './components/header/header.module';
import { ClipboardModule } from 'ngx-clipboard';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { CookiesSectionModule } from './sections/cookies-section/cookies-section.module';
import { FooterComponentModule } from "./components/footer/footer.module";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppLanguageInterceptor } from './services/interceptors/app-language/app-language.interceptor';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';


export const HttpLoaderFactory = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const dnestCoreAngularConfig: LibraryConfig = {
  urlBase: environment.api_url, // URL del API
  routes: { root: '/', login: MERZ_ROUTES.welcomeComplete },
  loginClientServiceClass: LoginApiClientService,
};

@NgModule({
  declarations: [AppComponent],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Calendar,
    AppVersion,
    InAppBrowser,
    File,
    FileOpener,
    SocialSharing,
    { provide: HTTP_INTERCEPTORS, useClass: AppLanguageInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ animated: false }),
    AppRoutingModule,
    DnestCoreAngularModule,
    DnestCoreAngularModule.forRoot(dnestCoreAngularConfig),
    AlertModule,
    LoadAnimationModule,
    HeaderModule,
    ClipboardModule,
    CookiesSectionModule,
    FooterComponentModule,
    NgxExtendedPdfViewerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'es',
    }),
  ],
})
export class AppModule { }

