// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
}

.lottie {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 999999999;
  background: rgba(246, 249, 254, 0.52);
  display: grid;
  place-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/load-animation/load-animation.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,kBAAA;EACA,qCAAA;EACA,aAAA;EACA,mBAAA;AACF","sourcesContent":[":host {\n  height: 100%;\n}\n\n.lottie {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  z-index: 999999999;\n  background: rgba(246, 249, 254, .52);\n  display: grid;\n  place-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
