import { Component, Input, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { MediaReference } from 'src/app/models/media-reference';

@Component({
  selector: 'merz-media-reference-short-card',
  templateUrl: './media-reference-short-card.component.html',
  styleUrls: ['./media-reference-short-card.component.scss'],
})
export class MediaReferenceShortCardComponent implements OnInit {
  @Input() mediaReference: MediaReference;
  @Input() small = false;

  constructor() { }

  ngOnInit() {}

  get date(){
    let result = format(new Date(this.mediaReference?.publishDate), 'MMMM yyyy', {
      locale: es
    });
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
}
