
import { Base } from './base';

export class Speaker extends Base {
  image?: string;
  name?: string;
  firstName: string;
  description: string;
  followedCount: number;
  lastName: string;
  title: string;
  videosCount: number;
  followed: boolean;
  facebook: string;
  instagram: string;
  linkedin: string;
  twitter: string;
  videos;
  imageBackground: string;
  imageBackgroundDesktop: string;
}
