import { Injectable } from '@angular/core';
import { UserApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { User } from 'src/app/models/user';
import { Country } from 'src/app/models/country';

@Injectable({
  providedIn: 'root',
})
export class UserDetailService {
  userDetail: User;
  language: string;
  availableLanguages = ['es', 'pt'];
  countries: Country[] = [];

  constructor(private userApiClientService: UserApiClientService) {}

  get showVideoNotification() {
    return this.userDetail && this.userDetail.newNotificationFormation > 0;
  }

  get showEventNotification() {
    return this.userDetail && this.userDetail.newNotificationEvents > 0;
  }

  get showGalleryNotification() {
    return this.userDetail && this.userDetail.newNotificationGalery > 0;
  }

  getUserNewNotifications() {
    this.userApiClientService.get().subscribe((user: User) => {
      this.userDetail.newNotificationEvents = user.newNotificationEvents;
      this.userDetail.newNotificationFormation = user.newNotificationFormation;
      this.userDetail.newNotificationGalery = user.newNotificationGalery;
    });
  }

  cleanFormationNotifications() {
    const body = {
      id: '',
      newNotificationFormation: 0,
    };
    this.userApiClientService.edit(body).subscribe({
      next: (data) => (this.userDetail.newNotificationFormation = 0),
      error: (error) => console.log(error),
    });
  }

  cleanEventNotifications() {
    const body = {
      id: '',
      newNotificationEvents: 0,
    };
    this.userApiClientService.edit(body).subscribe({
      next: (data) => (this.userDetail.newNotificationEvents = 0),
      error: (error) => console.log(error),
    });
  }

  cleanGalleryNotifications() {
    const body = {
      id: '',
      newNotificationGalery: 0,
    };
    this.userApiClientService.edit(body).subscribe({
      next: (data) => (this.userDetail.newNotificationGalery = 0),
      error: (error) => console.log(error),
    });
  }

  getUserDetail() {
    this.userApiClientService.get().subscribe((user: User) => {
      this.userDetail = user;
    });
  }
}
