// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-card {
  display: block;
  max-width: 360px;
  position: relative;
}
ion-card .top {
  height: 160px;
  background: var(--ion-color-pink);
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: grid;
  place-items: center;
}
ion-card .top ion-thumbnail {
  --size: 120px;
  --border-radius: 100%;
  background: var(--ion-color-white);
  border: 4px solid var(--ion-color-lines);
  display: grid;
  place-items: center;
}
ion-card .top ion-thumbnail ion-icon {
  font-size: 50px;
}
ion-card ion-card-content {
  padding: 13.5px 16px 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/work-center-card/work-center-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAGA;EACE,cAAA;EACA,gBAAA;EAEA,kBAAA;AADF;AAGE;EACE,aAAA;EACA,iCAAA;EACA,kBAAA;EACA,4BAAA;EACA,6BAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAII;EACE,aAAA;EACA,qBAAA;EACA,kCAAA;EACA,wCAAA;EACA,aAAA;EACA,mBAAA;AAFN;AAIM;EACE,eAAA;AAFR;AAOE;EACE,yBAAA;AALJ","sourcesContent":[":host {\n  display: block;\n}\n\n\nion-card {\n  display: block;\n  max-width: 360px;\n  // height: 280px;\n  position: relative;\n\n  .top {\n    height: 160px;\n    background: var(--ion-color-pink);\n    border-radius: 5px;\n    border-bottom-left-radius: 0;\n    border-bottom-right-radius: 0;\n    display: grid;\n    place-items: center;\n\n\n    ion-thumbnail {\n      --size: 120px;\n      --border-radius: 100%;\n      background: var(--ion-color-white);\n      border: 4px solid var(--ion-color-lines);\n      display: grid;\n      place-items: center;\n\n      ion-icon {\n        font-size: 50px;\n      }\n    }\n  }\n\n  ion-card-content {\n    padding: 13.5px 16px 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
