import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeakersSmallBlockComponent } from './speakers-small-block.component';
import { IonicModule } from '@ionic/angular';
import { TextModule } from 'src/app/components/text/text.module';



@NgModule({
  declarations: [
    SpeakersSmallBlockComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TextModule
  ],
  exports: [
    SpeakersSmallBlockComponent
  ]
})
export class SpeakersSmallBlockModule { }
