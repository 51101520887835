import { Component, OnInit, Input } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dnest-core-sanatized-html-content-without-encapsulation',
  templateUrl: './sanatized-html-content-without-encapsulation.component.html',
  styleUrls: ['./sanatized-html-content-without-encapsulation.component.scss']
})
export class SanatizedHtmlContentWithoutEncapsulationComponent implements OnInit {
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";
  
  _content: SafeHtml = "";

  @Input('content')
  set content(content: string) {
    this._content = this.sanitized.bypassSecurityTrustHtml(content);
  }
  constructor(
    private sanitized: DomSanitizer,
  ) { }

  ngOnInit() {
  }

}
