import { Injectable } from '@angular/core';
import { BaseDetailResolver } from './base-detail.resolver';
import { EventApiClientService } from '../api/event-api-client/event-api-client.service';
import { Event } from 'src/app/models/event';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotFoundService } from '../not-found/not-found.service';

@Injectable({
  providedIn: 'root',
})
export class EventResolver extends BaseDetailResolver<Event> {
  protected idParam = 'eventId';

  constructor(
    protected service: EventApiClientService,
    protected notFoundService: NotFoundService
  ) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.service
      .filter(route.queryParams)
      .get(route.paramMap.get(this.idParam))
      .pipe(
        map((data) => {
          this.notFoundService.showNotFound = false;
          return data;
        }),
        catchError((error) => {
          if (error.status === 404) {
            this.notFoundService.showNotFound = true;
          }
          return of(undefined);
        })
      );
  }
}
