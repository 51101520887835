import { Component, OnInit } from '@angular/core';
import { MERZ_ROUTES } from './merz-routes';
import { LoginApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { UserDetailService } from './services/user-detail-service/user-detail.service';
import { register } from 'swiper/element/bundle';

register();
@Component({
  selector: 'merz-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  MERZ_ROUTES = MERZ_ROUTES;

  constructor(
    private loginApiClientService: LoginApiClientService,
    private router: Router,
    private translateService: TranslateService,
    private platform: Platform,
    private userDetailService: UserDetailService
  ) {
    this.initializeLanguage();
  }

  ngOnInit() { }

  private async initializeLanguage() {
    await this.platform.ready();

    Device.getLanguageCode().then((language) => {
      const availableLanguages = this.userDetailService.availableLanguages;

      const languageSelected =
        this.userDetailService.language || language.value;

      if (availableLanguages.includes(languageSelected)) {
        this.translateService.use(languageSelected);
      } else {
        this.translateService.use(this.translateService.getDefaultLang());
      }
    });
  }

  get showHeaderdesktop() {
    return this.loginApiClientService.isLoggedIn() && this.noOneOfThisRoutes();
  }

  get showFooter() {
    return !this.router.url.includes(MERZ_ROUTES.welcomeComplete);
  }

  /**
   * Retorna si true si la url actual no pertence a ninguna de las que no están permitidas
   */
  noOneOfThisRoutes() {
    const currentUrl = this.router.url;
    return ![
      MERZ_ROUTES.registerPasswordComplete,
      MERZ_ROUTES.registerOcupationComplete,
      MERZ_ROUTES.registerFunctionComplete,
      MERZ_ROUTES.registerStatementComplete,
      MERZ_ROUTES.registerSpecialtyComplete,
      MERZ_ROUTES.registerMasterComplete,
      MERZ_ROUTES.registerMasterEndateComplete,
      MERZ_ROUTES.registerWorkcenterComplete,
      MERZ_ROUTES.registerWorkcenterAddComplete,
      MERZ_ROUTES.completeProfileComplete,
      MERZ_ROUTES.pollComplete,
      MERZ_ROUTES.preferencesComplete,
      MERZ_ROUTES.registerPhoneConfirmComplete,
    ].includes(currentUrl);
  }
}
