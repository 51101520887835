import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { Column } from '../../models/table/column';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  constructor(
    @Inject(LOCALE_ID) protected localeValue: string = 'es'
  ) {
    Column.locale = localeValue;
  }

  get locale() {
    return this.localeValue;
  }
}
