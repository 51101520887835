import { Injectable } from '@angular/core';
import { BaseDetailResolver } from './base-detail.resolver';
import { KamWorkcenterApiClientService } from '../api/kam-workcenter-api-client/kam-workcenter-api-client.service';
import { UserWorkCenter } from 'src/app/models/user-work-center';

@Injectable({
  providedIn: 'root',
})
export class KamWorkCenterResolver extends BaseDetailResolver<UserWorkCenter> {
  protected idParam = 'kamWorkcenterId';

  constructor(protected service: KamWorkcenterApiClientService
    ) {
    super();
  }
}
