import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { Subject } from 'rxjs';
import { Formation } from 'src/app/models/formation';

@Injectable({
  providedIn: 'root'
})
export class FormationApiClientService extends ApiClientService<Formation> {
  endpoint = 'formation';
  profileCoursesEvent = new Subject<any>();
}
