import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'merz-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

	public header: string;
	public subheader: string;
	public message: string;
	public buttons = [];

	constructor(public alertController: AlertController, public alertService: AlertService) {}

	ngOnInit() {
		this.alertService.alertEvent?.subscribe((event) => {
      this.header = event.header;
      this.subheader = event.subheader;
      this.message = event.message;
      this.buttons = event.buttons;
			this.presentAlertConfirm();
		});
	}

	async presentAlertConfirm() {
		const alert = await this.alertController.create({
			cssClass: 'my-custom-class',
			header: this.header,
			subHeader: this.subheader,
			message: this.message,
			buttons: this.buttons,
		});

		await alert.present();
	}
}
