import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserDevice } from 'src/app/models/user-device';
import { UserDeviceApiClientService } from 'src/app/services/api/user-device-api-cliente/user-device-api-client.service';
import { DataResolverService } from 'src/app/services/data-resolver/data-resolver.service';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-user-device',
  templateUrl: './user-device.component.html',
  styleUrls: ['./user-device.component.scss'],
})
export class UserDeviceComponent extends InputComponent {
  userDevices: UserDevice[] = [];

  constructor(
    protected userDeviceApiClientService: UserDeviceApiClientService, protected activatedRoute: ActivatedRoute, protected dataResolverService: DataResolverService
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.userDevices = this.dataResolverService.getData(
        this.activatedRoute,
        'userDevices'
      );
    });
  }

  disconnect(deviceId: string){
    const userDevice = new UserDevice();
    userDevice.id = deviceId;
    this.userDeviceApiClientService.delete(userDevice).subscribe((data) => {
      this.userDevices = this.userDevices.filter((item) => item.id !== deviceId);
    });

  }
}
