import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'merz-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss'],
})
export class HeaderHomeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
