// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  position: relative;
  text-align: left;
}

.tooltip .top {
  top: -20px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px 20px;
  background-color: #FFFFFF;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.tooltip:hover .top {
  display: block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #FFFFFF;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./projects/dnest-core-angular/src/lib/components/tooltip/tooltip.component.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,UAAA;EACA,SAAA;EACA,iCAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,wCAAA;AADJ;;AAIA;EACI,cAAA;AADJ;;AAIA;EACI,kBAAA;EACA,SAAA;EACA,SAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AADJ;;AAIA;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,8CAAA;EACA,yBAAA;EACA,wCAAA;AADJ","sourcesContent":["@import '../../styles/mixins.scss';\n\n.tooltip {\n    position: relative;\n    text-align: left;\n}\n\n.tooltip .top {\n    top: -20px;\n    left: 50%;\n    transform: translate(-50%, -100%);\n    padding: 10px 20px;\n    background-color: #FFFFFF;\n    border-radius: 8px;\n    position: absolute;\n    z-index: 99999999;\n    box-sizing: border-box;\n    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);\n}\n\n.tooltip:hover .top {\n    display: block;\n}\n\n.tooltip .top i {\n    position: absolute;\n    top: 100%;\n    left: 50%;\n    margin-left: -12px;\n    width: 24px;\n    height: 12px;\n    overflow: hidden;\n}\n\n.tooltip .top i::after {\n    content: '';\n    position: absolute;\n    width: 12px;\n    height: 12px;\n    left: 50%;\n    transform: translate(-50%, -50%) rotate(45deg);\n    background-color: #FFFFFF;\n    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
