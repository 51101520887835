import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { Subject } from 'rxjs';
import { Notification } from 'src/app/models/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationApiClientService extends ApiClientService<Notification> {
  endpoint = 'notification';
  notificationsReadEvent = new Subject<any>();
}

