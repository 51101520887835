// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters .filter {
  padding: 12px 0;
  border-bottom: 1px solid var(--ion-color-lines);
}
.filters .filter merz-text {
  margin-bottom: 10px;
}
.filters .filter:last-child {
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/filter-group/filter-group.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,+CAAA;AAAJ;AAEI;EACE,mBAAA;AAAN;AAGI;EACE,mBAAA;AADN","sourcesContent":[".filters {\n  .filter {\n    padding: 12px 0;\n    border-bottom: 1px solid var(--ion-color-lines);\n\n    merz-text {\n      margin-bottom: 10px;\n    }\n\n    &:last-child {\n      border-bottom: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
