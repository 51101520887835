import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {

  constructor() { }

  calculateDaysInBetweenTwoDates(firstDate: Date, secondDate: Date) {
    const differenceInTime = secondDate.getTime() - firstDate.getTime();
    return differenceInTime / (1000 * 3600 * 24);
  }
  
}
