import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';

@Component({
  selector: 'merz-header-workcenter',
  templateUrl: './header-workcenter.component.html',
  styleUrls: ['./header-workcenter.component.scss']
})
export class HeaderWorkcenterComponent implements OnInit {

  constructor(
    protected navController: NavController,
    protected modalController: ModalController
    ) {
	}
	ngOnInit() {}

	goBack(){
    this.navController.navigateBack([MERZ_ROUTES.profileComplete]);
  }

  goToAddWorkcenter(){
    this.navController.navigateForward([MERZ_ROUTES.workCenterNewComplete]);
  }
}
