import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';

@Component({
  selector: 'merz-settings-button',
  templateUrl: './settings-button.component.html',
  styleUrls: ['./settings-button.component.scss']
})
export class SettingsButtonComponent implements OnInit {

  constructor(
    protected navController: NavController,
  ) { }

  ngOnInit() {
  }

  goToSettings(){
    this.navController.navigateForward([MERZ_ROUTES.settingsComplete]);
  }
}
