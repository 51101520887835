// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-item {
  padding: 10px 0;
  border-top: 1px solid var(--ion-color-lines);
  border-bottom: 1px solid var(--ion-color-lines);
  --padding-start: 0;
  --inner-padding-end: 0;
  --inner-border-width: 0;
}
ion-item.modal {
  border-top: 1px solid var(--ion-color-background);
  border-bottom: none;
}

ion-thumbnail {
  --size: 55px;
  --border-radius: 100%;
  background: var(--ion-color-background);
  border: 1px solid var(--ion-color-lines);
  margin: 0 16px 0 0;
}
ion-thumbnail.with-no-image {
  padding: 10px;
}

.circle {
  border-radius: 100%;
  width: 44px;
  height: 44px;
  background: var(--ion-color-background);
  display: grid;
  place-items: center;
}
.circle ion-icon {
  font-size: 23px;
}`, "",{"version":3,"sources":["webpack://./src/app/sections/speaker-follow-block/speaker-follow-block.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,eAAA;EACA,4CAAA;EACA,+CAAA;EACA,kBAAA;EACA,sBAAA;EACA,uBAAA;AACF;AACE;EACE,iDAAA;EACA,mBAAA;AACJ;;AAEA;EACE,YAAA;EACA,qBAAA;EACA,uCAAA;EACA,wCAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;AACJ;;AAIA;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,uCAAA;EACA,aAAA;EACA,mBAAA;AADF;AAGE;EACE,eAAA;AADJ","sourcesContent":[":host {\n  display: block;\n}\n\nion-item{\n  padding: 10px 0;\n  border-top: 1px solid var(--ion-color-lines);\n  border-bottom: 1px solid var(--ion-color-lines);\n  --padding-start: 0;\n  --inner-padding-end: 0;\n  --inner-border-width: 0;\n\n  &.modal {\n    border-top: 1px solid var(--ion-color-background);\n    border-bottom: none;\n  }\n}\nion-thumbnail {\n  --size: 55px;\n  --border-radius: 100%;\n  background: var(--ion-color-background);\n  border: 1px solid var(--ion-color-lines);\n  margin: 0 16px 0 0;\n\n  &.with-no-image {\n    padding: 10px;\n  }\n}\n\n\n.circle {\n  border-radius: 100%;\n  width: 44px;\n  height: 44px;\n  background: var(--ion-color-background);\n  display: grid;\n  place-items: center;\n\n  ion-icon {\n    font-size: 23px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
