import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user';
import { KamUserApiClientService } from '../api/kam-user-api-client/kam-user-api-client.service';

@Injectable({
  providedIn: 'root'
})
export class FilterUserService {
  usersResult: User[] = [];
  filterParams = {};
  usersCount = 0;
  startSearchEvent = new Subject<any>();
  removeSearchEvent = new Subject<any>();
  pageSize = 10;

  constructor(
    protected kamUserApiClientService: KamUserApiClientService
    ) {
    this.startSearchEvent.subscribe((event) => {
      this.kamUserApiClientService.pageSize(this.pageSize).filter(this.filterParams).subscribe((data) => {
        this.usersResult = data.results;
        this.usersCount = data.count;
        event.target.complete();
        if(this.pageSize >= this.usersCount){
          event.target.disabled = true;
        }
      });
    });
    this.removeSearchEvent.subscribe(() => {
      this.cleanFilter();
    });
  }

  cleanFilter() {
    this.filterParams = {};
    this.usersResult = [];
    this.usersCount = 0;
  }

  loadMoreData(event){
    this.pageSize+=10;
    this.startSearchEvent.next(event);
  }

  get isFilterEmpty(){
    return Object.keys(this.filterParams).length === 0 && this.filterParams.constructor === Object;
  }
}
