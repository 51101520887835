import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { Speaker } from 'src/app/models/speaker';
import { SpeakerApiClientService } from 'src/app/services/api/speaker-api-client/speaker-api-client.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'merz-speaker-follow-block',
  templateUrl: './speaker-follow-block.component.html',
  styleUrls: ['./speaker-follow-block.component.scss']
})
export class SpeakerFollowBlockComponent implements OnInit {
  @Input() speaker: Speaker;
  @Input('from-modal') fromModal = false;
  @Input('from-formations') fromFormation = false;
  @Input('video-id') videoId = '';


  constructor(
    public toastController: ToastController,
    protected speakerApiClientService: SpeakerApiClientService,
    protected navController: NavController,
    protected modalController: ModalController,
    protected translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }
  get image() {
    return this.speaker?.image;
  }

  get speakerName() {
    return this.speaker?.title ? `${this.speaker?.title}. ${this.speaker?.name}` : this.speaker?.name;
  }


  get countText() {
    return this.speaker?.videosCount !== 1 ? `${this.speaker?.videosCount} ${this.translateService.instant('VIDEO.VIDEOS')}` : this.translateService.instant('VIDEO.ONE_VIDEO');
  }


  followSpeaker(event: Event) {
    event.stopPropagation();
    const speaker = new Speaker();
    speaker.id = this.speaker?.id;

    if (this.speaker?.followed) {
      this.speakerApiClientService.deleteAction(speaker, 'notification').subscribe((data) => {
        if (data['success']) {
          this.presentToast(false);
          this.speaker.followed = !this.speaker.followed;
        }
      });
    }
    else {
      this.speakerApiClientService.action(speaker, 'notification', {}).subscribe((data) => {
        if (data['success']) {
          this.presentToast();
          this.speaker.followed = !this.speaker.followed;
        }
      });
    }
  }

  goToSpeaker() {
    this.modalController.dismiss();
    this.navController.navigateForward([MERZ_ROUTES.speakerIdComplete.replace('${speakerId}', this.speaker?.id)]);
  }

  async presentToast(follow = true) {
    const aMessage = follow ? this.translateService.instant('SPEAKER.NOTIFICATIONS_ACTIVATE') : this.translateService.instant('SPEAKER.NOTIFICATIONS_DEACTIVATE');

    const toast = await this.toastController.create({
      message: aMessage,
      duration: 2000,
      color: 'dark',
      cssClass: 'toast',
      buttons: [
        {
          side: "end",
          icon: 'close-outline',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }


}
