import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'dnest-core-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";
  @Input('text') text = "";
  @Input('text-theme') textTheme: string = "primary";
  @Input('text-type') textType: string = "p-1";
  @Input('value') value = "";
  _radioController: UntypedFormControl = new UntypedFormControl('');;
  @Input('input-controller') set radioController(controller: UntypedFormControl) {
    this._radioController = controller;
    this._radioController.registerOnChange(() => this.checkSelected());
    this.checkSelected();
  }
  get radioController(): UntypedFormControl {
    return this._radioController;
  }

  selected = false;

  constructor() { }

  ngOnInit() {
    this.checkSelected();
  }

  onClick(event: any) {
    this.radioController.setValue(this.value);
    this.checkSelected();
  }

  checkSelected() {
    this.selected = this.radioController.value == this.value;
  }

}
