import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LibraryConfigService, LibraryConfig } from '../../../../config';
import { LoginApiClientService } from '../../../api/services/api-client/login-api-client/login-api-client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  routes: any;

  constructor(
    protected loginApiClientService: LoginApiClientService,
    protected router: Router,
    @Inject(LibraryConfigService) config: LibraryConfig
  ) {
    this.routes = config.routes;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.loginApiClientService.isLoggedIn()) {
      return true
    } else {
      this.router.navigate([this.routes.login], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }

}
