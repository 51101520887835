import { Component, OnInit } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'merz-update-app-modal',
  templateUrl: './update-app-modal.component.html',
  styleUrls: ['./update-app-modal.component.scss'],
})
export class UpdateAppModalComponent implements OnInit {
  constructor(
    protected modalController: ModalController,
    private inAppBrowser: InAppBrowser,
    protected platform: Platform
  ) { }

  iosUrl = 'https://apps.apple.com/es/app/merzapp/id1083525272';
  androidUrl =
    'https://play.google.com/store/apps/details?id=es.innopulse.merz';

  ngOnInit() { }

  updateApp() {
    if (this.platform.is('android')) {
      this.inAppBrowser.create(this.androidUrl, '_blank');
    } else {
      this.inAppBrowser.create(this.iosUrl, '_blank');
    }
  }
}
