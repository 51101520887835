import { Base } from './base';
import { Product } from './product';
import { ProductCategory } from './product-category';

export class Gallery extends Base {
  createdDate?: string;
  updatedDate?: string;
  name?: string;
  description?: string;
  image?: string;
  language: string;
  category: string;
  products: Product[];
  images: any[];
  type: string;
  videoUrl: string;
  thumbnail: string;
  productcategory?: ProductCategory[];
  checked?: boolean;
}
