import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'merz-event-highlight-card',
  templateUrl: './event-highlight-card.component.html',
  styleUrls: ['./event-highlight-card.component.scss']
})
export class EventHighlightCardComponent implements OnInit {
  @Input() event: Event;
  @Output('see-event') seeEvent = new EventEmitter<{id: string; canSee: boolean}>();

  constructor() { }

  ngOnInit(): void {
  }

  get seePremiumTag(){
    return this.event?.canSee && (this.event?.accessLevel==='premium' || this.event?.accessLevel==='student');
  }


}
