import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Filter, FilterType } from 'src/app/models/filter';
import { User } from 'src/app/models/user';
import { EventApiClientService } from 'src/app/services/api/event-api-client/event-api-client.service';
import { FiltersGetOptionsService } from 'src/app/services/filters-get-options-service/filters-get-options.service';
import { FiltersService } from 'src/app/services/filters-service/filters.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'merz-filter-event-modal',
  templateUrl: './filter-event-modal.component.html',
  styleUrls: ['./filter-event-modal.component.scss'],
})
export class FilterEventModalComponent implements OnInit {
  filters: Filter[];
  @Input() user: User;

  routerEvent: Subscription;

  constructor(
    protected navController: NavController,
    protected eventApiClientService: EventApiClientService,
    protected filtersGetOptionsService: FiltersGetOptionsService,
    protected filtersService: FiltersService,
    protected router: Router,
    protected translateService: TranslateService
  ) {
    this.routerEvent = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tryCreateFilters();
      }
    });
  }

  ngOnInit() {
    this.tryCreateFilters();
  }

  tryCreateFilters() {
    if (
      this.filtersGetOptionsService?.eventTypesOptions?.length > 0 &&
      this.filtersGetOptionsService?.eventSubjectsOptions?.length > 0 &&
      this.filtersGetOptionsService?.productCategoriesOptions?.length > 0
    ) {
      this.createFilters();
    } else {
      setTimeout(() => {
        this.tryCreateFilters();
      }, 100);
    }
  }

  get filterParams() {
    return this.filtersService.filterParams;
  }

  createFilters() {
    this.filters = [
      new Filter({
        type: FilterType.RADIO_BUTTON,
        field: 'is_online',
        options: [
          { text: this.translateService.instant('FILTERS.EVENT.IS_ONLINE.ALL'), value: '' },
          { text: this.translateService.instant('FILTERS.EVENT.IS_ONLINE.ONSITE'), value: 'false' },
          { text: this.translateService.instant('FILTERS.EVENT.IS_ONLINE.ONLINE'), value: 'true' },
        ],
        optionSelectedValue: this.filterParams['is_online'],
      }),
      // new Filter(
      //   { type: FilterType.SELECT, field: 'position', options: [
      //     {text: 'Cerca de mí', value: 'true'}
      //   ], title: 'ubicación', placeholder: 'Cualquier lugar', optionSelectedValue: this.filterParams['position']}),
      new Filter({
        type: FilterType.CHECKBOX,
        options: this.filtersGetOptionsService.eventTypesOptions,
        field: 'type',
        title: this.translateService.instant('FILTERS.EVENT.TYPE'),
        optionSelectedValue: this.filterParams['type'],
      }),
      new Filter({
        type: FilterType.SELECT,
        field: 'product_category',
        options: this.filtersGetOptionsService.productCategoriesOptions,
        title: this.translateService.instant('FILTERS.EVENT.PRODUCT'),
        placeholder: this.translateService.instant('FILTERS.PLACEHOLDER'),
        optionSelectedValue: this.filterParams['product_category'],
      }),
      new Filter({
        type: FilterType.CHECKBOX,
        options: this.filtersGetOptionsService.eventSubjectsOptions,
        field: 'subject',
        title: this.translateService.instant('FILTERS.EVENT.SUBJECT'),
        optionSelectedValue: this.filterParams['subject'],
      }),
    ];
    const premiumFilter = new Filter({
      type: FilterType.CHECKBOX,
      field: 'accessLevel',
      options: [
        { text: this.translateService.instant('FILTERS.EVENT.ACCESS_LEVEL.OPTIONS.PREMIUM'), value: 'premium' },
        { text: this.translateService.instant('FILTERS.EVENT.ACCESS_LEVEL.OPTIONS.STUDENT'), value: 'student' },
      ],
      optionSelectedValue: this.filterParams['accessLevel'],
    });
    const masterFilter = new Filter({
      type: FilterType.CHECKBOX,
      field: 'accessLevel',
      options: [{ text: this.translateService.instant('FILTERS.EVENT.ACCESS_LEVEL.OPTIONS.STUDENT'), value: 'student' }],
      optionSelectedValue: this.filterParams['accessLevel'],
    });

    const kamFilter = new Filter({
      type: FilterType.CHECKBOX,
      field: 'accessLevel',
      options: [
        { text: this.translateService.instant('FILTERS.EVENT.ACCESS_LEVEL.OPTIONS.BASIC'), value: 'basic' },
        { text: this.translateService.instant('FILTERS.EVENT.ACCESS_LEVEL.OPTIONS.ADVANCE'), value: 'advance' },
        { text: this.translateService.instant('FILTERS.EVENT.ACCESS_LEVEL.OPTIONS.STUDENT'), value: 'student' },
        { text: this.translateService.instant('FILTERS.EVENT.ACCESS_LEVEL.OPTIONS.PREMIUM'), value: 'premium' },
      ],
      title: this.translateService.instant('FILTERS.EVENT.ACCESS_LEVEL.TITLE'),
      optionSelectedValue: this.filterParams['accessLevel'],
    });

    if (!this.user?.isKam && this.user?.accessLevel === 'premium') {
      this.filters.splice(1, 0, premiumFilter);
    } else if (!this.user?.isKam && this.user?.accessLevel === 'student') {
      this.filters.splice(1, 0, masterFilter);
    } else if (this.user?.isKam) {
      this.filters.splice(4, 0, kamFilter);
    }
  }

  cleanFilters() {
    this.filtersService.deleteFilters();
  }
}
