// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.modal-container {
  display: grid;
  place-items: center;
  height: calc(100% + var(--ion-safe-area-top));
  padding-top: calc(30px + var(--ion-safe-area-top));
  background: var(--ion-color-background);
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-container .header {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}
.modal-container .header ion-icon {
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 50px;
  font-size: 24px;
  color: var(--ion-color-medium);
}
.modal-container .content {
  padding: 0 24px 0 23px;
  margin-top: 35px;
  margin-bottom: calc(70px + var(--ion-safe-area-bottom));
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/plans-modal/plans-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,6CAAA;EACA,kDAAA;EACA,uCAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;AACJ;AACI;EACE,eAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,eAAA;EACA,8BAAA;AACN;AAGE;EACE,sBAAA;EACA,gBAAA;EACA,uDAAA;AADJ","sourcesContent":[":host {\n  display: block;\n}\n\n.modal-container {\n  display: grid;\n  place-items: center;\n  height: calc(100% + var(--ion-safe-area-top));\n  padding-top: calc(30px + var(--ion-safe-area-top));\n  background: var(--ion-color-background);\n  overflow-y: auto;\n  overflow-x: hidden;\n\n  .header {\n    display: flex;\n    justify-content: center;\n    position: relative;\n    width: 100%;\n\n    ion-icon {\n      cursor: pointer;\n      position: absolute;\n      top: 3px;\n      left: 50px;\n      font-size: 24px;\n      color: var(--ion-color-medium);\n    }\n  }\n\n  .content {\n    padding: 0 24px 0 23px;\n    margin-top: 35px;\n    margin-bottom: calc(70px + var(--ion-safe-area-bottom));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
