import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeSmallBlockComponent } from './time-small-block.component';
import { TextModule } from 'src/app/components/text/text.module';



@NgModule({
  declarations: [
    TimeSmallBlockComponent
  ],
  imports: [
    CommonModule,
    TextModule
  ],
  exports: [
    TimeSmallBlockComponent
  ]
})
export class TimeSmallBlockModule { }
