import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { Gallery } from 'src/app/models/gallery';

@Injectable({
  providedIn: 'root'
})
export class GalleryApiClientService extends ApiClientService<Gallery> {
  endpoint = 'gallery';
}

