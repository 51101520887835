import { Component, Input, OnInit } from '@angular/core';
import { Formation } from 'src/app/models/formation';

@Component({
  selector: 'merz-formation-card',
  templateUrl: './formation-card.component.html',
  styleUrls: ['./formation-card.component.scss']
})
export class FormationCardComponent implements OnInit {
  @Input() formation: Formation;
  @Input() online = false;
  constructor() { }

  ngOnInit(): void {
  }


  get duration(){
    return `${this.formation?.start.substr(11,5)} - ${this.formation?.end.substr(11,5)}`;
  }

  get showSpeakers(){
    return this.formation?.speakers?.length > 1;
  }

  get seePremiumTag(){
    return this.formation?.canSee && (this.formation?.accessLevel==='premium' || this.formation?.accessLevel==='student');
  }
}
