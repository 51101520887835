import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LibraryConfigService, LibraryConfig } from '../../../config';

export const CRUD_URL_CLASS_NAME = new InjectionToken<string>('CRUD_URL_CLASS_NAME');

@Injectable({
  providedIn: 'root'
})
export class CRUDService<T> {
  protected readonly _headers: {
    "Content-Type"?: string;
    "Accept"?: string;
  } = {
    "Content-Type": "application/json",
    "Accept": "application/json"
  };

  protected get headers() {
    let result = { ...this._headers };
    return result;
  }

  private _urlBase: string = "";
  get urlBase() { return this._urlBase }
  set urlBase(value: string) { this._urlBase = value }

  private _className: string = "";
  get urlClassName() { return this._className == "" ? this.constructor.name : this._className; }
  set urlClassName(value: string) { this._className = value }

  constructor(
    protected http: HttpClient,
    @Inject(CRUD_URL_CLASS_NAME) urlClassName: string = "",
    @Inject(LibraryConfigService) protected config: LibraryConfig
  ) {
    this.urlClassName = urlClassName;
    this.urlBase = config.urlBase!;
  }

  public get endpointURL() {
    return `${this.urlBase}${this.urlClassName}/`;
  }

  params: any = {};

  getParams() {
    let paramsName = Object.keys(this.params);
    let result: any = {};
    paramsName.forEach(p =>
      !this.isEmpty(this.params[p]) ? (result[p] = this.params[p]) : null
    );

    return result;
  }

  isEmpty(arg: any) {
    return !arg || arg.length === 0;
  }

  // GET METHODS

  getItem(postfix?: string): Observable<T> {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };

    let endpoint = !!postfix ? `${this.endpointURL}${postfix}/` : this.endpointURL;

    const req = this.http.get<T>(endpoint, options);
    return req;
  }

  getItemByID(id: string, postfix?: string): Observable<T> {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };

    let endpoint = !!postfix ? `${this.endpointURL}${postfix}/${id}/` : `${this.endpointURL}${id}/`;
    const req = this.http.get<T>(endpoint, options);
    return req;
  }

  // POST METHODS

  postItem(body: T, postfix?: string): Observable<T> {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };

    let endpoint = !!postfix ? `${this.endpointURL}${postfix}/` : this.endpointURL;

    const req = this.http.post<T>(endpoint, body, options);
    return req;
  }

  postItemAsFormData(body: T, postfix?: string): Observable<T> {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };

    delete (options.headers as any)['Content-Type'];
    delete (options.headers as any)['Accept'];

    let endpoint = !!postfix ? `${this.endpointURL}${postfix}/` : this.endpointURL;

    const req = this.http.post<T>(endpoint, body, options);
    return req;
  }

  patchItemAsFormData(body: T, postfix?: string): Observable<T> {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };

    delete (options.headers as any)['Content-Type'];
    delete (options.headers as any)['Accept'];

    let endpoint = !!postfix ? `${this.endpointURL}${postfix}/` : this.endpointURL;

    const req = this.http.patch<T>(endpoint, body, options);
    return req;
  }

  // UPDATE METHODS

  updatePatchItemByID(body: any, id: string): Observable<T> {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };
    let endpoint = `${this.endpointURL}${id}/`;
    const req = this.http.patch<T>(endpoint, body, options);
    return req;
  }

  updatePutItemByID(body: any, id: string): Observable<T> {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };
    let endpoint = `${this.endpointURL}${id}/`;
    const req = this.http.put<T>(endpoint, body, options);
    return req;
  }

  updatePatchItem(body: any): Observable<T> {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };
    const req = this.http.patch<T>(this.endpointURL, body, options);
    return req;
  }

  updatePutItem(body: any): Observable<T> {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };
    const req = this.http.put<T>(this.endpointURL, body, options);
    return req;
  }

  // DELETE METHODS

  deleteItemByID(id: string, postfix?: string) {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };

    let endpoint = !!postfix ? `${this.endpointURL}${postfix}/${id}/` : `${this.endpointURL}${id}/`;

    const req = this.http.delete<T>(endpoint, options);

    return req;
  }

  deleteItem() {
    let options = {
      headers: this.headers,
      params: this.getParams()
    };

    let endpoint = this.endpointURL;
    const req = this.http.delete<T>(endpoint, options);

    return req;
  }

}
