import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dnest-core-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  _pdfUrl = "";
  @Input('pdf-url')
  set pdfURL(pdfUrl: string) {
    this._pdfUrl = pdfUrl;
    if (this._pdfUrl) {
      this.pdfFile = this.domSanitizer.bypassSecurityTrustResourceUrl(this._pdfUrl)
    } else {
      this.pdfFile = '';
    }
  }
  get pdfUrl() : string {
    return this._pdfUrl;
  }

  private _pdfFile: SafeResourceUrl = '';
  public get pdfFile(): SafeResourceUrl { return this._pdfFile }
  public set pdfFile(value: SafeResourceUrl) { this._pdfFile = value; }


  constructor(
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
  }

}
