import { Injectable } from '@angular/core';
import { BaseDetailResolver } from './base-detail.resolver';
import { Product } from 'src/app/models/product';
import { ProductApiClientService } from '../api/product-api-client/product-api-client.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotFoundService } from '../not-found/not-found.service';

@Injectable({
  providedIn: 'root',
})
export class ProductResolver extends BaseDetailResolver<Product> {
  protected idParam = 'productId';

  constructor(
    protected service: ProductApiClientService,
    protected notFoundService: NotFoundService
  ) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.service.get(route.paramMap.get(this.idParam)).pipe(
      map((data) => {
        this.notFoundService.showNotFound = false;
        return data;
      }),
      catchError((error) => {
        if (error.status === 404) {
          this.notFoundService.showNotFound = true;
        }
        return of(undefined);
      })
    );
  }
}
