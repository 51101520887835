import { InjectionToken } from '@angular/core';
import { IStorageService } from './lib/models/interfaces';

export interface LibraryConfig {
    urlBase?: string;
    storage?: IStorageService;
    routes: any;
    loginClientService?: any;
    localStorageService?: any;
    notificationService?: any;
    formManagementService?: any;
    loginClientServiceClass?: any;
}

export const LibraryConfigService = new InjectionToken<LibraryConfig>('LibraryConfig');