// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}

ion-card {
  display: block;
  width: 273px;
  height: 300px;
  padding: 14px;
  margin-top: 0;
}
ion-card img {
  min-height: 169px;
  height: 169px;
  width: 100%;
}
ion-card ion-card-content {
  padding: 0;
}
ion-card ion-card-content .margin5 {
  margin-bottom: 2px;
}
ion-card ion-card-content .ellipsis {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/product-card/product-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;;AAGA;EACE,cAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,aAAA;AAAF;AAEE;EACE,iBAAA;EACA,aAAA;EACA,WAAA;AAAJ;AAGE;EACE,UAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAII;EACE,qBAAA;EACA,4BAAA;EACA,oBAAA;EACA,gBAAA;EACA,iBAAA;AAFN","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n}\n\n\nion-card {\n  display: block;\n  width: 273px;\n  height: 300px;\n  padding: 14px;\n  margin-top: 0;\n\n  img {\n    min-height: 169px;\n    height: 169px;\n    width: 100%;\n  }\n\n  ion-card-content {\n    padding: 0;\n\n    .margin5 {\n      margin-bottom: 2px;\n    }\n\n    .ellipsis {\n      -webkit-line-clamp: 3;\n      -webkit-box-orient: vertical;\n      display: -webkit-box;\n      overflow: hidden;\n      line-height: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
