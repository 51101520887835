import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseDetailResolver } from './base-detail.resolver';
import { UserApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { User } from 'src/app/models/user';
import { NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { UserDetailService } from '../user-detail-service/user-detail.service';

@Injectable({
  providedIn: 'root',
})
export class UserResolver extends BaseDetailResolver<User> {
  constructor(
    protected service: UserApiClientService,
    protected navController: NavController,
    protected userDetailService: UserDetailService
  ) {
    super();
  }

  resolve(): Observable<any> {
    return this.service.get().pipe(
      map((user) => {
        this.userDetailService.userDetail = user;
        if (user['phoneValidated']) {
          return user;
        } else {
          this.navController.navigateBack([
            MERZ_ROUTES.registerPhoneConfirmComplete,
          ]);
          return undefined;
        }
      }),
      catchError((error) => {
        return of(undefined);
      })
    );
  }
}
