import { Injectable } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { Login } from '../../../models/login';

@Injectable({
  providedIn: 'root'
})
export class LogoutApiClientService extends ApiClientService<Login> {
  override endpoint = 'rest-auth/logout';

  logout() {
    this.create(new Login()).subscribe(
      data => {
        this.removeToken();
        window.location.reload();
      }
    );
  }

  removeToken() {
    this.loginService.cleanToken();
  }
}
