import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { debounceTime } from 'rxjs/operators';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { UserWorkCenter } from 'src/app/models/user-work-center';
import { KamWorkcenterApiClientService } from 'src/app/services/api/kam-workcenter-api-client/kam-workcenter-api-client.service';
import { UserWorkCenterApiClientService } from 'src/app/services/api/user-work-center-api-client/user-work-center-api-client.service';
import { DataResolverService } from 'src/app/services/data-resolver/data-resolver.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-workcenter',
  templateUrl: './input-workcenter.component.html',
  styleUrls: ['./input-workcenter.component.scss'],
})
export class InputWorkcenterComponent extends InputComponent {

  workcenters: UserWorkCenter[] = [];

  constructor(
    protected kamWorkcenterApiClientService: KamWorkcenterApiClientService,
    protected userWorkCenterApiClientService: UserWorkCenterApiClientService,
    protected dataResolverService: DataResolverService,
    protected activatedRoute: ActivatedRoute,
    protected navController: NavController,
    protected localStorageService: LocalStorageService

  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.activatedRoute.url.subscribe((url) => {
      this.workcenters = this.dataResolverService.getData(
        this.activatedRoute,
        'kamWorkCenters'
      );
    });
    this.formControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.kamWorkcenterApiClientService
      .filter({ search: value })
      .subscribe((result) => {
        this.workcenters = result.results;
      });
    });
  }

  selectWorkCenter(workcenter: UserWorkCenter){
    this.formControl.setValue(workcenter.name);
    this.sendWorkcenter(workcenter);
  }

  sendWorkcenter(workcenter){
    const userWorkcenter = new UserWorkCenter();
    userWorkcenter.name = workcenter['name'];
    userWorkcenter.address = workcenter['address'];
    userWorkcenter.zipCode = workcenter['zipCode'];
    userWorkcenter.state = workcenter['state'];
    userWorkcenter.country = workcenter['country'];
    userWorkcenter.phone = workcenter['phone'];

    this.userWorkCenterApiClientService.create(userWorkcenter).subscribe((result) => {
      console.log(result);
    });
  }

  get empty() {
    return this.workcenters.length === 0;
  }

  get buttonText() {
    return `Añadir clínica ${this.formControl.value}`;
  }

  goToAddWorkcenter(){
    if(this.empty){
      this.localStorageService.saveObject(this.localStorageService.CREATE_WORKCENTER_NAME, this.formControl.value);
      this.navController.navigateForward([MERZ_ROUTES.registerWorkcenterAddComplete]);
    }
  }
}
