import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadAnimationComponent } from './load-animation.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    LoadAnimationComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    LoadAnimationComponent
  ],
})
export class LoadAnimationModule { }
