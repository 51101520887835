import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { FiltersService } from 'src/app/services/filters-service/filters.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';

@Component({
  selector: 'merz-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit, OnDestroy {
  MERZ_ROUTES = MERZ_ROUTES;
  searchControl = new UntypedFormControl();
  searchText = '';

  focus = false;
  routerEvent: Subscription;


  constructor(
    protected filtersService: FiltersService,
    protected navController: NavController,
    protected localStorageService: LocalStorageService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router
  ) {
    this.routerEvent = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //si no hay filtro search, limpiamos el input
        //si hay, en el input mostramos el valor del filtro
        const value = this.activatedRoute.snapshot.queryParamMap.get('search');
        if (value) {
          this.searchControl.setValue(value);
        } else {
          this.searchControl.setValue('');
        }
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.routerEvent.unsubscribe();
  }

  search() {
    this.searchText = this.searchControl.value;
    this.goToFormation();
  }

  goToFormation() {
    this.filtersService.getfilterData({ search: this.searchText }, MERZ_ROUTES.formationComplete);
  }
}
