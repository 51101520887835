import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { ProductCategory } from 'src/app/models/product-category';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryApiClientService extends ApiClientService<ProductCategory> {
  endpoint = 'productcategory';
}


