// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-card {
  display: block;
  width: 235px;
  height: 240px;
}
ion-card ion-card-content {
  padding: 0;
}
ion-card ion-card-content ion-item {
  height: 100%;
  --padding-start: 0px;
  --inner-border-width: 0;
  --inner-padding-top: 30px;
  --inner-padding-bottom: 15px;
  --inner-padding-start: 19px;
  --inner-padding-end: 10px;
  --background-activated: none;
  --background: var(--ion-color-white);
  --detail-icon-color: var(--ion-color-warning);
  --detail-icon-opacity: 1;
  --background-activated-opacity: 1;
  --detail-icon-font-size: 20px;
  --background-focused: none;
  --background-hover: none;
}
ion-card ion-card-content ion-item.padding {
  --inner-padding-end: 19px;
}
ion-card ion-card-content ion-item ion-thumbnail {
  --size: 57px;
  --border-radius: 4px;
}
ion-card ion-card-content ion-item .margin {
  margin-top: 13px;
}
ion-card ion-card-content .ellipsis {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: 21px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/phase-card/phase-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAGA;EACE,cAAA;EACA,YAAA;EACA,aAAA;AAAF;AAEE;EACE,UAAA;AAAJ;AAEI;EACE,YAAA;EACA,oBAAA;EACA,uBAAA;EACA,yBAAA;EACA,4BAAA;EACA,2BAAA;EACA,yBAAA;EACA,4BAAA;EACA,oCAAA;EACA,6CAAA;EACA,wBAAA;EACA,iCAAA;EACA,6BAAA;EACA,0BAAA;EACA,wBAAA;AAAN;AAEM;EACE,yBAAA;AAAR;AAIM;EACE,YAAA;EACA,oBAAA;AAFR;AAKM;EACE,gBAAA;AAHR;AAOI;EACE,qBAAA;EACA,4BAAA;EACA,oBAAA;EACA,gBAAA;EACA,iBAAA;AALN","sourcesContent":[":host {\n  display: block;\n}\n\n\nion-card {\n  display: block;\n  width: 235px;\n  height: 240px;\n\n  ion-card-content {\n    padding: 0;\n\n    ion-item {\n      height: 100%;\n      --padding-start: 0px;\n      --inner-border-width: 0;\n      --inner-padding-top: 30px;\n      --inner-padding-bottom: 15px;\n      --inner-padding-start: 19px;\n      --inner-padding-end: 10px;\n      --background-activated: none;\n      --background: var(--ion-color-white);\n      --detail-icon-color: var(--ion-color-warning);\n      --detail-icon-opacity: 1;\n      --background-activated-opacity: 1;\n      --detail-icon-font-size: 20px;\n      --background-focused: none;\n      --background-hover: none;\n\n      &.padding {\n        --inner-padding-end: 19px;\n      }\n\n\n      ion-thumbnail {\n        --size: 57px;\n        --border-radius: 4px;\n      }\n\n      .margin {\n        margin-top: 13px;\n      }\n    }\n\n    .ellipsis {\n      -webkit-line-clamp: 5;\n      -webkit-box-orient: vertical;\n      display: -webkit-box;\n      overflow: hidden;\n      line-height: 21px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
