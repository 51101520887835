// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  background: var(--ion-color-bg-update);
}

.modal-container {
  height: calc(100% + var(--ion-safe-area-top));
  width: 100%;
}
.modal-container .content {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: calc(50px + var(--ion-safe-area-bottom));
  padding-top: calc(50px + var(--ion-safe-area-top));
}
.modal-container .content .box {
  text-align: center;
  max-width: 290px;
  margin: 0 auto;
}
.modal-container .content .box img {
  margin-bottom: -30px;
}
.modal-container .content .box .first {
  margin-bottom: 23px;
}
.modal-container .corner-image {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/update-app-modal/update-app-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,sCAAA;AACF;;AAEA;EACE,6CAAA;EACA,WAAA;AACF;AACE;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,8BAAA;EACA,wDAAA;EACA,kDAAA;AACJ;AACI;EACE,kBAAA;EACA,gBAAA;EACA,cAAA;AACN;AACM;EACE,oBAAA;AACR;AAEM;EACE,mBAAA;AAAR;AAKE;EACE,eAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;AAHJ","sourcesContent":[":host {\n  display: block;\n  background: var(--ion-color-bg-update);\n}\n\n.modal-container {\n  height: calc(100% + var(--ion-safe-area-top));\n  width: 100%;\n\n  .content {\n    padding: 0 24px;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    justify-content: space-between;\n    padding-bottom: calc(50px + var(--ion-safe-area-bottom));\n    padding-top: calc(50px + var(--ion-safe-area-top));\n\n    .box {\n      text-align: center;\n      max-width: 290px;\n      margin: 0 auto;\n\n      img {\n        margin-bottom: -30px;\n      }\n\n      .first {\n        margin-bottom: 23px;\n      }\n    }\n  }\n\n  .corner-image {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    z-index: -1;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
