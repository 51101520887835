import { Component, Input, OnInit } from '@angular/core';
import { Video } from 'src/app/models/video';

@Component({
  selector: 'merz-lateral-video-card',
  templateUrl: './lateral-video-card.component.html',
  styleUrls: ['./lateral-video-card.component.scss']
})
export class LateralVideoCardComponent implements OnInit {
  @Input() video: Video;

  constructor() { }

  ngOnInit(): void {
  }

  get speakerName(){
    return this.video?.speaker?.title ? `${this.video?.speaker?.title}. ${this.video?.speaker?.name}`: this.video?.speaker?.name;
  }

  get showSpeakers(){
    return this.video?.speakers?.length > 1;
  }
}
