import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dnest-core-loader-circles',
  templateUrl: './loader-circles.component.html',
  styleUrls: ['./loader-circles.component.scss']
})
export class LoaderCirclesComponent implements OnInit {
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";

  constructor() { }

  ngOnInit(): void {
  }

}
