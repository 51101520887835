import { Component, Input, OnInit } from '@angular/core';
import { Formation } from 'src/app/models/formation';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'merz-horizontal-formation-card',
  templateUrl: './horizontal-formation-card.component.html',
  styleUrls: ['./horizontal-formation-card.component.scss']
})
export class HorizontalFormationCardComponent implements OnInit {
  @Input() formation: Formation;
  @Input() onsite = false;

  constructor(protected utilsService: UtilsService) { }

  ngOnInit(): void {
  }

  get duration(){
    return `${this.formation?.start.substr(11,5)}`;
  }

  get date(){
    return `${this.weekDay}, ${this.monthDay} ${this.month}, ${this.duration}`;
  }

  get weekDay(){
    return this.utilsService.daysToText[new Date(this.formation?.start).getDay()];
  }
  get monthDay(){
    return new Date(this.formation?.start).getDate();
  }

  get month(){
    return this.utilsService.monthsToText[new Date(this.formation?.start).getMonth()];
  }

  get location() {
    return this.onsite ? this.formation?.location : 'Formación en directo';
  }
}
