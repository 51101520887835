// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  position: absolute;
  bottom: 0;
  background: var(--ion-color-white);
  z-index: 5;
  overflow: auto;
  width: 100%;
  padding: 15px 24px 40px;
}
.modal-container ion-icon {
  font-size: 25px;
  margin-left: -8px;
}
.modal-container .buttons {
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/disclaimer-modal/disclaimer-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,kCAAA;EACA,UAAA;EACA,cAAA;EACA,WAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[".modal-container {\n  position: absolute;\n  bottom: 0;\n  background: var(--ion-color-white);\n  z-index: 5;\n  overflow: auto;\n  width: 100%;\n  padding: 15px 24px 40px;\n\n  ion-icon {\n    font-size: 25px;\n    margin-left: -8px;\n  }\n\n  .buttons {\n    margin-top: 30px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
