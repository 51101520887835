import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class SeoService {
	constructor(protected title: Title, protected meta: Meta) {}

	setTitle(title: string) {
		this.title.setTitle(title);
	}
	getTitle() {
		return this.title.getTitle();
	}

	setDescription(description: string) {
		this.meta.addTags([
			{
				name: 'description',
				content: description,
			},
		]);
	}
}
