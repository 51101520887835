import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';

@Component({
  selector: 'merz-header-kam-workcenter',
  templateUrl: './header-kam-workcenter.component.html',
  styleUrls: ['./header-kam-workcenter.component.scss']
})
export class HeaderKamWorkcenterComponent implements OnInit {

  constructor(
    protected navController: NavController,
    protected modalController: ModalController
    ) {
	}
	ngOnInit() {}

	goBack() {
    return this.navController.navigateBack(MERZ_ROUTES.profileComplete);
  }

  goToAddWorkcenter(){
    this.navController.navigateForward([MERZ_ROUTES.kamWorkCentersNewComplete]);
  }
}
