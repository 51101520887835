import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user';
import { KamUserApiClientService } from '../api/kam-user-api-client/kam-user-api-client.service';
import { BaseListResolver } from './base-list.resolver';

@Injectable({
  providedIn: 'root'
})
export class KamUsersResolver extends BaseListResolver<User> {
  constructor(
    protected service: KamUserApiClientService,
  ) {
    super();
  }
}
