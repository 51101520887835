// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-toolbar {
  --padding-start: 23px;
  --padding-end: 15px;
}
ion-header ion-toolbar.b-transparent {
  --background: transparent;
}
ion-header ion-toolbar.b-white {
  --background: var(--ion-color-white);
}
ion-header ion-toolbar.b-background {
  --background: var(--ion-color-background);
}
ion-header ion-toolbar.b-primary {
  --background: var(--ion-color-primary);
}
ion-header ion-toolbar ion-buttons {
  width: 45px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header/header.component.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,yBAAA;AAAN;AAGI;EACE,oCAAA;AADN;AAII;EACE,yCAAA;AAFN;AAKI;EACE,sCAAA;AAHN;AAOI;EACE,WAAA;AALN","sourcesContent":["ion-header {\n  ion-toolbar {\n    --padding-start: 23px;\n    --padding-end: 15px;\n\n    &.b-transparent {\n      --background: transparent;\n    }\n\n    &.b-white {\n      --background: var(--ion-color-white);\n    }\n\n    &.b-background {\n      --background: var(--ion-color-background);\n    }\n\n    &.b-primary {\n      --background: var(--ion-color-primary);\n    }\n\n\n    ion-buttons {\n      width: 45px;\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
