import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { Subject } from 'rxjs';
import { Video } from 'src/app/models/video';

@Injectable({
  providedIn: 'root'
})
export class VideoApiClientService extends ApiClientService<Video> {
  endpoint = 'video';
  videoFavoritesEvent = new Subject<any>();
  playNextVideo = true;
}

