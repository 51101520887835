import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Plan } from 'src/app/models/plan';
import { PlanApiClientService } from '../api/plan-api-client/plan-api-client.service';
import { UtilsService } from '../utils/utils.service';
import { BaseListResolver } from './base-list.resolver';

@Injectable({
  providedIn: 'root'
})
export class PlansResolver extends BaseListResolver<Plan> {
  protected pageSize = 20;

  constructor(
    protected service: PlanApiClientService,
    protected utilsService: UtilsService
  ) {
    super();
  }

  resolve(): Observable<any> {
    return this.service.pageSize(this.pageSize).all().query.execute().pipe(
      map(paginator => {
        this.utilsService.plans = paginator['results'];
        return paginator['results'];
      }),
      catchError((error) => {
        return of(undefined);
      })
    );
  }
}
