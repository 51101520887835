import { EventEmitter } from '@angular/core';
import { FilterOption } from './filter-option';
import { Queryset } from '../../api/queryset';

export enum FilterType {
  select = "select",
  boolean = "boolean",
  numberRange = "numberRange",
  dateRange = "dateRange",
  text = "text",
  search = "search"
}

export class Filter {
  name: string;
  allOption: boolean;
  allText: string;
  paramName: string;
  options: FilterOption[];
  defaultValue?: any;
  type: FilterType;
  extra: any;
  close = true;
  moreFilters: Filter[];
  moreFiltersParams :{}[] = [];
  showMoreFilters = false;

  currentValue: any;

  isSelectedAnyOption = false;
  isNotSelectedAnyOption = true;
  downloadServiceList = new EventEmitter<any>();
  changeValueEvent = new EventEmitter<any>();

  /**
   * @param name: Nombre a visualizar para el filtro
   * @param paramName: El parametro que se pasará al request
   * @param type: Tipo de filtro (FilterType)
   * @param extra: Información extra necesaria para otros tipos de filtros
   *                como por ejemplo
   *                    allOption: Si se mostrará en el filtro la opcion ver todos
   *                    options: array de filterOptions
   *                    defaultValue: valor por defecto
   *                    allText: texto a mostrar para todas las opciones
   *                    queryset: queryset a utilizar para obtener las opciones
   *                    querysetIdProperty: campo para obtener id (por defecto: id)
   *                    querysetNameProperty: campo para obtener nombre (por defecto: name)
   */
  constructor(name: string,
    paramName: string,
    type?: FilterType,
    extra?: {
      allOption?: boolean,
      allText?: string,
      options?: FilterOption[],
      defaultValue?: any,
      queryset?: Queryset<any>,
      querysetIdProperty?: string,
      querysetNameProperty?: string,
      minValue?: any,
      maxValue?: any,
      moreFilters?: Filter[],
      close?: boolean
    }) {
    this.name = name;
    this.paramName = paramName;
    this.type = type || FilterType.select;
    this.extra = extra;

    this.allText = '';
    if (extra && extra['allText'] !== undefined) {
      this.allText = extra['allText'];
    }

    this.allOption = this.type === FilterType.select;
    if (extra && extra['allOption'] !== undefined) {
      this.allOption = extra['allOption'];
    }

    this.options = [];
    if (extra && extra['options']) {
      this.options = extra['options'];
    }

    this.defaultValue = '';
    if (this.type === FilterType.boolean) {
      this.defaultValue = 1;
    }
    if (extra && extra['defaultValue'] !== undefined) {
      this.defaultValue = extra['defaultValue'];
    }

    this.moreFilters = [];
    if (extra && extra['moreFilters'] !== undefined) {
      this.moreFilters = extra['moreFilters'];
      this.moreFilters.forEach(
        moreFilter => {
          moreFilter.changeValueEvent.subscribe(
            moreFilter => {
              this.moreFiltersParams = [];
              this.moreFilters.forEach(
                moreFilter => {
                  this.moreFiltersParams.push(moreFilter.getQuerysetParams());
                }
              );
              this.changeValueEvent.emit({ filter: this, value: this.currentValue });
            }
          );
        }
      );
    }

    this.close = true;
    if (extra && extra['close'] !== undefined) {
      this.close = extra['close'];
    }

    if (type === FilterType.select && extra && extra['queryset']) {
      const idProperty = extra && extra['querysetIdProperty'] || 'id';
      const nameProperty = extra && extra['querysetNameProperty'] || 'name';
      if (this.allOption) {
        this.options.push(new FilterOption(
          '',
          this.allText,
          !this.currentValue
        ));
      }
      extra['queryset'].subscribe(
        ( response: { results: any; count: any; }) => {
          let results = response.results;
          let count = response.count;
          if (results === undefined) {
            results = response;
            count = results.length;
          }
          for (let i = 0; i < count; i++) {
            this.options.push(new FilterOption(
              results[i][idProperty],
              results[i][nameProperty],
              results[i][idProperty] == this.currentValue
            ));
          }
          this.downloadServiceList.emit({ filter: this });
        }
      );
    }

    this.initialValue();
  }

  initialValue() {
    switch (this.type) {
      case FilterType.numberRange:
      case FilterType.dateRange:
        this.changeValue({
          min: this.extra && this.extra.minValue,
          max: this.extra && this.extra.maxValue
        });
        break;
      default:
        this.changeValue(this.defaultValue);
        break;
    }
  }

  initValue(params: any) {
    switch (this.type) {
      case FilterType.boolean:
        this.changeValue(!!+(params[this.paramName] || this.defaultValue));
        break;
      case FilterType.numberRange:
      case FilterType.dateRange:
        this.changeValue({
          min: params[this.paramName + '_min'] || (this.extra && this.extra.minValue),
          max: params[this.paramName + '_max'] || (this.extra && this.extra.maxValue)
        });
        break;
      default:
        this.changeValue(params[this.paramName] || this.defaultValue);
        break;
    }
  }

  getFilterParamDisplay() {
    switch (this.type) {
      case FilterType.numberRange:
        if (this.currentValue.min !== undefined && this.currentValue.max !== undefined) {
          if (!this.extra || this.extra.minValue === undefined || this.extra.maxValue === undefined ||
            this.currentValue.min != this.extra.minValue || this.currentValue.max != this.extra.maxValue) {
            return this.currentValue.min + ' - ' + this.currentValue.max + ' ' + this.extra.unitName;
          }
        }
        break;
      case FilterType.dateRange:
        if (this.currentValue.min !== undefined && this.currentValue.max !== undefined) {
          return this.currentValue.min + ' - ' + this.currentValue.max;
        }
        break;
      case FilterType.text:
        if (this.currentValue) {
          return this.currentValue;
        }
        break;
      default:
        if (this.currentValue && this.options && this.options.length) {
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].value == this.currentValue) {
              return this.options[i].key;
            }
          }
        }
        break;
    }
    return '';
  }

  changeValue(value: any) {
    let changed = false;
    switch (this.type) {
      case FilterType.numberRange:
      case FilterType.dateRange:
        if (!this.currentValue || this.currentValue.min != value.min || this.currentValue.max != value.max) {
          this.currentValue = { min: value.min, max: value.max };
          changed = true;
        }
        break;
      default:
        if (this.currentValue != value) {
          this.currentValue = value;
          changed = true;
        }
        break;
    }

    if (value !== '' && value !== undefined) {
      this.isSelectedAnyOption = true;
      this.isNotSelectedAnyOption = false;
    } else {
      this.isSelectedAnyOption = false;
      this.isNotSelectedAnyOption = true;
    }
    let optionSelected = undefined;
    if (this.options && this.options.length) {
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].selected = value == this.options[i].value;
        if (this.options[i].selected) {
          optionSelected = this.options[i];
        }
      }
    }
    if (changed) {
      this.changeValueEvent.emit({ filter: this, value: this.currentValue, optionSelected: optionSelected });
    }
  }

  getQuerysetParams() {
    const params = {};
    switch (this.type) {
      case FilterType.numberRange:
      case FilterType.dateRange:
        if (this.currentValue.min) {
          params[this.paramName + '_min' as keyof Object] = this.currentValue.min;
        }
        if (this.currentValue.max) {
          params[this.paramName + '_max' as keyof Object] = this.currentValue.max;
        }
        break;
      case FilterType.search:
        if (this.currentValue) {
          params[this.paramName as keyof Object] = this.currentValue;
        }
        this.moreFiltersParams.forEach(
          moreFilterParam => {
            Object.assign(params, moreFilterParam);
          }
        );
        break;
      default:
        if (this.currentValue) {
          params[this.paramName as keyof Object] = this.currentValue;
        }
        break;
    }
    return params;
  }

  updateQueryset(
    queryset: Queryset<any>
  ) {
    const params = this.getQuerysetParams();
    return queryset.filter(params);
  }
}
