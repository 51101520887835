// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
}

ion-card {
  display: block;
  width: 292px;
  height: 315px;
  position: relative;
}
ion-card merz-date-card-box {
  position: absolute;
  top: 9.5px;
  right: 12.5px;
}
ion-card merz-can-see-plan-tag {
  position: absolute;
  left: 12.5px;
  top: 9.5px;
}
ion-card img {
  min-height: 169px;
  height: 169px;
  width: 100%;
}
ion-card ion-card-content {
  padding: 13.5px 16px 18px;
}
ion-card ion-card-content .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--ion-color-dark);
  line-height: 26px;
  text-align: left;
  display: block;
  display: -webkit-box;
  height: 55px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card ion-card-content .time merz-text {
  text-align: left;
  margin-bottom: 6px;
}
ion-card ion-card-content merz-avatar-name-section {
  margin-top: 7px;
}
ion-card ion-card-content .location {
  margin-top: 7px;
  display: flex;
  align-items: center;
}
ion-card ion-card-content .location ion-icon {
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/formation-card/formation-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,uBAAA;EAAA,kBAAA;AACF;;AAGA;EACE,cAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AAAF;AAEE;EACE,kBAAA;EACA,UAAA;EACA,aAAA;AAAJ;AAGE;EACE,kBAAA;EACA,YAAA;EACA,UAAA;AADJ;AAIE;EACE,iBAAA;EACA,aAAA;EACA,WAAA;AAFJ;AAKE;EACE,yBAAA;AAHJ;AAKI;EACE,eAAA;EACA,gBAAA;EACA,4BAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;EACA,YAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;AAHN;AAOM;EACE,gBAAA;EACA,kBAAA;AALR;AASI;EACE,eAAA;AAPN;AAUI;EACE,eAAA;EACA,aAAA;EACA,mBAAA;AARN;AAUM;EACE,iBAAA;AARR","sourcesContent":[":host {\n  display: block;\n  width: fit-content;\n}\n\n\nion-card {\n  display: block;\n  width: 292px;\n  height: 315px;\n  position: relative;\n\n  merz-date-card-box {\n    position: absolute;\n    top: 9.5px;\n    right: 12.5px;\n  }\n\n  merz-can-see-plan-tag {\n    position: absolute;\n    left: 12.5px;\n    top: 9.5px;\n  }\n\n  img {\n    min-height: 169px;\n    height: 169px;\n    width: 100%;\n  }\n\n  ion-card-content {\n    padding: 13.5px 16px 18px;\n\n    .title {\n      font-size: 18px;\n      font-weight: 500;\n      color: var(--ion-color-dark);\n      line-height: 26px;\n      text-align: left;\n      display: block;\n      display: -webkit-box;\n      height: 55px;\n      -webkit-line-clamp: 2;\n      -webkit-box-orient: vertical;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n\n    .time {\n      merz-text {\n        text-align: left;\n        margin-bottom: 6px;\n      }\n    }\n\n    merz-avatar-name-section {\n      margin-top: 7px;\n    }\n\n    .location {\n      margin-top: 7px;\n      display: flex;\n      align-items: center;\n\n      ion-icon {\n        margin-right: 8px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
