import { Component, Input } from '@angular/core';
import { TextComponent } from '../text/text.component';

@Component({
  selector: 'dnest-core-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent extends TextComponent {

  @Input('type') override type:string = "h1";

}
