import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Formation } from 'src/app/models/formation';
import { FormationApiClientService } from 'src/app/services/api/formation-api-client/formation-api-client.service';

@Component({
  selector: 'merz-unfollow-course-popover',
  templateUrl: './unfollow-course-popover.component.html',
  styleUrls: ['./unfollow-course-popover.component.scss'],
})
export class UnfollowCoursePopoverComponent implements OnInit {
  @Input() formationId = '';
  constructor(protected popoverController: PopoverController, protected formationApiClientService: FormationApiClientService) { }

  ngOnInit(): void {
  }

  dismiss() {
    this.popoverController.dismiss();
  }

  delete() {
    const formation = new Formation();
    formation.id = this.formationId;
    this.formationApiClientService.deleteAction(formation, 'notification').subscribe((data) => {
      if (data['success']) {
        this.dismiss();
        this.formationApiClientService.profileCoursesEvent.next('');
      }
    });
  }
}
