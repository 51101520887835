// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-card {
  display: block;
}
ion-card ion-card-content {
  padding: 0;
}
ion-card ion-card-content ion-item {
  --padding-start: 0px;
  --inner-border-width: 0;
  --inner-padding-top: 7px;
  --inner-padding-bottom: 7px;
  --inner-padding-start: 7px;
  --inner-padding-end: 21px;
  --background-activated: none;
  --background: var(--ion-color-white);
  --detail-icon-color: var(--ion-color-light);
  --detail-icon-opacity: 1;
  --background-activated-opacity: 1;
  --detail-icon-font-size: 15px;
}
ion-card ion-card-content ion-item ion-thumbnail {
  --size: 67px;
  min-width: 67px;
  min-height: 67px;
  padding: 7px;
  --border-radius: 5px;
  background: var(--ion-color-background);
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/file-card/file-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAGA;EACE,cAAA;AAAF;AAEE;EACE,UAAA;AAAJ;AAGI;EACE,oBAAA;EACA,uBAAA;EACA,wBAAA;EACA,2BAAA;EACA,0BAAA;EACA,yBAAA;EACA,4BAAA;EACA,oCAAA;EACA,2CAAA;EACA,wBAAA;EACA,iCAAA;EACA,6BAAA;AADN;AAGM;EACE,YAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,oBAAA;EACA,uCAAA;EACA,kBAAA;AADR","sourcesContent":[":host {\n  display: block;\n}\n\n\nion-card {\n  display: block;\n\n  ion-card-content {\n    padding: 0;\n\n\n    ion-item {\n      --padding-start: 0px;\n      --inner-border-width: 0;\n      --inner-padding-top: 7px;\n      --inner-padding-bottom: 7px;\n      --inner-padding-start: 7px;\n      --inner-padding-end: 21px;\n      --background-activated: none;\n      --background: var(--ion-color-white);\n      --detail-icon-color: var(--ion-color-light);\n      --detail-icon-opacity: 1;\n      --background-activated-opacity: 1;\n      --detail-icon-font-size: 15px;\n\n      ion-thumbnail {\n        --size: 67px;\n        min-width: 67px;\n        min-height: 67px;\n        padding: 7px;\n        --border-radius: 5px;\n        background: var(--ion-color-background);\n        margin-right: 15px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
