import { Component, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { VeevaId } from 'src/app/models/veeva-id';
import { VeevaIdApiClientService } from 'src/app/services/api/veeva-id-api-client/veeva-id-api-client.service';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-veeva-id',
  templateUrl: './input-veeva-id.component.html',
  styleUrls: ['./input-veeva-id.component.scss']
})
export class InputVeevaIdComponent extends InputComponent {

  veevaIds: VeevaId[] = [];

  constructor(
    protected veevaIdApiClientService: VeevaIdApiClientService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.formControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.veevaIdApiClientService
      .filter({ search: value })
      .subscribe((result) => {
        this.veevaIds = result.results;
      });
    });
  }

  selectVeevaId(veevaId: VeevaId){
    this.formControl.setValue(veevaId.veevaId);
  }


  get empty() {
    return this.veevaIds.length === 0;
  }
}
