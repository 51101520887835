import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, formatCurrency } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CoreUtilsService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  openExternalLink(link: string, target: string = '_blank', features: string = 'noopener noreferrer') {
    if (isPlatformBrowser(this.platformId)) {
      window.open(link, target, features);
      const windowWebkit:any = window;
      // To use in ionic environments
      if (windowWebkit.webkit && windowWebkit.webkit.messageHandlers && windowWebkit.webkit.messageHandlers.cordova_iab) {
        const messageObject = {link: link, target: target, features: features};
        windowWebkit.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(messageObject));
      }
    } 
  }

  copyToClipboard(value: string) {
    if (isPlatformBrowser(this.platformId)) {
      const tempInput = document.createElement('input');
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
  }

  fixedValue(value: string) {
    return formatCurrency(Number(value), 'es', '€', 'EUR', '0.0-2');
  }

}
