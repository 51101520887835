import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressFormatterService {

  addressCompletedEvent = new Subject<any>();
  hasAddress: boolean = false;
  hasStreetNumber: boolean = false;

  constructor() { }

  splitAddress(addressComponents: any, name: any, fullAddress: any) {
    let route = 'route';
    let streetNumber = 'street_number';
    let city = 'locality';
    let province = 'administrative_area_level_2';
    let country = 'country';
    let postalCode = 'postal_code';

    let result = {
      city: '',
      province: '',
      country: '',
      postalCode: '',
      streetNumber: '',
      route: '',
      address: name,
      fullAddress: fullAddress
    };

    addressComponents
      .forEach((a: { types: any; long_name: any; }) => {
        if (a.types.includes(route)) result.route = a.long_name;
        if (a.types.includes(streetNumber)) result.streetNumber = a.long_name;
        if (a.types.includes(city)) result.city = a.long_name;
        if (a.types.includes(province)) result.province = a.long_name;
        if (a.types.includes(country)) result.country = a.long_name;
        if (a.types.includes(postalCode)) result.postalCode = a.long_name;
      });

    if (result.route && result.streetNumber) {
      result.address = result.route + ' ' + result.streetNumber;
    }

    this.hasAddress = !!result.address ? true : false;
    this.hasStreetNumber = !!result.streetNumber ? true : false;

    this.addressCompletedEvent.next(result);
  }
}
