import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TabsComponent } from './tabs.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/button.module';
import { TextModule } from '../text/text.module';
import { HeaderKamsComponentModule } from '../header-kams/header-kams.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    ButtonModule,
    TextModule,
    HeaderKamsComponentModule,
    TranslateModule,
    NgOptimizedImage
  ],
  declarations: [TabsComponent],
  exports: [TabsComponent],
})
export class TabsComponentModule {}
