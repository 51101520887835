// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noti-button {
  position: relative;
}
.noti-button .noti-count {
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 8px;
  padding: 1px 6px;
  background: var(--ion-color-pink);
}

ion-img.smaller {
  width: 34px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/button/notification-button/notification-button.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;EACA,iCAAA;AAEJ;;AAGE;EACE,WAAA;AAAJ","sourcesContent":[".noti-button {\n  position: relative;\n  .noti-count {\n    position: absolute;\n    top: 3px;\n    right: 3px;\n    border-radius: 8px;\n    padding: 1px 6px;\n    background: var(--ion-color-pink);\n  }\n}\n\nion-img {\n  &.smaller {\n    width: 34px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
