import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'merz-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent implements OnInit {

  @Input('form-control') formControl = new UntypedFormControl();
  @Input() top = false;
  @Input() length;
  @Input() minlength;

  constructor() { }

  ngOnInit() {}

  get isRequired() {
    if (!this.formControl.validator) {
      return false;
    }
    const validator = this.formControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }
  get formControlInvalid() { return this.formControl.invalid && this.formControl.touched; }
  get hasRemoteError() { return (this.formControl.errors as any).remote; }
  get hasRequiredError() { return (this.formControl.errors as any).required; }
  get hasRequiredTrueError() { return (this.formControl.errors as any).requiredTrue; }
  get hasEmailError() { return (this.formControl.errors as any).email; }
  get hasMinLengthError() { return (this.formControl.errors as any).minlength; }
  get hasLengthError() { return (this.formControl.errors as any).maxlength; }
  get hasMinError() { return (this.formControl.errors as any).min; }
  get hasMaxError() { return (this.formControl.errors as any).max; }
  get errorMessage() { return (this.formControl.errors as any).value; }
  get errorMin() { return (this.formControl.errors as any).min.min; }
  get errorMax() { return (this.formControl.errors as any).max.max; }
  get hasError() {
    if (!!this.formControl.errors) {
      return this.hasRemoteError || this.hasRequiredError || this.hasRequiredTrueError ||
        this.hasEmailError || this.hasLengthError || this.hasMinLengthError || this.hasMinError || this.hasMaxError;
    }
    return false;
  }
}
