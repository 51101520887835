import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { FormComponent } from '../../form/form.component';
import { HeaderBackFormService } from '../../../services/header-back-form/header-back-form.service';

@Component({
  selector: 'merz-header-back',
  templateUrl: './header-back.component.html',
  styleUrls: ['./header-back.component.scss']
})
export class HeaderBackComponent implements OnInit {
  @Input() href;
	@Input('check-form') checkForm = false;
	@Input('arrow-white') arrowWhite = false;
	@Input('can-go-next') canGoNext = false;

	@Input() background = 'transparent';
	@Input() text = '';
	@Input('text-color') textColor = '';
  @Output('go-back') goBackEvent = new EventEmitter<any>();
	form: FormComponent;

	constructor(protected navController: NavController,
    private headerBackFormService: HeaderBackFormService
    ) {
		headerBackFormService.headerBackComponent = this;
	}
	ngOnInit() {}

  goNext(){
    this.headerBackFormService.goNext(this.form);
  }

	goBack() {
		if (!this.checkForm || this.headerBackFormService.canGoBack(this.form)) {
      this.goBackEvent.emit();
      if(this.href) {
        return this.navController.navigateBack(this.href);
      }
		}
	}
}
