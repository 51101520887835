import { Component, Input, OnInit } from '@angular/core';
import { Gallery } from 'src/app/models/gallery';
import { ProductCategory } from 'src/app/models/product-category';

@Component({
  selector: 'merz-gallery-by-producto-card',
  templateUrl: './gallery-by-producto-card.component.html',
  styleUrls: ['./gallery-by-producto-card.component.scss']
})
export class GalleryByProductoCardComponent implements OnInit {
  @Input('product-category') productCategory: ProductCategory;

  constructor() { }

  ngOnInit(): void {
  }

}
