import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dnest-core-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss']
})
export class ButtonLinkComponent implements OnInit {
  @Input('text') text:string = "";
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";
  @Input('text-align') align: string = "left";

  @Input('uppercase') uppercase: boolean = false;
  @Input('is-loading') isLoading: boolean = false;
  @Input('ellipsis') ellipsis: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
