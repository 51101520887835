import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Filter } from '../../models/filter/filter';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'dnest-core-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {
  @Input('placeholder') placeholder: string = "";
  @Input('theme') theme: string = "primary";
  @Input('list') list: string = "options";
  @Input('service-param-search') param: string = "search";
  @Input('service-param-value') paramValue: string = "name";
  @Input('add-user-option') addUserOption: boolean = false;

  _required: boolean = false;
  @Input("required") set required(value: boolean) {
    this._required = value;
    if (value) this.inputControllerForInput.setValidators([Validators.required]);
  }

  _inputController: UntypedFormControl = new UntypedFormControl('');
  @Input('input-controller') set inputController(input){
    this._inputController = input;
    this.inputControllerForInput.setValue(input.value[this.paramValue])
  }
  get inputController(){
    return this._inputController;
  }

  _service: any;
  @Input('service') set service(value){
    this._service = value;
    this.getOptions();
  }
  get service(){
    return this._service;
  }

  _filter: any;
  @Input('filter')
  set filter(filter: Filter) {
    this._filter = filter;
    this.options = filter.options;
    filter.downloadServiceList.subscribe(
      filterEvent => {
        this.options = filterEvent.filter.options;
      }
    );
  }
  get filter() {
    return this._filter;
  }

  _options: any[] = [];
  @Input('options')
  set options (options: any[]) {
    this._options = options;
    if (!options) {
      return;
    }
    for (let index = 0; index < options.length; index++) {
      const option = options[index];
      if (option['selected']) {
        this.inputController.setValue(option);
        break;
      }
    }
  }
  get options() {
    return this._options;
  }

  inputControllerForInput: UntypedFormControl = new UntypedFormControl('');

  get valueText() { return `Añadir: "${this.inputControllerForInput.value}"`; }

  constructor() { }

  emitChange(value: any){
    this.selectOption(value);
    this.filterEmit(value?.value);
    this.inputControllerForInput.setValue("");
  }

  ngOnInit(): void {

    this.inputControllerForInput.valueChanges.subscribe((value) => {
      if (value.includes("Añadir:")) {
        const newValue = value.slice(9, -1);
        this.inputControllerForInput.setValue(newValue);
        this.onEnter("");
      }
    });

    this.inputControllerForInput.valueChanges.pipe(debounceTime(300)).subscribe(
      value => {
        if (!this.findOption(value) && this.service) {
          const params = {};
          (params as any)[this.param] = value;
          this.service.filter(params).subscribe(
            ( data: { results: any[]; }) => {
              this.options = data.results;
            }
          );
        }
      }
    );
  }

  getOptions(){
    this.service.filter().subscribe(
      ( data: { results: any[]; }) => {
        this.options = data.results;
      }
    );
  }


  filterEmit(value: any) {
    if(this.filter) {
      this.filter.changeValue(value);
    }
  }

  getParamValue(item: any) {
    return item[this.paramValue];
  }

  compareValues(value: string, option: any): boolean {
    return value && option && value === option[this.paramValue];
  }

  findOption(value: any) {
    for (let i = 0; i < this.options.length; i++) {
      if (this.compareValues(value, this.options[i])) {
        this.inputController.setValue(this.options[i]);
        this.emitChange(this.options[i]);
        return true;
      }
    }
    return false;
  }

  @Output('selected-option') selectedOptionEvent = new EventEmitter<any>();
  selectOption(value: any) {
    this.selectedOptionEvent.emit(value);
  }

  @Output("on-enter-option") onEnterOptionEvent = new EventEmitter<any>();
  onEnter(event: any) {
    this.onEnterOptionEvent.emit(this.inputControllerForInput.value);
    this.inputControllerForInput.setValue("");
  }

  get hasRequiredError() { return (this.inputController.errors as any).required }
}
