import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { AttachFileButtonComponent } from './attach-file-button/attach-file-button.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ButtonComponent } from './button/button.component';
import { ButtonLinkComponent } from './button-link/button-link.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InputComponent } from './input/input.component';
import { LoaderCirclesComponent } from './loader-circles/loader-circles.component';
import { LoadingLogoComponent } from './loading-logo/loading-logo.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { RadioComponent } from './radio/radio.component';
import { SanatizedHtmlContentWithoutEncapsulationComponent } from './sanatized-html-content-without-encapsulation/sanatized-html-content-without-encapsulation.component';
import { SubmenuComponent } from './submenu/submenu.component';
import { TableComponent } from './table/table.component';
import { TextComponent } from './text/text.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TitleComponent } from './title/title.component';
import { InputAddressAutocompleteComponent } from './input-address-autocomplete/input-address-autocomplete.component';
import { SelectComponent } from './select/select.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarModule } from 'angular-calendar';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { FiltersComponent } from './filters/filters.component';
import { SidenavBarComponent } from './sidenav-bar/sidenav-bar.component';
import { SidenavBarItemComponent } from './sidenav-bar-item/sidenav-bar-item.component';


@NgModule({
  declarations: [
    AttachFileButtonComponent,
    AutocompleteComponent,
    ButtonComponent,
    ButtonLinkComponent,
    CheckboxComponent,
    InputComponent,
    LoaderCirclesComponent,
    LoadingLogoComponent,
    PaginationComponent,
    PasswordInputComponent,
    RadioComponent,
    SanatizedHtmlContentWithoutEncapsulationComponent,
    SubmenuComponent,
    TableComponent,
    TextComponent,
    TextareaComponent,
    TitleComponent,
    InputAddressAutocompleteComponent,
    SelectComponent,
    CalendarComponent,
    PdfViewerComponent,
    TooltipComponent,
    FiltersComponent,
    SidenavBarComponent,
    SidenavBarItemComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ClipboardModule,
    CalendarModule,
  ],
  exports: [
    AttachFileButtonComponent,
    AutocompleteComponent,
    ButtonComponent,
    ButtonLinkComponent,
    CheckboxComponent,
    InputComponent,
    LoaderCirclesComponent,
    LoadingLogoComponent,
    PaginationComponent,
    PasswordInputComponent,
    RadioComponent,
    SanatizedHtmlContentWithoutEncapsulationComponent,
    SubmenuComponent,
    TableComponent,
    TextComponent,
    TextareaComponent,
    TitleComponent,
    InputAddressAutocompleteComponent,
    SelectComponent,
    CalendarComponent,
    PdfViewerComponent,
    TooltipComponent,
    FiltersComponent,
    SidenavBarComponent,
    SidenavBarItemComponent,
  ]
})
export class ComponentsModule { }
