import { Injectable } from '@angular/core';
import { UserWorkCenter } from 'src/app/models/user-work-center';
import { UserWorkCenterApiClientService } from '../api/user-work-center-api-client/user-work-center-api-client.service';
import { BaseListResolver } from './base-list.resolver';

@Injectable({
  providedIn: 'root'
})
export class UserWorkCentersResolver extends BaseListResolver<UserWorkCenter> {
  constructor(
    protected service: UserWorkCenterApiClientService,
  ) {
    super();
  }
}
