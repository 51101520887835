import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanSeePlanTagComponent } from './can-see-plan-tag.component';
import { IonicModule } from '@ionic/angular';
import { TextModule } from '../text/text.module';



@NgModule({
  declarations: [
    CanSeePlanTagComponent
  ],
  imports: [
    CommonModule,
    TextModule,
    IonicModule
  ],
  exports: [
    CanSeePlanTagComponent
  ]
})
export class CanSeePlanTagModule { }
