import { Component, Input, OnInit } from '@angular/core';
import { FreeContent } from 'src/app/models/free-content';

@Component({
  selector: 'merz-free-content-card',
  templateUrl: './free-content-card.component.html',
  styleUrls: ['./free-content-card.component.scss']
})
export class FreeContentCardComponent implements OnInit {
  @Input('free-content') freeContent: FreeContent;

  constructor() { }

  ngOnInit(): void {
  }

  get seePremiumTag(){
    return this.freeContent?.canSee && (this.freeContent?.accessLevel==='premium' || this.freeContent?.accessLevel==='student');
  }
}
