import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dnest-core-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input('theme') theme: string = "primary";
  
  constructor() { }

  ngOnInit(): void {
  }

}
