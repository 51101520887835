import { Injectable } from '@angular/core';
import { BaseDetailResolver } from './base-detail.resolver';
import { Formation } from 'src/app/models/formation';
import { FormationApiClientService } from '../api/formation-api-client/formation-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class FormationResolver extends BaseDetailResolver<Formation> {
  protected idParam = 'formationId';

  constructor(protected service: FormationApiClientService) {
    super();
  }
}
