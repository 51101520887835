// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.block {
  display: flex;
  background: var(--ion-color-dark);
  border-radius: 5px;
  padding: 3px 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/sections/time-small-block/time-small-block.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,iCAAA;EACA,kBAAA;EACA,gBAAA;AACF","sourcesContent":[":host {\n  display: block;\n}\n\n.block {\n  display: flex;\n  background: var(--ion-color-dark);\n  border-radius: 5px;\n  padding: 3px 7px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
