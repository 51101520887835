import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  closedEvent = new Subject();
  openedModal: any;

  private modals: any[] = [];

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string) {
    const modal = this.modals.find(x => x.id === id);
    this.openedModal = id;
    modal.open();
  }

  close(id: string) {
    const modal = this.modals.find(x => x.id === id);
    this.openedModal = undefined;
    modal.close();
  }

  isOpened(id: string) {
    return this.openedModal = id;
  }
  
}
