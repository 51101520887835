import { Component, Input, OnInit } from '@angular/core';
import { Option } from 'src/app/models/option';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
})
export class InputSelectComponent extends InputComponent {
	@Input() options: Option[];

  constructor(
  ) {
    super();
  }

  ngOnInit() {
		super.ngOnInit();
	}

	selectOption(opt: Option) {
		this.formControl.setValue(opt.value);
		this.enter.next(true);
	}
}
