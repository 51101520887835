import { Component, Input, OnInit } from '@angular/core';
import { File } from 'src/app/models/file';

@Component({
  selector: 'merz-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss'],
})
export class FileCardComponent implements OnInit {
  @Input() file: File;
  fileImages = {
    txt: '/assets/svg/poduct-text-icon.svg',
    doc: '/assets/svg/product-doc-icon.svg',
    jpg: '/assets/svg/product-jpg-icon.svg',
    pdf: '/assets/svg/product-pdf-icon.svg',
    xls: '/assets/svg/product-xls-icon.svg',
    png: '/assets/svg/product-png-icon.svg',
  };
  constructor() {}

  ngOnInit() {}
}
