import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { IonicModule } from '@ionic/angular';
import { TextModule } from '../text/text.module';
import { ButtonModule } from '../button/button.module';
import { FilterGroupModule } from '../filter-group/filter-group.module';
import { PollModalComponent } from './poll-modal/poll-modal.component';
import { FilterGalleryModalComponent } from './filter-gallery-modal/filter-gallery-modal.component';
import { FilterEventModalComponent } from './filter-event-modal/filter-event-modal.component';
import { PlansModalComponent } from './plans-modal/plans-modal.component';
import { CardModule } from '../card/card.module';
import { FilterUsersComponent } from './filter-users/filter-users.component';
import { SpeakersModalComponent } from './speakers-modal/speakers-modal.component';
import { SpeakerFollowBlockModule } from 'src/app/sections/speaker-follow-block/speaker-follow-block.module';
import { DisclaimerModalComponent } from './disclaimer-modal/disclaimer-modal.component';
import { CompleteProfileModalComponent } from './complete-profile-modal/complete-profile-modal.component';
import { UpdateAppModalComponent } from './update-app-modal/update-app-modal.component';
import { GalleryDetailModalComponent } from './gallery-detail-modal/gallery-detail-modal.component';
import { NotFoundModule } from 'src/app/sections/not-found/not-found.module';
import { TranslateModule } from '@ngx-translate/core';
import { DatetimeModalComponent } from './datetime-modal/datetime-modal.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { EnrollEventModalComponent } from './enroll-event-modal/enroll-event-modal.component';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [FilterModalComponent, PollModalComponent, FilterGalleryModalComponent, FilterEventModalComponent, PlansModalComponent, FilterUsersComponent, SpeakersModalComponent, DisclaimerModalComponent, CompleteProfileModalComponent, UpdateAppModalComponent, GalleryDetailModalComponent, DatetimeModalComponent, EnrollEventModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    TextModule,
    ButtonModule,
    FilterGroupModule,
    CardModule,
    SpeakerFollowBlockModule,
    NotFoundModule,
    TranslateModule,
    NgOptimizedImage
  ],
  exports: [FilterModalComponent, PollModalComponent, FilterGalleryModalComponent, FilterEventModalComponent, PlansModalComponent, FilterUsersComponent, SpeakersModalComponent, DisclaimerModalComponent, CompleteProfileModalComponent, UpdateAppModalComponent, GalleryDetailModalComponent, DatetimeModalComponent
  ]
})
export class ModalModule { }
