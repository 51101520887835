import { Injectable } from '@angular/core';
import { Notification } from 'src/app/models/notification';
import { NotificationApiClientService } from '../api/notification-api-client/notification-api-client.service';
import { BaseListResolver } from './base-list.resolver';

@Injectable({
  providedIn: 'root'
})
export class NotificationsResolver extends BaseListResolver<Notification> {
  constructor(
    protected service: NotificationApiClientService,
  ) {
    super();
    this.pageSize = 50;
  }
}
