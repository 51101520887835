import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'merz-can-see-plan-tag',
  templateUrl: './can-see-plan-tag.component.html',
  styleUrls: ['./can-see-plan-tag.component.scss']
})
export class CanSeePlanTagComponent implements OnInit {
  @Input() name = '';
  @Input() small = false;
  @Input() premium = false;

  constructor() { }

  ngOnInit(): void {
  }

}
