import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { NotificationApiClientService } from 'src/app/services/api/notification-api-client/notification-api-client.service';

@Component({
  selector: 'merz-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss']
})
export class NotificationButtonComponent implements OnInit {
  @Input() desktop = false;
  notiCount = 0;

  constructor(
    protected navController: NavController,
    protected notificationApiClientService: NotificationApiClientService
  ) { }

  ngOnInit() {
    this.notificationApiClientService.notificationsReadEvent.subscribe(() => {
      this.getNotifications();
    });
    this.getNotifications();
  }

  getNotifications(){
    this.notificationApiClientService.filter({read: false}).all().subscribe((result) => {
      this.notiCount = result['count'];
    });
  }

  get isNoti(){
    return this.notiCount !== 0;
  }

  get notiText(){
    return this.notiCount > 9 ? '+9': this.notiCount;
  }

  goToNotifications(){
    this.navController.navigateForward([MERZ_ROUTES.notificationsComplete]);
  }

}
