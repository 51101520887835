import { Component, Input, OnInit } from '@angular/core';
import { Phase } from 'src/app/models/phase';

@Component({
  selector: 'merz-phase-card',
  templateUrl: './phase-card.component.html',
  styleUrls: ['./phase-card.component.scss'],
})
export class PhaseCardComponent implements OnInit {
  @Input() phase: Phase;
  @Input() detail = false;

  constructor() { }

  ngOnInit() {}

}
