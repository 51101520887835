// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  background: var(--ion-color-white);
  z-index: 5;
  overflow: auto;
  padding: 0 24px;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.modal-container .top {
  padding: 25px 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/datetime-modal/datetime-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,UAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,4BAAA;EACA,6BAAA;AACF;AACE;EACE,oBAAA;AACJ","sourcesContent":[".modal-container {\n  background: var(--ion-color-white);\n  z-index: 5;\n  overflow: auto;\n  padding: 0 24px;\n  position: absolute;\n  width: 100%;\n  bottom: 0;\n  border-top-left-radius: 15px;\n  border-top-right-radius: 15px;\n\n  .top {\n    padding: 25px 0 20px;\n  }\n\n  // .date-time {\n  //   display: flex;\n  //   align-items: center;\n  //   padding: 13px 0 50px;\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
