import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { Gallery } from 'src/app/models/gallery';
import { GalleryApiClientService } from 'src/app/services/api/gallery-api-client/gallery-api-client.service';
import { LogApiClientService } from 'src/app/services/api/log-api-client/log-api-client.service';
import { Share } from '@capacitor/share';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GalleryDetailService } from 'src/app/services/gallery-detail-service/gallery-detail.service';
import { NotFoundService } from 'src/app/services/not-found/not-found.service';
import { TranslateService } from '@ngx-translate/core';
import Swiper from 'swiper';


@Component({
  selector: 'merz-gallery-detail-modal',
  templateUrl: './gallery-detail-modal.component.html',
  styleUrls: ['./gallery-detail-modal.component.scss'],
  providers: [File, FileOpener, SocialSharing],
})
export class GalleryDetailModalComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer: any;
  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  swiper?: Swiper;


  constructor(
    // private activatedRoute: ActivatedRoute,
    private sanitazer: DomSanitizer,
    // protected userWorkCenterApiClientService: UserWorkCenterApiClientService,
    private file: File,
    private fileOpener: FileOpener,
    protected loadingController: LoadingController,
    private socialSharing: SocialSharing,
    protected platform: Platform,
    protected navController: NavController,
    protected logApiClientService: LogApiClientService,
    protected modalController: ModalController,
    protected galleryDetailService: GalleryDetailService,
    protected galleryApiClientService: GalleryApiClientService,
    protected notFoundService: NotFoundService,
    protected translateService: TranslateService
  ) { }

  checkVideo = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.swiperReady();
    }, 500);

    setInterval(() => {
      if (this.galleryDetail?.type === 'video' && !this.checkVideo) {
        this.checkVideo = true;
        this.createPlayer();
      }
    }, 500);
  }


  downloadedFile;

  slideOpts = {
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: false,
  };

  get showNotFound() {
    return this.notFoundService.showNotFound;
  }

  get galleryDetail() {
    return this.galleryDetailService.galleryDetail;
  }

  get buttonText() {
    return this.isNormal
      ? this.translateService.instant('BUTTONS.DOWNLOAD_IMAGE')
      : this.isCarrusel
        ? this.translateService.instant('BUTTONS.DOWNLOAD_IMAGES')
        : this.translateService.instant('BUTTONS.DOWNLOAD_VIDEO');
  }

  get isNormal() {
    return this.galleryDetail?.type === 'normal';
  }

  get isCarrusel() {
    return this.galleryDetail?.type === 'carrusel';
  }

  get isVideo() {
    return this.galleryDetail?.type === 'video';
  }

  get url(): SafeResourceUrl {
    return this.sanitazer.bypassSecurityTrustResourceUrl(
      this.galleryDetail?.videoUrl
    );
  }

  swiperReady() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  createPlayer() {
    const iframeList = document.querySelectorAll('.vimeo');
    const iframe = iframeList[iframeList.length - 1];
    iframe.setAttribute('src', '');
    iframe.setAttribute('src', this.galleryDetail?.videoUrl);
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translateService.instant('GALLERY.DOWNLOADING_FILE'),
    });
    await loading.present();
  }

  download(event) {
    this.presentLoading();
    const gallery = new Gallery();
    gallery.id = this.galleryDetail?.id;
    this.galleryApiClientService
      .all()
      .actionGet(gallery, 'download', 'arraybuffer')
      .subscribe(async (csv: any) => {
        const contentType = csv.headers.get('content-type');
        const options = { type: 'application/octet-stream' };
        this.downloadedFile = new Blob([csv.body], options);
        const filename = `merz-download.${contentType.split('/')[1]}`;

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(this.downloadedFile);
        link.download = filename;
        link.click();

        this.loadingController.dismiss();
      });
  }

  async shareFile(path) {
    await this.socialSharing.share('Merz', 'Descarga', path);
  }

  async share() {
    let url = '';
    if (this.isNormal) {
      url = this.galleryDetail.image;
    } else if (this.isVideo) {
      url = this.galleryDetail.videoUrl;
    }
    this.logApiClientService
      .create({
        event: 'share-gallery',
        elementType: 'gallery',
        elementId: this.galleryDetail?.id,
      })
      .subscribe();

    await Share.share({
      url,
    });
  }

  slidePrev(e: Event) {
    e.stopPropagation();
    if (this.swiper.isBeginning) {
      this.swiper.slideTo(this.galleryDetail?.images.length - 1);
    } else {
      this.swiper.slidePrev();
    }
  }

  slideNext(e: Event) {
    e.stopPropagation();
    if (this.swiper.isEnd) {
      this.swiper.slideTo(0);
    } else {
      this.swiper.slideNext();
    }
  }

  //close modal
  dismiss() {
    this.modalController.dismiss();
  }

  stopPropagation(e: Event) {
    e.stopPropagation();
  }
}
