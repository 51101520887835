import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Plan } from 'src/app/models/plan';
import { User } from 'src/app/models/user';
import { KamUserApiClientService } from 'src/app/services/api/kam-user-api-client/kam-user-api-client.service';
import { LogApiClientService } from 'src/app/services/api/log-api-client/log-api-client.service';

@Component({
  selector: 'merz-profile-plan-card',
  templateUrl: './profile-plan-card.component.html',
  styleUrls: ['./profile-plan-card.component.scss']
})
export class ProfilePlanCardComponent implements OnInit {
  @Input('current-plan-id') currentPlanId = '';
  @Input('kam-phone') kamPhone = '';
  @Input('from-kam') fromKam = false;
  @Input('kam-user-id') kamUserId = '';
  @Input() plan: Plan;
  @Output('changed-plan') changedPlanEvent = new EventEmitter<any>();

  options = [];
  constructor(
    protected kamUserApiClientService: KamUserApiClientService,
    protected logApiClientService: LogApiClientService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.options =
      [
        {
          name: this.translateService.instant('PLANS.VIDEOS_BASIC'),
          value: this.plan?.basicLevelTrainingVideos
        },
        {
          name: this.translateService.instant('PLANS.FORMATION_ONLINE'),
          value: this.plan?.trainingStreaming
        },
        {
          name: this.translateService.instant('PLANS.FORMATION_ONSITE'),
          value: this.plan?.classroomTraining
        },
        {
          name: this.translateService.instant('PLANS.ACCESS_COURSES_TECHNIQUES'),
          value: this.plan?.accessToCoursesAndTechnicalVideos
        },
        {
          name: this.translateService.instant('PLANS.FORMATION_VIP'),
          value: this.plan?.internationalVipTraining
        },
        {
          name: this.translateService.instant('PLANS.ACCESS_PRODUCTS'),
          value: this.plan?.merzProductsAccess
        },
        {
          name: this.translateService.instant('PLANS.ACCESS_EVENTS'),
          value: this.plan?.eventsAccess
        },
        {
          name: this.translateService.instant('PLANS.GALLERY'),
          value: this.plan?.contentGallery
        },
        {
          name: this.translateService.instant('PLANS.VIDEOS_PREMIUM'),
          value: this.plan?.formationVideos
        },
        {
          name: this.translateService.instant('PLANS.INNOVATION_PROGRAMME'),
          value: this.plan?.innovationProgram
        },
      ];
  }

  get buttonText() {
    return this.fromKam ? this.translateService.instant('BUTTONS.SELECT_PLAN') : this.translateService.instant('BUTTONS.REQUEST_PLAN');
  }

  get title() {
    return this.plan?.name;
  }

  get description() {
    return this.plan?.description;
  }

  get starsAmount() {
    return this.plan?.stars;
  }

  get isCurrentPlan() {
    return this.plan?.id === this.currentPlanId;
  }

  get backgroundColor() {
    switch (this.title) {
      case 'Avanzado':
        return 'green';
      case 'Premium':
        return 'pink';
      case 'Máster':
        return 'brown';
      default:
        return 'blue';
    }
  }

  get starSvg() {
    switch (this.title) {
      case 'Avanzado':
        return '/assets/svg/star-green-icon.svg';
      case 'Premium':
        return '/assets/svg/star-pink-icon.svg';
      case 'Máster':
        return '/assets/svg/star-brown-icon.svg';
      default:
        return '/assets/svg/star-blue-icon.svg';
    }
  }

  selectRequestPlan() {
    this.logApiClientService.create({ event: 'info-plan', elementType: 'plan', elementId: this.plan?.id }).subscribe();

    if (this.fromKam && !this.isCurrentPlan) {
      const user = new User();
      user.id = this.kamUserId;
      user.plan = this.plan.id;

      this.kamUserApiClientService.edit(user).subscribe((result) => {
        this.changedPlanEvent.emit();
      });
    }
    else if (window && !this.isCurrentPlan) {
      window.open(`https://api.whatsapp.com/send?phone=${this.kamPhone}`, '_blank');
    }
  }
}
