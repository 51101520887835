import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'merz-premium-tag',
  templateUrl: './premium-tag.component.html',
  styleUrls: ['./premium-tag.component.scss'],
})
export class PremiumTagComponent implements OnInit {
  @Input() text = '';

  constructor() { }

  ngOnInit() {}

}
