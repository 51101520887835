import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { Subject } from 'rxjs';
import { Event } from 'src/app/models/event';
import { Flight } from 'src/app/models/flights';
import { Inscription } from 'src/app/models/inscription';
import { Document } from 'src/app/models/document';

@Injectable({
  providedIn: 'root'
})
export class EventApiClientService extends ApiClientService<Event> {
  endpoint = 'event';
  profileEventsEvent = new Subject<any>();
  eventFlight: Flight;
  eventInscription: Inscription;
  eventDetail: Event;
  eventDocument: Document;
}

