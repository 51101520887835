// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-toolbar {
  --background: var(--ion-color-white);
}
ion-header ion-toolbar .logo {
  width: 230px;
  margin: 0 auto;
  position: absolute;
  right: 50%;
  transform: translate(50%, -50%);
  top: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header-home/header-home.component.scss"],"names":[],"mappings":"AACE;EACE,oCAAA;AAAJ;AAEI;EACE,YAAA;EACA,cAAA;EACA,kBAAA;EACA,UAAA;EACA,+BAAA;EACA,QAAA;AAAN","sourcesContent":["ion-header {\n  ion-toolbar {\n    --background: var(--ion-color-white);\n\n    .logo {\n      width: 230px;\n      margin: 0 auto;\n      position: absolute;\n      right: 50%;\n      transform: translate(50%, -50%);\n      top: 50%;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
