import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SmallSeePlansComponent } from './small-see-plans.component';
import { TextModule } from '../text/text.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TextModule,
    TranslateModule,
  ],
  declarations: [SmallSeePlansComponent],
  exports: [SmallSeePlansComponent],
})
export class SmallSeePlansComponentModule {}
