import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiClientService } from '../api-client.service';
import { User } from '../../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserApiClientService extends ApiClientService<User> {
  override endpoint = 'rest-auth/user';

  updatedUser: Subject<User> = new Subject<User>();

  _user: any;

  protected override connectServices() {
    this.loginService.isLoggedOutEvent.subscribe(
      () => {
        this.cleanUser();
      }
    );
  }

  get user() {
    if (this._user == undefined) {
      return this.get().subscribe(
        data => {
          this._user = data;
          this.updatedUser.next(data);
        }
      );
    }
    return this._user;
  }

  editUser(payload: any) {
    const observable = this.edit(
      payload,
      true
    );
    observable.subscribe(
      user => {
        this._user.user = user;
      }
    );
    return observable;
  }

  cleanUser() {
    this._user = undefined;
    this.updatedUser.next(new User());
  }

  /* getQuestions(item: User): Question<any>[] {
    return [
      new InputQuestion({
        value: this._user.user.first_name,
        key: 'first_name',
        label: 'Nombre',
        type: 'text',
        required: true,
        placeholder: 'Nombre'
      }),
      new InputQuestion({
        value: this._user.user.last_name,
        key: 'last_name',
        label: 'Apellidos',
        type: 'text',
        required: true,
        placeholder: 'Apellidos'
      })
    ];
  } */
}
