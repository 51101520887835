import { Injectable, Inject } from '@angular/core';
import { CRUDService } from '../crud/crud.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LibraryConfigService, LibraryConfig } from '../../../config';
import { IPasswordReset, IPasswordChange } from '../../models/interfaces';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetApiClientService extends CRUDService<any> {
  static urlClassName = 'rest-auth/password';


  constructor(
    protected override http: HttpClient,
    protected router: Router,
    @Inject(LibraryConfigService) protected override config: LibraryConfig
  ) {
    super(http, PasswordResetApiClientService.urlClassName, config);
  }

  resetPassword(body: { email: string }) {
    return this.postItem(body, 'reset');
  }

  resetPasswordConfirm(body: IPasswordReset) {
    return this.postItem(body, 'reset/confirm');
  }

  changePassword(body: IPasswordChange) {
    return this.postItem(body, 'change');
  }

}
