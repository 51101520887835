import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { TextModule } from '../text/text.module';
import { IonicModule } from '@ionic/angular';
import { ButtonIconComponent } from './button-icon/button-icon.component';
import { ButtonLinkComponent } from './button-link/button-link.component';
import { NotificationButtonComponent } from './notification-button/notification-button.component';
import { SettingsButtonComponent } from './settings-button/settings-button.component';



@NgModule({
  declarations: [ButtonComponent, ButtonIconComponent, ButtonLinkComponent, NotificationButtonComponent, SettingsButtonComponent],
  imports: [
    CommonModule,
    TextModule,
    IonicModule
  ],
  exports: [ButtonComponent, ButtonIconComponent, ButtonLinkComponent, NotificationButtonComponent, SettingsButtonComponent]
})
export class ButtonModule { }
