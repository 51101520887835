import { Injectable } from '@angular/core';
import { BaseDetailResolver } from './base-detail.resolver';
import { News } from 'src/app/models/news';
import { NewsApiClientService } from '../api/news-api-client/news-api-client.service';
import { NotFoundService } from '../not-found/not-found.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class newsResolver extends BaseDetailResolver<News> {
  protected idParam = 'newsId';

  constructor(protected service: NewsApiClientService,
    protected notFoundService: NotFoundService) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.service.get(route.paramMap.get(this.idParam)).pipe(
      map((data) => {
        this.notFoundService.showNotFound = false;
        return data;
      }),
      catchError((error) => {
        if (error.status === 404) {
          this.notFoundService.showNotFound = true;
        }
        return of(undefined);
      })
    );
  }
}
