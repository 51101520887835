import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltersService implements OnInit {
  filterParams: { [key: string]: any } = {};
  routerEvent: Subscription;

  constructor(protected router: Router, protected route: ActivatedRoute) {
    this.routerEvent = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initializeFilters();
      }
    });
  }

  ngOnInit(): void {
    this.initializeFilters();
  }

  ngOnDestroy(): void {
    this.routerEvent.unsubscribe();
  }

  /**
   * Actualizamos los filtros dado lo que esté en el queryParams
   */
  initializeFilters() {
    this.filterParams = this.route.snapshot.queryParams;
  }

  /**
   * Url base sin los queryParams
   */
  get currentUrl() {
    return this.router.url.split('?')[0];
  }

  /**
   * Recibe los filtros de cada filtro y los agrupa para enviarlos a la url como queryParams
   *
   * @param filters {field: value}
   */
  getfilterData(filters: any, url: string = this.currentUrl) {
    this.filterParams = { ...this.filterParams, ...filters };
    this.filterParams = this.cleanFilters(this.filterParams);
    this.router.navigate([url], { queryParams: this.filterParams });
  }

  /**
   * Limpiar y agrupar los filtros para que exista solo una value por cada uno y no haya filtros vacios.
   *
   * @param filterParams
   * @returns
   */
  cleanFilters(filterParams: Record<string, any>) {
    const filters: Record<string, any> = {};

    Object.keys(filterParams).forEach((key) => {
      if (filterParams[key] && filterParams[key] != false) {
        filters[key] = filterParams[key];
      }
    });
    return filters;
  }

  /**
   * Eliminar todos los filtros.
   * Elimina los queryParams.
   * Elimina el texto de headerText
   */
  deleteFilters() {
    this.filterParams = {};
    this.getfilterData({});
  }

  get isFiltersEmpty() {
    return Object.keys(this.filterParams).length === 0;
  }
}
