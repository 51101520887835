import { Component, Input, OnInit } from '@angular/core';
import { Video } from 'src/app/models/video';

@Component({
  selector: 'merz-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
})
export class VideoCardComponent implements OnInit {
  @Input() video: Video;
  @Input('is-small') isSmall = false;

  isHover = false;

  constructor() {}

  ngOnInit(): void {}

  get showSpeakers() {
    return this.video?.speakers?.length > 1;
  }

  get seePremiumTag() {
    return (
      this.video?.canSee &&
      (this.video?.accessLevel === 'premium' ||
        this.video?.accessLevel === 'student')
    );
  }
}
