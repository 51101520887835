import { Base } from './base';
import { Kam } from './kam';
import { Plan } from './plan';
import { Province } from './province';
import { UserWorkCenter } from './user-work-center';
import { Country } from './country';

export class User extends Base {
  accessLevel?: string;
  ageRange?: string;
  birthdate?: string;
  career?: string;
  email?: string;
  firstName?: string;
  howAboutUs?: string;
  kam?: Kam;
  kams?: Kam[];
  lastName?: string;
  masterCourse?: boolean;
  masterEndCourse?: string;
  membershipId?: string;
  ocupation?: string;
  ocupationNoMedical?: string;
  ocupationOther?: string;
  phoneMobile?: string;
  photo?: string;
  sex?: string;
  subscribeNotifications?: boolean;
  username?: string;
  veevaCause?: string;
  oceId?: string;
  institutionOceId?: string;
  zipCode?: string;
  registered?: boolean;
  phoneValidated?: boolean;
  registerCode?: string;
  isKam?: boolean;
  plan?: any;
  workcenter?: UserWorkCenter;
  workcenters?: UserWorkCenter[];
  isMerzStaff?: boolean;
  haveWorkCenter?: boolean;
  lastLogin?: string;

  province?: Province;
  country?: Country;

  isPreferencesSet?: boolean;

  newNotificationFormation?: number;
  newNotificationEvents?: number;
  newNotificationGalery?: number;
}
