import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { Product } from 'src/app/models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductApiClientService extends ApiClientService<Product> {
  endpoint = 'product';
}


