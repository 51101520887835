import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'merz-event-formation-card',
  templateUrl: './event-formation-card.component.html',
  styleUrls: ['./event-formation-card.component.scss'],
})
export class EventFormationCardComponent implements OnInit {
  @Input() event: Event;
  constructor() { }

  ngOnInit(): void {
  }


  get duration(){
    return `${this.event?.start.substr(11,5)} - ${this.event?.end.substr(11,5)}`;
  }
}
