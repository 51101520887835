import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'merz-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  get currentYear() {
    const date = new Date(Date.now());
    return date.getFullYear();
  }

  get privacyLink() {
    return 'https://merzapp.es/politica-de-privacidad';
  }

  get TermsLink() {
    return 'https://merzapp.es/terminos-y-condiciones';
  }

  goToTerms(e: Event) {
    e.stopPropagation();
    if (window) {
      window.open(this.TermsLink, '_blank');
    }
  }

  goToPrivacy(e: Event) {
    e.stopPropagation();
    if (window) {
      window.open(this.privacyLink, '_blank');
    }
  }
}
