import { formatNumber, formatCurrency, formatDate } from '@angular/common';
import { LOCALE_ID, Inject } from '@angular/core';

export enum ColumnType {
    tag = "tag",
    percent = "percent",
    number = "number",
    currency = "currency",
    boolean = "boolean",
    text = "text",
    textWithImage = "textWithImage",
    date = "date",
    link = "link",
    button = "button",
    more = "more",
    component = "component"
}

export class Column {
    static locale = 'en';

    alignLeft: boolean;
    alignRight: boolean;
    bold: boolean;
    secondaryValue: any;
    yesText: string;
    noText: string;
    canSort: boolean;
    callbackButton: any;
    noMobile: boolean;

    constructor(
        public label: string,
        public value: string,
        public type: ColumnType = ColumnType.text,
        public options: {
            alignLeft?: boolean,
            alignRight?: boolean,
            bold?: boolean,
            secondaryValue?: any,
            yesText?: string,
            noText?: string,
            canSort?: boolean,
            callbackButton?: any,
            noMobile?: boolean

        } = {
            alignLeft: false,
            alignRight: false,
            bold: false,
            yesText: 'yes',
            noText: 'no',
            canSort: true,
            secondaryValue: undefined,
            callbackButton: undefined,
            noMobile: false
        }) {
        this.alignLeft = options.alignLeft || false;
        this.alignRight = options.alignRight || false;
        this.bold = options.bold || false;
        this.secondaryValue = options.secondaryValue || undefined;
        this.yesText = options.yesText || 'yes';
        this.noText = options.noText || 'no';
        this.canSort = options.canSort || true;
        this.callbackButton = options.callbackButton || undefined;
        this.noMobile = options.noMobile || false;
    }

    getFormatValue(item: any) {
        const value = this.getValue(item);
        if (value === '') {
            return value;
        }

        switch (this.type) {
            case ColumnType.percent:
                return value + ' %';
            case ColumnType.number:
                return formatNumber(value, Column.locale);
            case ColumnType.currency:
                return formatCurrency(value, Column.locale, this.getSecondaryValue(item));
            case ColumnType.boolean:
                if (value) {
                    return this.yesText;
                }
                return this.noText;
            case ColumnType.date:
                return formatDate(value, 'd/M/yyyy', Column.locale);
            case ColumnType.button:
                return this.value;
            default:
                break;
        }
        return value;
    }

    getPropertyValue(item: any, value: string) {
        if (value.includes('.')) {
            const valueSplit = value.split('.');
            valueSplit.forEach(
                key => {
                    item = item[key];
                }
            )
            return item;
        }
        return item[value];
    }

    getValue(item: any) {
        if (this.value) {
            return this.getPropertyValue(item, this.value);
        }
        return '';
    }

    getSecondaryValue(item: any) {
        if (this.secondaryValue) {
            return this.getPropertyValue(item, this.secondaryValue);
        }
        return '';
    }
}
