import { Component, Input, OnInit } from '@angular/core';
import { UserWorkCenter } from 'src/app/models/user-work-center';

@Component({
  selector: 'merz-work-center-card',
  templateUrl: './work-center-card.component.html',
  styleUrls: ['./work-center-card.component.scss'],
})
export class WorkCenterCardComponent implements OnInit {
  @Input('work-center') workCenter: UserWorkCenter;

  constructor() { }

  ngOnInit(): void {
  }

  get address(){
    return `${this.workCenter?.address}. ${this.workCenter?.zipCode}. ${this.workCenter?.state}. ${this.workCenter?.country}`;
  }
}

