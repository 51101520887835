import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';

@Component({
  selector: 'merz-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	@Input() text = '';
	@Input() background = '';
	@Input('first-icon') firstIcon = '';
	@Input('second-icon') secondIcon = '';
	@Input('first-link') firstLink = '';
	@Input('second-link') secondLink = '';
  @Output('go-first') goFirstEvent = new EventEmitter<any>();
  @Output('go-second') goSecondEvent = new EventEmitter<any>();

	constructor(protected navController: NavController) {}

	ngOnInit() {}

  goToFirstIcon(){
    this.goFirstEvent.emit();
    if(this.firstLink){
      this.navController.navigateForward([this.firstLink]);
    }
  }

  goToSecondIcon(){
    this.goSecondEvent.emit();
    if(this.secondLink){
      this.navController.navigateForward([this.secondLink]);
    }
  }
}
