import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/models/event';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'merz-horizontal-event-card',
  templateUrl: './horizontal-event-card.component.html',
  styleUrls: ['./horizontal-event-card.component.scss'],
})
export class HorizontalEventCardComponent implements OnInit {
  @Input() event: Event;

  constructor(protected utilsService: UtilsService) { }

  ngOnInit(): void {
  }

  get duration(){
    return `${this.event?.start.substr(11,5)}`;
  }

  get date(){
    return `${this.weekDay}, ${this.monthDay} ${this.month}`;
  }
  get endDate(){
    return `- ${this.endWeekDay}, ${this.endMonthDay} ${this.endMonth}`;
  }

  get weekDay(){
    return this.utilsService.daysToText[new Date(this.event?.start).getDay()];
  }
  get monthDay(){
    return new Date(this.event?.start).getDate();
  }

  get month(){
    return this.utilsService.monthsToText[new Date(this.event?.start).getMonth()];
  }

  get endWeekDay(){
    return this.utilsService.daysToText[new Date(this.event?.end).getDay()];
  }
  get endMonthDay(){
    return new Date(this.event?.end).getDate();
  }

  get endMonth(){
    return this.utilsService.monthsToText[new Date(this.event?.end).getMonth()];
  }

  get location() {
    return this.event?.location;
  }
}
