import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumTagComponent } from './premium-tag.component';
import { IonicModule } from '@ionic/angular';
import { TextModule } from '../text/text.module';



@NgModule({
  declarations: [PremiumTagComponent],
  imports: [
    CommonModule,
    IonicModule,
    TextModule
  ],
  exports: [
    PremiumTagComponent
  ]
})
export class PremiumTagModule { }
