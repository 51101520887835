import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';

@Component({
  selector: 'merz-complete-profile-modal',
  templateUrl: './complete-profile-modal.component.html',
  styleUrls: ['./complete-profile-modal.component.scss']
})
export class CompleteProfileModalComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    protected navController: NavController
  ) {}

	ngOnInit() {
  }

	dismiss() {
		this.modalController.dismiss();
	}

  goToCompleteProfile() {
    this.dismiss();
    this.navController.navigateForward([MERZ_ROUTES.registerOcupationComplete]);
  }
}
