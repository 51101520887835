import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputErrorComponent } from './input-error/input-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TextModule } from '../text/text.module';
import { InputReadonlyComponent } from './input-readonly/input-readonly.component';
import { InputComponent } from './input/input.component';
import { TextareaComponent } from './textarea/textarea.component';
import { UserDeviceComponent } from './user-device/user-device.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputSelectSelectComponent } from './input-select-select/input-select-select.component';
import { InputEmptyComponent } from './input-empty/input-empty.component';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { ButtonModule } from '../button/button.module';
import { RouterModule } from '@angular/router';
import { InputCheckboxListComponent } from './input-checkbox-list/input-checkbox-list.component';
import { InputAvatarComponent } from './input-avatar/input-avatar.component';
import { InputWorkcenterComponent } from './input-workcenter/input-workcenter.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputVeevaIdComponent } from './input-veeva-id/input-veeva-id.component';
import { InputCheckboxPasswordComponent } from './input-checkbox-password/input-checkbox-password.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputRadioComponent } from './input-radio/input-radio.component';
import { InputCountriesComponent } from './input-countries/input-countries.component';
import { InputRadioStarsComponent } from './input-radio-stars/input-radio-stars.component';
import { InputRadioFacesComponent } from './input-radio-faces/input-radio-faces.component';
import { InputRadioNpsComponent } from './input-radio-nps/input-radio-nps.component';
import { InputTextareaRestrictedWordsComponent } from './input-textarea-restricted-words/input-textarea-restricted-words.component';
import { ModalModule } from '../modal/modal.module';
import { InputRecaptchaComponent } from './input-recaptcha/input-recaptcha.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';


const COMPONENTS = [
  InputErrorComponent,
  InputReadonlyComponent,
  InputComponent,
  TextareaComponent,
  UserDeviceComponent,
  InputSelectComponent,
  InputDateComponent,
  InputSelectSelectComponent,
  InputEmptyComponent,
  InputCheckboxComponent,
  InputCheckboxListComponent,
  InputAvatarComponent,
  InputWorkcenterComponent,
  InputPasswordComponent,
  InputVeevaIdComponent,
  InputCheckboxPasswordComponent,
  InputSearchComponent,
  InputRadioComponent,
  InputCountriesComponent,
  InputRadioStarsComponent,
  InputRadioFacesComponent,
  InputRadioNpsComponent,
  InputTextareaRestrictedWordsComponent,
  InputRecaptchaComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    TextModule,
    ReactiveFormsModule,
    IonicModule,
    ButtonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    ModalModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  exports: COMPONENTS,
})
export class InputModule { }
