// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.card {
  position: relative;
}
.card ion-thumbnail {
  display: grid;
  place-items: center;
  --size: 150px;
  --border-radius: 5px;
  background: var(--ion-color-background);
}
.card ion-thumbnail ion-img {
  width: 100%;
}
.card .image {
  width: 80%;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/product-category-card/product-category-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AACA;EACE,kBAAA;AAEF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,oBAAA;EACA,uCAAA;AAEJ;AAAI;EACE,WAAA;AAEN;AAEE;EACE,UAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;EACA,+BAAA;AAAJ","sourcesContent":[":host {\n  display: block;\n}\n.card {\n  position: relative;\n\n  ion-thumbnail {\n    display: grid;\n    place-items: center;\n    --size: 150px;\n    --border-radius: 5px;\n    background: var(--ion-color-background);\n\n    ion-img {\n      width: 100%;\n    }\n\n  }\n  .image {\n    width: 80%;\n    position: absolute;\n    right: 50%;\n    top: 50%;\n    transform: translate(50%, -50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
