import { AnimationController } from '@ionic/angular';

const animationCtrl: AnimationController = new AnimationController();

export const enterAnimation = (baseEl: any) => {
  const backdropAnimation = animationCtrl
    .create()
    .addElement(baseEl.shadowRoot.querySelector('[part="backdrop"]')!)
    .duration(500)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

  const wrapperAnimation = animationCtrl
    .create()
    .addElement(baseEl.shadowRoot.querySelector('[part="content"]')!)
    .duration(500)
    .easing('ease')
    .fromTo('transform', 'translate3d(0,100%,0)', 'translate3d(0, 0, 0)')
    .fromTo('opacity', '0.01', '1');

  return animationCtrl.create()
    .addElement(baseEl)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};

export const leaveAnimation = (baseEl: any) => {
  return enterAnimation(baseEl).direction('reverse');
};
