import { Component, Input, OnInit } from '@angular/core';
import { Speaker } from 'src/app/models/speaker';

@Component({
  selector: 'merz-speakers-small-block',
  templateUrl: './speakers-small-block.component.html',
  styleUrls: ['./speakers-small-block.component.scss']
})
export class SpeakersSmallBlockComponent implements OnInit {
  @Input() speakers: Speaker[];
  @Input('from-schedule') fromSchedule = false;

  constructor(
  ) {}

  ngOnInit(): void {
  }

  get image(){
    return this.speakers[0]?.image;
  }

  get count(){
    return this.speakers?.length;
  }

  get numberText(){
    return `+${this.count-1}`;
  }
}
