import { Component, Input, OnInit } from '@angular/core';
import { Speaker } from 'src/app/models/speaker';

@Component({
  selector: 'merz-speaker-card',
  templateUrl: './speaker-card.component.html',
  styleUrls: ['./speaker-card.component.scss'],
})
export class SpeakerCardComponent implements OnInit {
  @Input() speaker: Speaker;
  @Input() large = false;
  //Clase cuando sale en vista formación
  @Input('is-big') isBig = false;

  constructor() {}

  ngOnInit(): void {}

  get image() {
    return this.speaker?.image;
  }

  get countText() {
    return this.speaker?.videosCount !== 1
      ? `${this.speaker?.videosCount} videos`
      : '1 video';
  }

  get name() {
    return this.speaker?.title
      ? `${this.speaker?.title}. ${this.speaker?.name}`
      : this.speaker?.name;
  }
}
