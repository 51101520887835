import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Filter, FilterType } from 'src/app/models/filter';
import { GalleryApiClientService } from 'src/app/services/api/gallery-api-client/gallery-api-client.service';
import { ProductCategoryApiClientService } from 'src/app/services/api/product-category-api-client/product-category-api-client.service';
import { FiltersGetOptionsService } from 'src/app/services/filters-get-options-service/filters-get-options.service';
import { FiltersService } from 'src/app/services/filters-service/filters.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'merz-filter-gallery-modal',
  templateUrl: './filter-gallery-modal.component.html',
  styleUrls: ['./filter-gallery-modal.component.scss'],
})
export class FilterGalleryModalComponent implements OnInit {
  filters: Filter[];
  routerEvent: Subscription;

  langChangeSubscription: Subscription;

  constructor(
    protected productCategoryApiClientService: ProductCategoryApiClientService,
    protected galleryApiClientService: GalleryApiClientService,
    protected navController: NavController,
    protected filtersGetOptionsService: FiltersGetOptionsService,
    protected filtersService: FiltersService,
    protected router: Router,
    protected translateService: TranslateService
  ) {
    this.routerEvent = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tryCreateFilters();
      }
    });
  }

  ngOnInit() {
    this.tryCreateFilters();

    this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
      this.createFilters();
    });
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
  }

  tryCreateFilters() {
    if (this.filtersGetOptionsService?.productCategoriesOptions?.length > 0) {
      this.createFilters();
    } else {
      setTimeout(() => {
        this.tryCreateFilters();
      }, 100);
    }
  }

  get filterParams() {
    return this.filtersService.filterParams;
  }

  createFilters() {
    this.filters = [
      new Filter({
        type: FilterType.SELECT,
        field: 'productcategory',
        options: this.filtersGetOptionsService.productCategoriesOptions,
        title: this.translateService.instant('FILTERS.GALLERY.PRODUCT'),
        placeholder: this.translateService.instant('FILTERS.PLACEHOLDER'),
        optionSelectedValue: this.filterParams['productcategory'],
      }),
      // new Filter({
      //   type: FilterType.CHECKBOX,
      //   field: 'category',
      //   options: [
      //     { text: '¿Sabías qué…?', value: 'do_you_know' },
      //     { text: 'Antes & después', value: 'before_after' },
      //     { text: 'Clippings', value: 'clippings' },
      //     { text: 'Frase del día', value: 'quote_of_day' },
      //     { text: 'Lifestyle', value: 'lifestyle' },
      //     { text: 'Mitos y realidades', value: 'myths_realities' },
      //   ],
      //   title: 'Categoría',
      //   optionSelectedValue: this.filterParams['category'],
      // }),
      new Filter({
        type: FilterType.CHECKBOX,
        field: 'type',
        options: [
          { text: this.translateService.instant('FILTERS.GALLERY.FORMAT.OPTIONS.VIDEO'), value: 'video' },
          { text: this.translateService.instant('FILTERS.GALLERY.FORMAT.OPTIONS.IMAGE'), value: 'normal' },
          { text: this.translateService.instant('FILTERS.GALLERY.FORMAT.OPTIONS.CAROUSEL'), value: 'carrusel' },
        ],
        title: this.translateService.instant('FILTERS.GALLERY.FORMAT.TITLE'),
        optionSelectedValue: this.filterParams['type'],
      }),
      new Filter({
        type: FilterType.SELECT,
        field: 'language',
        options: [
          { text: this.translateService.instant('FILTERS.GALLERY.LANGUAGE.OPTIONS.SPANISH'), value: 'es' },
          { text: this.translateService.instant('FILTERS.GALLERY.LANGUAGE.OPTIONS.ENGLISH'), value: 'en' },
          { text: this.translateService.instant('FILTERS.GALLERY.LANGUAGE.OPTIONS.PORTUGUESE'), value: 'pt' },
        ],
        title: this.translateService.instant('FILTERS.GALLERY.LANGUAGE.TITLE'),
        placeholder: this.translateService.instant('FILTERS.PLACEHOLDER'),
        optionSelectedValue: this.filterParams['language'],
      }),
    ];
  }

}
