import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { FreeContent } from 'src/app/models/free-content';

@Injectable({
  providedIn: 'root',
})
export class FreeContentVideoApiClientService extends ApiClientService<FreeContent> {
  endpoint = 'freecontent/{freeContentID}/video';
}
