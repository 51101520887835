// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  min-height: calc(100% - 59px);
  margin-top: 59px;
  background: var(--ion-color-white);
  z-index: 5;
  overflow: auto;
  padding: 0 24px 24px;
  position: relative;
}
.modal-container .header {
  background: var(--ion-color-white);
  position: sticky;
  top: 0;
  height: 68px;
  border-bottom: 1px solid var(--ion-color-lines);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}
.modal-container .header ion-icon {
  font-size: 23px;
  cursor: pointer;
}
.modal-container .header .clear {
  cursor: pointer;
}

ion-footer {
  position: absolute;
  bottom: 0;
}

ion-toolbar {
  --background: var(ion-color-white);
  --min-height: 112px;
  --padding-bottom: 30px;
  --padding-end: 24px;
  --padding-start: 23px;
  --padding-top: 12px;
  border-top: 1px solid var(--ion-color-lines);
  --border-width: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/filter-users/filter-users.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,gBAAA;EACA,kCAAA;EACA,UAAA;EACA,cAAA;EACA,oBAAA;EAEA,kBAAA;AAAF;AAGE;EACE,kCAAA;EACA,gBAAA;EACA,MAAA;EACA,YAAA;EACA,+CAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AADJ;AAGI;EACE,eAAA;EACA,eAAA;AADN;AAII;EACE,eAAA;AAFN;;AAOA;EACE,kBAAA;EACA,SAAA;AAJF;;AAOA;EACE,kCAAA;EACA,mBAAA;EACA,sBAAA;EACA,mBAAA;EACA,qBAAA;EACA,mBAAA;EACA,4CAAA;EACA,4BAAA;AAJF","sourcesContent":[".modal-container {\n  min-height: calc(100% - 59px);\n  margin-top: 59px;\n  background: var(--ion-color-white);\n  z-index: 5;\n  overflow: auto;\n  padding: 0 24px 24px;\n  //padding-bottom: 135px;\n  position: relative;\n\n\n  .header {\n    background: var(--ion-color-white);\n    position: sticky;\n    top: 0;\n    height: 68px;\n    border-bottom: 1px solid var(--ion-color-lines);\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    z-index: 10;\n\n    ion-icon {\n      font-size: 23px;\n      cursor: pointer;\n    }\n\n    .clear {\n      cursor: pointer;\n    }\n  }\n}\n\nion-footer {\n  position: absolute;\n  bottom: 0;\n}\n\nion-toolbar {\n  --background: var(ion-color-white);\n  --min-height: 112px;\n  --padding-bottom: 30px;\n  --padding-end: 24px;\n  --padding-start: 23px;\n  --padding-top: 12px;\n  border-top: 1px solid var(--ion-color-lines);\n  --border-width: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
