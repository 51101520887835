import { Base } from './base';
import { Position } from './position';
import { Speaker } from './speaker';

export class Formation extends Base {
  speaker?: Speaker;
  speakers?: Speaker[];
  address?: string;
  name?: string;
  date?: string;
  end?: string;
  start?: string;
  isOnline?: boolean;
  image?: string;
  location?: string;
  notification?: boolean;
  accessLevel?: string;
  component?: any;
  createdDate?: string;
  description?: string;
  position?: string;
  position2?: Position;
  products?: any[];
  relatedFormations?: Formation[];
  seats?: number;
  treatment?: any;
  type?: string;
  updatedDate?: string;
  url?: string;
  zone?: string;
  premium?: boolean;
  canSee?: boolean;
  planName?: string;

  buttonAction?: string;
  buttonActionValue?: string;

  disclaimer?: boolean;
  userAcceptDisclaimer?: boolean;
}



