import { Injectable } from '@angular/core';
import { NotificationService } from '../notification/notification.service';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class FormManagementService {
	excludeFields = ['nonFieldErrors', 'detail'];
	errorMessage: string = '';

	constructor(protected notificationService: NotificationService) {}

	/**
	 * This method will check if it's a general error or an error form to show either a notification
	 * or the correspondant error per field
	 */
	manageFormValidationError(error: any, formGroup?: UntypedFormGroup) {
		if (error.status !== 500) {
			let errorArray = error.error;
			const fields = Object.keys(errorArray).filter((f) => !this.excludeFields.includes(f));
			fields.length === 0 ? this.getGeneralError(errorArray) : this.getFieldError(errorArray, formGroup!, fields);
		} else {
			this.notificationService.showError('Error', 'We cannot process the request right now', 8000);
		}
	}

	getGeneralError(error: any) {
		for (let field of this.excludeFields) {
			if (error[field]) {
				let errorMessage = error[field];
				this.notificationService.showError('Opps, something went wrong', errorMessage, 8000);
			}
		}
	}

	getFieldError(error: any, formGroup: UntypedFormGroup, fields: any) {
		for (let field of fields) {
			let abstractControl = formGroup.get(field);
			if (abstractControl) {
				let errorMessage = error[field].join(', ');
				formGroup.controls[field].setErrors({ remote: true, value: errorMessage });
			}
		}
	}

	validateForm(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field)!;
			control.markAsTouched({ onlySelf: true });
		});
	}

	manageFormValidationErrorNoNotification(error: any, formGroup?: UntypedFormGroup) {
		if (error.status !== 500) {
			let errorArray = error.error;
			const fields = Object.keys(errorArray).filter((f) => !this.excludeFields.includes(f));
			fields.length === 0 ? this.getGeneralError(errorArray) : this.getFieldError(errorArray, formGroup!, fields);
		} else {
			this.errorMessage = 'We cannot process your request right now';
		}
	}

	getGeneralErrorNoNotification(error: any) {
		for (let field of this.excludeFields) {
			if (error[field]) {
				this.errorMessage = error[field];
			} else {
				this.errorMessage = 'We cannot process your request right now';
			}
		}
	}

	/**
	 * Scroll to the first invalid field
	 * mark all fields as touched
	 * @param formGroup
	 */
	validateFormWithScroll(formGroup: UntypedFormGroup) {
		let scrollIntoView = false;
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);
			control?.markAsTouched({ onlySelf: true });
			if (control?.invalid && !scrollIntoView) {
				document.getElementById(field)?.scrollIntoView({
					block: 'center',
					behavior: 'smooth',
				});
				scrollIntoView = true;
			}
		});
	}
}
