import { Injectable } from '@angular/core';
import { BaseDetailResolver } from './base-detail.resolver';
import { Speaker } from 'src/app/models/speaker';
import { SpeakerApiClientService } from '../api/speaker-api-client/speaker-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class SpeakerResolver extends BaseDetailResolver<Speaker> {
  protected idParam = 'speakerId';

  constructor(protected service: SpeakerApiClientService) {
    super();
  }
}
