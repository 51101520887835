import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Manager } from '../../api/manager.service';
import { Queryset } from '../../api/queryset';
import { Filter } from '../../models/filter/filter';

@Component({
  selector: 'dnest-core-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  count: number = 0;
  numberOfPages: number = 1;
  pageNumbers: number[] = [];
  noMore: boolean = false;
  actualPage = 1;
  // tabsParameters: {}[];
  subscribedFilter = {};
  queryset: any;
  results: any[] = [];
  _orderingFilter: any;
  _filters: Filter[] = [];
  filterTimeout: any;

  /**
   * Primary by default
   */
  @Input('theme') theme: string = 'primary';
  @Input('text-theme') textTheme: string = 'primary';
  @Input('button-link-theme') buttonLinkTheme: string = 'primary';
  @Input('button-link-page-theme') buttonLinkPageTheme: string = 'primary';
  @Input('button-link-page-selected-theme') buttonLinkPageSelectedTheme: string = 'primary';
  @Input('button-show-more-text') buttonShowMoreText: string = "Cargar más";
  @Input('show-button-link') showButtonLink: boolean = true;
  @Input('button-show-more-large') buttonShowMoreLarge: boolean = false;
  @Input('show-pages') showPages: boolean = true;
  @Input('page-size') pageSize: number = 10;
  @Input('pages-to-show') pagesToShow: number = 10;
  @Input('show-resume-text') showResumeText: boolean = true;
  @Input('service') service: any;
  @Input('filters')
  set filters(filters: Filter[]) {
    this._filters = filters;
    if (!filters) {
      return;
    }
    this.queryset = this.service.all();
    for (let i = 0; i < filters.length; i++) {
      if ((this.subscribedFilter as any)[filters[i].paramName]) {
        continue;
      }
      this.queryset = filters[i].updateQueryset(this.queryset);
      (this.subscribedFilter as any)[filters[i].paramName] = true;
      filters[i].changeValueEvent.subscribe(
        data => {
          if (!!this.filterTimeout) clearTimeout(this.filterTimeout);
          this.filterTimeout = setTimeout(() => this.update(), 500);
        }
      );
    }
  }
  /* @Input('tabs')
  set tabs(tabs: Tabs) {
    if (!tabs) {
      return;
    }
    tabs.tabs.forEach(tab => {
      if (tab.selected) {
        this.tabsParameters = tab.params;
      }
    });
    tabs.selectedTabEvent.subscribe(
      tab => {
        this.tabsParameters = tab.params;
        this.update();
      }
    );
  } */
  @Input('ordering-filter')
  set orderingFilter(filter: Filter) {
    if (!filter || (this.subscribedFilter as any)[filter?.paramName]) {
      return;
    }
    this._orderingFilter = filter;
    (this.subscribedFilter as any)[filter?.paramName] = true;
    filter?.changeValueEvent?.subscribe(
      data => {
        this.update();
      }
    );
  }
  @Input('parameters-to-add') parametersToAdd: {}[] = [];

  @Output('on-result') filteredResult = new EventEmitter<any>();
  @Output('on-loading') loading = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if (!this.queryset) {
      this.queryset = this.service.all();
    }
    this.update();
  }

  nextPage() {
    if (this.actualPage + 1 > this.numberOfPages) {
      this.noMore = true;
    } else {
      this.update(this.actualPage + 1);
    }
  }

  createQueryset() {
    this.queryset = this.service.all();
    if (this._filters) {
      for (let i = 0; i < this._filters.length; i++) {
        this.queryset = this._filters[i].updateQueryset(this.queryset);
      }
    }
    if (this.parametersToAdd) {
      this.parametersToAdd.forEach((parameter) => {
        this.queryset = this.queryset.filter(parameter);
      });
    }
    if (this._orderingFilter) {
      this.queryset = this.queryset.orderBy([this._orderingFilter.currentValue]);
    }
    /* if (this.tabsParameters) {
      this.tabsParameters.forEach((parameter) => {
        this.queryset = this.queryset.filter(parameter);
      });
    } */
  }

  update(pageNumber?: number) {
    this.noMore = false;
    let restartResults = false;
    if (pageNumber == undefined) {
      pageNumber = 1;
      this.createQueryset();
      restartResults = true;
    }

    let notInRange = false;
    if (pageNumber > this.numberOfPages) {
      pageNumber = this.numberOfPages;
      notInRange = true;
    } else if (pageNumber < 1) {
      pageNumber = 1;
      notInRange = true;
    }
    this.actualPage = pageNumber;

    if (notInRange) {
      return;
    }
    this.loading.emit();
    this.queryset
      .pageSize(this.pageSize)
      .page(pageNumber)
      .subscribe((paginator: { results: any; length?: any; count: any; }) => {
        if (!paginator.results) {
          paginator = {
            count: paginator.length,
            results: paginator,
          };
          this.pageSize = paginator.count;
        }
        this.count = paginator.count;
        this.numberOfPages = this.count / this.pageSize;
        if (!this.numberOfPages) {
          this.numberOfPages = 1;
        } else {
          this.numberOfPages = Math.ceil(this.numberOfPages);
        }

        if (this.showPages) {
          let toSum = 1;
          this.pageNumbers = [];
          this.pageNumbers.push(this.actualPage);
          let pagesToShowTemp = this.pagesToShow;
          while (pagesToShowTemp) {
            let added = false;
            const prev = pageNumber! - toSum;
            if (prev > 0) {
              this.pageNumbers.unshift(prev);
              pagesToShowTemp--;
              added = true;
            }
            const next = pageNumber! + toSum;
            if (next <= this.numberOfPages) {
              this.pageNumbers.push(next);
              pagesToShowTemp--;
              added = true;
            }
            toSum++;
            if (!added) {
              break;
            }
          }
        }
        if (paginator.results.length == 0) {
          this.noMore = true;
        }
        if (restartResults || this.showPages) {
          this.results = paginator.results;
        } else {
          this.results = this.results.concat(paginator.results);
        }
        this.filteredResult.emit({
          results: this.results,
          pageCount: this.numberOfPages,
          count: this.count,
        });
      });

    if (this.showPages && window) {
      window.scrollTo(0, 0);
    }
  }
}
