import { Component, OnInit, Input, ViewChildren, ViewContainerRef, QueryList, EventEmitter, Output, ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { Column, ColumnType } from '../../models/table/column';
import { SubmenuComponent } from '../submenu/submenu.component';

@Component({
  selector: 'dnest-core-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";
  @Input('button-theme') buttonTheme: string = "primary";
  @Input('submenu-theme') submenuTheme: string = "primary";
  @Input('component') component: any;
  @Input('no-head') noHead: boolean = false;
  @Input('last-in-bold') lastInBold: boolean = false;
  _columns: Column[]= [];
  @Input('columns')
  set columns (columns: Column[]) {
    this._columns = columns;
    this.componentsCreated = 0;
    this.printComponents();
  }
  get columns (): Column[] {
    return this._columns;
  }
  _rows: any[] = [];
  @Input('rows')
  set rows (rows: any[]) {
    this._rows = rows;
    this.componentsCreated = 0;
    this.printComponents();
  }
  get rows (): any[] {
    return this._rows;
  }

  @ViewChildren('component', { read: ViewContainerRef }) components: QueryList<ViewContainerRef> = new QueryList();
  @ViewChildren('submenu', { read: SubmenuComponent }) submenus: QueryList<SubmenuComponent> = new QueryList();
  @ViewChildren('componentRow', { read: ViewContainerRef }) componentRows: QueryList<ViewContainerRef> = new QueryList();

  @Output('on-sort') onSort = new EventEmitter<Column>();

  columnType = ColumnType;
  componentsCreated = 0;

  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    this.componentsCreated = 0;
  }

  printComponents() {
    let someComponent = false;
    if (!this.columns || !this.rows) {
      return;
    }

    for(let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].type == ColumnType.component) {
        someComponent = true;
      }
    }

    if (someComponent) {
      setTimeout(() => {
        let index = 0;
        this.rows.forEach(row => {
          this.columns.forEach(column => {
            if (column.type == ColumnType.component) {
              if (this.componentsCreated == index) {
                let componentFactory = this.componentFactoryResolver.resolveComponentFactory(column.secondaryValue);
                const componentRowRef = this.componentRows.toArray()[index].createComponent(componentFactory);
                (<any>componentRowRef.instance).item = row;
                this.componentsCreated++;
              }
              index++;
            }
          });
        });
      }, 100);
    }
  }

  clickOnColumn(column: Column) {
    if (column.canSort) {
      this.onSort.emit(column);
    }
  }

  @Output('on-row-click') rowClickEvent = new EventEmitter<any>();
  clickOnRow(index: number, row: any) {
    const component = row.component || this.component;
    if (!component) {
      return;
    }

    row.opened = !row.opened;
    this.rowClickEvent.emit({row: row, opened: row.opened});
    if (row.opened) {
      if (!row.created) {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = this.components.toArray()[index].createComponent(componentFactory);
        (<any>componentRef.instance).item = row;
      }
      row.created = true;
    }
  }

  clickOnButton(column: Column, row: any) {
    if (column.callbackButton) {
      column.callbackButton(row);
    }
  }

  openSubmenu(event: any, index: any) {
    this.submenus.toArray()[index].toggleMenu(event);

  }
}
