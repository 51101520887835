class MerzRoutes {
  welcome = 'bienvenido';
  welcomeComplete = '/bienvenido';

  login = 'entrar';
  loginComplete = '/entrar';

  loginTwoOptions = 'entrar-options';
  loginTwoOptionsComplete = '/entrar-options';

  loginByEmail = 'entrar-email';
  loginByEmailComplete = '/entrar-email';

  loginByPhone = 'entrar-phone';
  loginByPhoneComplete = '/entrar-phone';

  static = 'static';
  staticComplete = '/static';

  register = 'registro';
  registerComplete = '/registro';

  registerCode = 'registro-code/:code';
  registerCodeComplete = '/registro-code/:code';

  registerSelectCountry = 'seleccionar-pais';
  registerSelectCountryComplete = this.registerComplete + '/seleccionar-pais';

  registerContinueSelection = 'continuar-seleccion';
  registerContinueSelectionComplete =
    this.registerComplete + '/continuar-seleccion';

  registerPhoneConfirm = 'confirmar-telefono';
  registerPhoneConfirmComplete = this.registerComplete + '/confirmar-telefono';

  registerChangePhone = 'cambiar-telefono';
  registerChangePhoneComplete = this.registerComplete + '/cambiar-telefono';

  registerOcupation = 'profesion';
  registerOcupationComplete = this.registerComplete + '/profesion';

  registerFunction = 'funcion';
  registerFunctionComplete = this.registerComplete + '/funcion';

  registerStatement = 'declaracion';
  registerStatementComplete = this.registerComplete + '/declaracion';

  registerSpecialty = 'especialidad';
  registerSpecialtyComplete = this.registerComplete + '/especialidad';

  registerWorkcenter = 'clinica';
  registerWorkcenterComplete = this.registerComplete + '/clinica';

  registerWorkcenterAdd = 'clinica-nueva';
  registerWorkcenterAddComplete = this.registerComplete + '/clinica-nueva';

  registerMaster = 'master';
  registerMasterComplete = this.registerComplete + '/master';

  registerMasterEndate = 'master-endate';
  registerMasterEndateComplete = this.registerComplete + '/master-endate';

  registerPassword = 'contrasena';
  registerPasswordComplete = this.registerComplete + '/contrasena';

  registerEmail = 'correo';
  registerEmailComplete = this.registerComplete + '/correo';

  forgot = 'olvide-contrasena';
  forgotComplete = '/olvide-contrasena';

  forgotConfirm = 'confirmar-contrasena';
  forgotConfirmComplete = '/confirmar-contrasena';

  notifications = 'notificaciones';
  notificationsComplete = '/notificaciones';

  archivedNotifications = 'archivadas';
  archivedNotificationsComplete = this.notificationsComplete + '/archivadas';

  formation = 'formacion';
  formationComplete = '/formacion';

  formationSearch = 'formacion-search';
  formationSearchComplete = '/formacion-search';

  formationVideoSearch = 'video-search';
  formationVideoSearchComplete = this.formationComplete + '/video-search';

  speakers = 'ponentes';
  speakersComplete = this.formationComplete + '/ponentes';

  speaker = 'ponente';
  speakerComplete = '/ponente';
  speakerId = ':speakerId';
  speakerIdComplete = this.speakerComplete + '/${speakerId}';

  formationsOnline = 'formaciones-online';
  formationsOnlineComplete = this.formationComplete + '/formaciones-online';

  formationsOnsite = 'formaciones-presenciales';
  formationsOnsiteComplete =
    this.formationComplete + '/formaciones-presenciales';

  video = 'video';
  videoComplete = '/video';
  videoId = ':videoId';
  videoIdComplete = this.videoComplete + '/${videoId}';

  formacionOnline = 'formacion-online';
  formacionOnlineComplete = '/formacion-online';
  formacionOnlineId = ':formationId';
  formacionOnlineIdComplete = this.formacionOnlineComplete + '/${formationId}';

  formacionOnsite = 'formacion-presencial';
  formacionOnsiteComplete = '/formacion-presencial';
  formacionOnsiteId = ':formationId';
  formacionOnsiteIdComplete = this.formacionOnsiteComplete + '/${formationId}';

  event = 'evento';
  eventComplete = '/evento';
  eventId = ':eventId';
  eventIdComplete = this.eventComplete + '/${eventId}';

  eventSchedule = 'agenda';
  eventScheduleComplete = this.eventIdComplete + '/agenda';

  eventActionsMerz = 'acciones-merz';
  eventActionsMerzComplete = this.eventIdComplete + '/acciones-merz';

  eventMoves = 'traslados';
  eventMovesComplete = this.eventIdComplete + '/traslados';

  eventSocialNetworks = 'redes-sociales';
  eventSocialNetworksComplete = this.eventIdComplete + '/redes-sociales';

  eventHotels = 'hoteles';
  eventHotelsComplete = this.eventIdComplete + '/hoteles';

  eventFlights = 'vuelos';
  eventFlightsComplete = this.eventIdComplete + '/vuelos';

  eventFlight = 'vuelo';
  eventFlightComplete = this.eventFlightsComplete + '/vuelo';

  eventInscriptions = 'inscripciones';
  eventInscriptionsComplete = this.eventIdComplete + '/inscripciones';

  eventInscription = 'inscripcion';
  eventInscriptionComplete = this.eventInscriptionsComplete + '/inscripcion';

  eventFoods = 'comidas';
  eventFoodsComplete = this.eventIdComplete + '/comidas';

  eventFoodDetail = 'detalles-comida';
  eventFoodDetailComplete = this.eventFoodsComplete + '/detalles-comida';

  eventDinners = 'cenas';
  eventDinnersComplete = this.eventIdComplete + '/cenas';

  eventDinnerDetail = 'detalles-cena';
  eventDinnerDetailComplete = this.eventDinnersComplete + '/detalles-cena';

  eventSpeakers = 'ponentes';
  eventSpeakersComplete = this.eventIdComplete + '/ponentes';

  eventCovid = 'covid';
  eventCovidComplete = this.eventIdComplete + '/covid';

  eventParkings = 'parkings';
  eventParkingsComplete = this.eventIdComplete + '/parkings';

  eventDocuments = 'documentos';
  eventDocumentsComplete = this.eventIdComplete + '/documentos';

  eventDocumentDetail = 'detalle-documento';
  eventDocumentDetailComplete =
    this.eventDocumentsComplete + '/detalle-documento';

  workCenter = 'clinica';
  workCenterComplete = '/clinica';
  workCenterId = ':workcenterId';
  workCenterIdComplete = this.workCenterComplete + '/${workcenterId}';

  workCenterName = 'clinica-name';
  workCenterNameComplete = this.workCenterIdComplete + '/clinica-name';

  workCenterAddress = 'clinica-direccion';
  workCenterAddressComplete = this.workCenterIdComplete + '/clinica-direccion';

  workCenterPhone = 'clinica-tel';
  workCenterPhoneComplete = this.workCenterIdComplete + '/clinica-tel';

  workCenterNew = 'clinica-new';
  workCenterNewComplete = this.workCenterComplete + '/clinica-new';

  kamWorkCenter = 'kam-clinica';
  kamWorkCenterComplete = '/kam-clinica';
  kamWorkCenterId = ':kamWorkcenterId';
  kamWorkCenterIdComplete = this.kamWorkCenterComplete + '/${kamWorkcenterId}';

  kamWorkCenterName = 'kam-clinica-name';
  kamWorkCenterNameComplete =
    this.kamWorkCenterIdComplete + '/kam-clinica-name';

  kamWorkCenterAddress = 'kam-clinica-direccion';
  kamWorkCenterAddressComplete =
    this.kamWorkCenterIdComplete + '/kam-clinica-direccion';

  kamWorkCenterPhone = 'kam-clinica-tel';
  kamWorkCenterPhoneComplete =
    this.kamWorkCenterIdComplete + '/kam-clinica-tel';

  home = 'inicio';
  homeComplete = '/inicio';

  products = 'productos';
  productsComplete = '/productos';

  events = 'eventos';
  eventsComplete = '/eventos';

  profile = 'perfil';
  profileComplete = '/perfil';

  eventsFilterSearch = 'events-search';
  eventsFilterSearchComplete = this.eventsComplete + '/events-search';

  poll = 'encuesta';
  pollComplete = '/encuesta';
  pollId = ':pollId';
  pollIdComplete = this.pollComplete + '/${pollId}';

  pollFinish = 'finalizada';
  pollFinishComplete = this.pollIdComplete + '/finalizada';

  preferences = 'preferencias';
  preferencesComplete = '/preferencias';

  completeProfile = 'completar-perfil';
  completeProfileComplete = '/completar-perfil';

  workCenters = 'clinicas';
  workCentersComplete = this.profileComplete + '/clinicas';

  contents = 'contenidos';
  contentsComplete = '/contenidos';

  content = 'contenido';
  contentComplete = '/contenido';

  favorites = 'favoritos';
  favoritesComplete = this.profileComplete + '/favoritos';

  profileCourses = 'cursos';
  profileCoursesComplete = this.profileComplete + '/cursos';

  contact = 'contactar';
  contactComplete = this.profileComplete + '/contactar';

  myUsers = 'mis-usuarios';
  myUsersComplete = this.profileComplete + '/mis-usuarios';

  kamWorkCenters = 'kam-clinicas';
  kamWorkCentersComplete = this.profileComplete + '/kam-clinicas';

  kamWorkCentersNew = 'kam-clinica-nueva';
  kamWorkCentersNewComplete =
    this.kamWorkCentersComplete + '/kam-clinica-nueva';

  profileMyUserNew = 'usuario-nuevo';
  profileMyUserNewComplete = this.myUsersComplete + '/usuario-nuevo';

  profileMyUsersSearch = 'usuario-busqueda';
  profileMyUsersSearchComplete = this.myUsersComplete + '/usuario-busqueda';

  profileMyUserEdit = 'editar-usuario';
  profileMyUserEditComplete = this.myUsersComplete + '/editar-usuario';
  profileMyUserEditId = ':kamUserId';
  profileMyUserEditIdComplete =
    this.profileMyUserEditComplete + '/${kamUserId}';

  profileMyUserEditIdVeeva = 'oce';
  profileMyUserEditIdVeevaComplete = this.profileMyUserEditIdComplete + '/oce';

  profileMyUserEditOceInstitution = 'oce-institution';
  profileMyUserEditOceInstitutionComplete =
    this.profileMyUserEditIdComplete + '/oce-institution';

  profileMyUserEditEdit = 'editar';
  profileMyUserEditEditComplete = this.profileMyUserEditIdComplete + '/editar';

  profileMyUserEditIdWorkData = 'work-data';
  profileMyUserEditIdWorkDataComplete =
    this.profileMyUserEditIdComplete + '/work-data';

  profileMyUserEditIdPersonalData = 'personal-data';
  profileMyUserEditIdPersonalDataComplete =
    this.profileMyUserEditIdComplete + '/personal-data';

  profileMyUserEditIdWorkDataOcupation = 'kam-profesion';
  profileMyUserEditIdWorkDataOcupationComplete =
    this.profileMyUserEditIdWorkDataComplete + '/kam-profesion';

  profileMyUserEditIdWorkDataMaster = 'kam-master';
  profileMyUserEditIdWorkDataMasterComplete =
    this.profileMyUserEditIdWorkDataComplete + '/kam-master';

  profileMyUserEditIdWorkDataSpecialty = 'kam-especialidad';
  profileMyUserEditIdWorkDataSpecialtyComplete =
    this.profileMyUserEditIdWorkDataComplete + '/kam-especialidad';

  profileMyUserEditIdWorkDataMasterEndate = 'kam-master-fin';
  profileMyUserEditIdWorkDataMasterEndateComplete =
    this.profileMyUserEditIdWorkDataComplete + '/kam-master-fin';

  profileMyUserEditIdWorkDataMembershipId = 'kam-numero';
  profileMyUserEditIdWorkDataMembershipIdComplete =
    this.profileMyUserEditIdWorkDataComplete + '/kam-numero';

  settings = 'configuracion';
  settingsComplete = this.profileComplete + '/configuracion';

  personalInfo = 'info-personal';
  personalInfoComplete = this.settingsComplete + '/info-personal';

  workInfo = 'info-laboral';
  workInfoComplete = this.settingsComplete + '/info-laboral';

  changePassword = 'cambiar-contrasena';
  changePasswordComplete = this.settingsComplete + '/cambiar-contrasena';

  userDevices = 'dispositivos';
  userDevicesComplete = this.settingsComplete + '/dispositivos';

  deleteAccount = 'eliminar-cuenta';
  deleteAccountComplete = this.settingsComplete + '/eliminar-cuenta';

  deleteAccountForm = 'formulario';
  deleteAccountFormComplete = this.deleteAccountComplete + '/formulario';

  userPreferences = 'preferencias';
  userPreferencesComplete = this.settingsComplete + '/preferencias';

  userPreferencesContent = 'contenido';
  userPreferencesContentComplete = this.userPreferencesComplete + '/contenido';

  changeLanguage = 'idioma';
  changeLanguageComplete = this.settingsComplete + '/idioma';

  userPreferencesTreatments = 'tratamientos';
  userPreferencesTreatmentsComplete =
    this.userPreferencesComplete + '/tratamientos';

  userPreferencesCommercialCommunications = 'comunicaciones-comerciales';
  userPreferencesCommercialCommunicationsComplete =
    this.userPreferencesComplete + '/comunicaciones-comerciales';

  userPreferencesProducts = 'productos';
  userPreferencesProductsComplete = this.userPreferencesComplete + '/productos';

  profileWorkOcupation = 'profesion';
  profileWorkOcupationComplete = this.workInfoComplete + '/profesion';

  profileWorkMaster = 'master';
  profileWorkMasterComplete = this.workInfoComplete + '/master';

  profileWorkSpecialty = 'especialidad';
  profileWorkSpecialtyComplete = this.workInfoComplete + '/especialidad';

  profileWorkMasterEndate = 'master-fin';
  profileWorkMasterEndateComplete = this.workInfoComplete + '/master-fin';

  profileWorkMembershipId = 'numero';
  profileWorkMembershipIdComplete = this.workInfoComplete + '/numero';

  productsProductCategory = 'categoria';
  productsProductCategoryComplete = this.productsComplete + '/categoria';

  mediaReferences = 'medios';
  mediaReferencesComplete = this.homeComplete + '/medios';

  mediaReference = 'medio';
  mediaReferenceComplete = '/medio';
  mediaReferenceId = ':mediaReferenceId';
  mediaReferenceIdComplete =
    this.mediaReferenceComplete + '/${mediaReferenceId}';

  newss = 'noticias';
  newssComplete = this.homeComplete + '/noticias';

  product = 'producto';
  productComplete = '/producto';
  productId = ':productId';
  productIdComplete = this.productComplete + '/${productId}';

  successStory = 'caso-exito';
  successStoryComplete = this.productIdComplete + '/caso-exito';

  news = 'noticia';
  newsComplete = '/noticia';
  newsId = ':newsId';
  newsIdComplete = this.newsComplete + '/${newsId}';

  freeContent = 'free-content';
  freeContentComplete = '/free-content';
  freeContentId = ':freeContentId';
  freeContentIdComplete = this.freeContentComplete + '/${freeContentId}';

  mexzIberia = 'mexz-iberia';
  mexzIberiaComplete = '/mexz-iberia';
}

export const MERZ_ROUTES: MerzRoutes = new MerzRoutes();
