import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'dnest-core-attach-file-button',
  templateUrl: './attach-file-button.component.html',
  styleUrls: ['./attach-file-button.component.scss']
})
export class AttachFileButtonComponent implements OnInit {
  @Input('text') text: string = "";
  @Input('theme') theme: string = "primary";
  @Input('accepted-files') acceptedFiles: string = "";
  @Input('multiple') multiple: boolean = false;
  @Input('loading') loading: boolean = false;
  @Input('uppercase') uppercase: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  @Output('selected-files') attachFileEvent = new EventEmitter<File>();

  selectedFile(file: any) {
    if (file.target.files.length > 0) {
      const attachedFiles = file.target.files;
      this.attachFileEvent.emit(attachedFiles);
    }
  }

}
