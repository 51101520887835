import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class DetectDeviceService {
	constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

	get isMobile() {
		if (isPlatformBrowser(this.platformId)) return !!navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
    else return false;
	}
	get isTablet() {
		if (isPlatformBrowser(this.platformId)) return !!navigator.userAgent.match(/(iPad)|(Tablet)|(Vivo)|(Tab)|(AOPEN)|(GT)|(Samsung SM)|(Nexus 7)|(Nexus 10)|(Transformer Pad)|(Galaxy Note)/i);
    else return false;

	}
	get isDesktop() {
		if (isPlatformBrowser(this.platformId)) return !navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
    else return false;
	}
}
