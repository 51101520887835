import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Queryset } from "./queryset"
import { LibraryConfigService, LibraryConfig } from '../../config';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { IStorageService } from '../models/interfaces';
import { NotificationService } from '../services/notification/notification.service';
import { CancelScrollService } from '../services/cancel-scroll/cancel-scroll.service';
import { FormManagementService } from '../services/form-management/form-management.service';


@Injectable({
	providedIn: 'root',
})
export class Manager<T extends { id: string }> {
	_loginClientService: any;
	_localStorageService: any;
	_notificationService: any;
	_formManagementService: any;
	protected _queryset: Queryset<T>;
	protected endpoint: string;
	protected responseType: string = '';

	protected readonly _headers = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	};

	constructor(protected http: HttpClient, protected fb: UntypedFormBuilder, @Inject(LibraryConfigService) protected config: LibraryConfig, @Inject(PLATFORM_ID) protected platformId: any) {
		this._queryset = new Queryset<T>(this);
		this.connectServices();
	}

	protected get headers() {
		let result = { ...this._headers };
		if (this.loginService && this.loginService.isLoggedIn()) result = { ...this._headers, ...this.loginService.headerToken };
		return result;
	}

	public get loginService() /* : LoginApiClientService  */ {
		if (!this._loginClientService) {
			if (this.config.loginClientService) {
				this._loginClientService = this.config.loginClientService;
			} else {
				if (this.config.loginClientServiceClass) {
					this._loginClientService = new this.config.loginClientServiceClass(this.http, this.fb, this.config, this.platformId);
				}
			}
		}
		return this._loginClientService;
	}

	public get localStorageService(): IStorageService {
		if (!this._localStorageService) {
			if (this.config.localStorageService) {
				this._localStorageService = this.config.localStorageService;
			} else {
				this._localStorageService = new LocalStorageService(this.platformId);
			}
		}
		return this._localStorageService;
	}

	public get notificationService(): any {
		if (!this._notificationService) {
			if (this.config.notificationService) {
				this._notificationService = this.config.notificationService;
			} else {
				this._notificationService = new NotificationService(new CancelScrollService());
			}
		}
		return this._notificationService;
	}

	public get formManagementService() {
		if (!this._formManagementService) {
			if (this.config.formManagementService) {
				this._formManagementService = this.config.formManagementService;
			} else {
				this._formManagementService = new FormManagementService(this.notificationService);
			}
		}
		return this._formManagementService;
	}

	protected get apiUrl() {
		return this.config.urlBase;
	}

	protected get version() {
		return ``;
	}

	protected get urlBase() {
		const url = this.apiUrl;
		const version = this.version;
		return `${url}${version}`;
	}

	public get endpointURL() {
		return `${this.urlBase}${this.endpoint}`;
	}

	public httpAngular(): HttpClient {
		return this.http;
	}

	protected connectServices() {}

	getOptions(params = {}, item: any = undefined) {
		const options = {
			headers: this.headers,
			params: params,
		};
		if (!!this.responseType) {
			(options as any)['responseType'] = this.responseType;
		}
		if (item && item.toString().endsWith('FormData]')) {
			delete (options as any).headers['Content-Type'];
			delete (options as any).headers['Accept'];
		}
		return options;
	}

	getUrl(idOrSlug: string | undefined = undefined) {
		let url = `${this.endpointURL}/`;
		if (idOrSlug && idOrSlug.toString().length > 0) {
			url = `${this.endpointURL}/${idOrSlug}/`;
		}
		return url;
	}

	create(item: T | FormData, showMessage = false) {
		return this._queryset.create(item, showMessage);
	}

	edit(item: T | { id: string; form: FormData }, showMessage = false) {
		return this._queryset.edit(item, showMessage);
	}

	editAction(item: T | { id: string; form: FormData }, action: string, showMessage = false) {
		return this._queryset.editAction(item, action, showMessage);
	}

	delete(item: T, showMessage = false) {
		return this._queryset.delete(item, showMessage);
	}

	action(item: T, action: string, body: any, showMessage = false) {
		return this._queryset.action(item, action, body, showMessage);
	}

	postQueryParams(item: T, queryParams: {}, showMessage = false) {
		return this._queryset.postQueryParams(item, queryParams, showMessage);
	}

	deleteAction(item: T, action: string, showMessage = false, body = {}) {
		return this._queryset.deleteAction(item, action, showMessage, body);
	}

	actionGet(item: T, action: string, responseType = '') {
		return this._queryset.actionGet(item, action, responseType);
	}

	get(idOrSlug?: string) {
		return this._queryset.get(idOrSlug);
	}

	all() {
		return this._queryset.all();
	}

	filter(params: {}) {
		return this._queryset.filter(params);
	}

	exclude(params: {}) {
		return this._queryset.exclude(params);
	}

	orderBy(fields: any[]) {
		return this._queryset.orderBy(fields);
	}

	pageSize(pageSize: number) {
		return this._queryset.pageSize(pageSize);
	}

	page(page: number) {
		return this._queryset.page(page);
	}
}
