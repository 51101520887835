import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'merz-input-radio-faces',
  templateUrl: './input-radio-faces.component.html',
  styleUrls: ['./input-radio-faces.component.scss'],
})
export class InputRadioFacesComponent extends InputComponent {
  _options: Option[] = [];

  @Input() set options(options) {
    this._options = options;
    this.getSelectedValue();
  }

  get options() {
    return this._options;
  }

  imagesFaces = {
    1: '/assets/svg/faces/face-1.svg',
    2: '/assets/svg/faces/face-2.svg',
    3: '/assets/svg/faces/face-3.svg',
    4: '/assets/svg/faces/face-4.svg',
    5: '/assets/svg/faces/face-5.svg',
  };

  valueSelected = '';

  ngOnInit(): void {
    this.getSelectedValue();
  }

  getSelectedValue() {
    const selectedOption = this.options.find((option) => option.checked);
    if (selectedOption) {
      this.valueSelected = selectedOption.value;
    } else {
      this.valueSelected = '';
    }
  }

  selectOption(selectedValue: any) {
    this.valueSelected = selectedValue.value;
    this.formControl.setValue(selectedValue.value);
  }
}
