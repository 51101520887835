// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  padding-bottom: 40px;
}

@media only screen and (min-width: 1200px) {
  .modal-container {
    overflow: auto;
    margin-top: 0;
    padding: 0 35px 0 0;
    height: calc(100vh - 165px);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/filter-modal/filter-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAKA;EACE;IACE,cAAA;IACA,aAAA;IACA,mBAAA;IACA,2BAAA;EAFF;AACF","sourcesContent":[".modal-container {\n  padding-bottom: 40px;\n}\n\n\n\n\n@media only screen and (min-width: 1200px) {\n  .modal-container {\n    overflow: auto;\n    margin-top: 0;\n    padding: 0 35px 0 0;\n    height: calc(100vh - 165px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
