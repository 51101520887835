import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { enterAnimation, leaveAnimation } from 'src/app/animations/enter';
import { FilterGalleryModalComponent } from '../../modal/filter-gallery-modal/filter-gallery-modal.component';
import { FiltersService } from 'src/app/services/filters-service/filters.service';

@Component({
  selector: 'merz-header-gallery',
  templateUrl: './header-gallery.component.html',
  styleUrls: ['./header-gallery.component.scss']
})
export class HeaderGalleryComponent implements OnInit {
  @Input() href;
	@Input('button-text') buttonText = '';
  @Output('selected') selectedEvent = new EventEmitter<any>();
  @Output('go-back') goBackEvent = new EventEmitter<any>();
  @Output('open-filter') openFilterEvent = new EventEmitter<any>();

	constructor(
    protected navController: NavController,
    protected modalController: ModalController,
    protected filtersService: FiltersService
    ) {
	}
	ngOnInit() {}

	goBack() {
    this.goBackEvent.emit();
    return this.navController.navigateBack(this.href);
  }


  async openFilter(event) {
    this.openFilterEvent.emit();
		const modal = await this.modalController.create({
			component: FilterGalleryModalComponent,
      enterAnimation,
      leaveAnimation,
			cssClass: 'modal-options',
		});

		return await modal.present();
	}

  get filtersIcon(){
    return this.filtersService.isFiltersEmpty
      ? '/assets/svg/options-outline.svg'
      : '/assets/svg/options-outline-mark.svg';
  }
}
