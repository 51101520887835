import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { IStorageService } from '../../models/interfaces';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements IStorageService {
  storage: Storage;

  sessionToken: string = '';
  expirationDate: Date = new Date();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(platformId)) {
      this.storage = localStorage;
    } else {
      this.storage = {
        getItem: (name: string) => { return null},
        setItem: (name: string, value) => {},
        removeItem: (name: string) => {},
        length: 0,
        clear: () => {},
        key: () => { return null}
      };
    }
  }

  getObject(name: string) {
    try {
      return JSON.parse(this.storage.getItem(name)!);
    } catch {
      return undefined;
    }

  }

  saveObject(name: string, value: any) {
    this.storage.setItem(name, JSON.stringify(value));
  }

  deleteObject(name: string) {
    this.storage.removeItem(name);
  }

  deleteSessionData() {}
}
