import { Query } from './query';
import { Manager } from './manager.service';
import { Observable } from 'rxjs';

export class Queryset<T extends { id: string }> {
	query: Query<T>;
	manager: Manager<T>;

	constructor(manager: Manager<T>, query: Query<T> = new Query<T>(manager, {}, {}, [], 20)) {
		this.query = query;
		this.manager = manager;
	}

	private clone(): Queryset<T> {
		return new Queryset<T>(this.manager, this.query.clone());
	}

	create(item: T | FormData, showMessage = false) {
		const clone = this.clone();
		return clone.query.executeCreate(item, showMessage);
	}

	edit(item: T | { id: string; form: FormData }, showMessage = false) {
		const clone = this.clone();
		return clone.query.executeEdit(item, showMessage);
	}

	editAction(item: T | { id: string; form: FormData }, action: string, showMessage = false) {
		const clone = this.clone();
		return clone.query.executeEditAction(item, action, showMessage);
	}

	delete(item: T, showMessage = false) {
		const clone = this.clone();
		return clone.query.executeDelete(item, showMessage);
	}

	action(item: T, action: string, body: any, showMessage = false) {
		const clone = this.clone();
		return clone.query.executeAction(item, action, body, showMessage);
	}

	postQueryParams(item: T, queryParams: {}, showMessage = false) {
		const clone = this.clone();
		return clone.query.executePostItemQueryParams(item, queryParams, showMessage);
	}

	deleteAction(item: T, action: string, showMessage = false, body = {}) {
		const clone = this.clone();
		return clone.query.executeDeleteAction(item, action, showMessage, body);
	}

	actionGet(item: T, action: string, responseType = '') {
		const clone = this.clone();
		return clone.query.executeActionGet(item, action, responseType);
	}

	get(idOrSlug?: string, responseType = undefined) {
		const clone = this.clone();
		return clone.query.executeGet(idOrSlug, responseType);
	}

	all() {
		return this.clone();
	}

	filter(params: {}) {
		const clone = this.clone();
		clone.query.addFilterParams(params);
		return clone;
	}

	exclude(params: {}) {
		const clone = this.clone();
		clone.query.addExcludeParams(params);
		return clone;
	}

	orderBy(fields: any[]) {
		const clone = this.clone();
		clone.query.addOrderByFields(fields);
		return clone;
	}

	pageSize(pageSize: number) {
		const clone = this.clone();
		clone.query.setPageSize(pageSize);
		return clone;
	}

	page(page: number) {
		const clone = this.clone();
		clone.query.setPage(page);
		return clone;
	}

	subscribe(observerSubscribed: any) {
		return new Observable((observer) => {
			this.query.execute().subscribe({
				next: (value) => {
					observer.next(value);
				},
				error: (value) => {
					observer.error(value);
				},
				complete: () => {
					observer.complete();
				},
			});
		}).subscribe(observerSubscribed);
	}
}
