import { Component, Input } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'merz-input-radio-stars',
  templateUrl: './input-radio-stars.component.html',
  styleUrls: ['./input-radio-stars.component.scss'],
})
export class InputRadioStarsComponent extends InputComponent {
  @Input() options: Option[] = [];

  get scoreOrder(){
    return this.options?.find((option) => option.checked)?.order || 0;
  }

  ngOnInit(): void {
  }

  selectOption(optionSelected: Option){
    this.options.forEach((option) => option.checked = false);
    optionSelected.checked = true;
    this.formControl.setValue(optionSelected.value);
  }
}

