import { NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { AuthGuard } from 'projects/dnest-core-angular/src/public-api';
import { MERZ_ROUTES } from './merz-routes';
import { ProductBasicGuardGuard } from './services/guards/product-basic-guard/product-basic-guard.guard';
import { DeleteAccountCausesResolver } from './services/resolvers/delete-account-causes.resolver';
import { EventResolver } from './services/resolvers/event.resolver';
import { FormationResolver } from './services/resolvers/formation.resolver';
import { FreeContentResolver } from './services/resolvers/free-content.resolver';
import { KamUserResolver } from './services/resolvers/kam-user.resolver';
import { KamUsersResolver } from './services/resolvers/kam-users.resolver';
import { KamWorkCenterResolver } from './services/resolvers/kam-workcenter.resolver';
import { KamWorkCentersResolver } from './services/resolvers/kam-workcenters.resolver';
import { newsResolver } from './services/resolvers/news.resolver';
import { NotificationsResolver } from './services/resolvers/notifications.resolver';
import { PlansResolver } from './services/resolvers/plans.resolver';
import { PollResolver } from './services/resolvers/poll.resolver';
import { ProductResolver } from './services/resolvers/product.resolver';
import { SpeakerResolver } from './services/resolvers/speaker.resolver';
import { UserDevicesResolver } from './services/resolvers/user-devices.resolver';
import { UserWorkCenterResolver } from './services/resolvers/user-work-center.resolver';
import { UserWorkCentersResolver } from './services/resolvers/user-work-centers.resolver';
import { UserResolver } from './services/resolvers/user.resolver';
import { VideoResolver } from './services/resolvers/video.resolver';
import { GoToRootGuardService } from './services/guards/go-to-root-guard/go-to-root-guard.service';
import { LanguageResolver } from './services/resolvers/language.resolver';
import { CountriesResolver } from './services/resolvers/countries.resolver';
import { PollFinishResolver } from './services/resolvers/poll-finish.resolver';

const LoginRegisterRoutes: Routes = [];

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: MERZ_ROUTES.welcome,
        loadChildren: () =>
          import('./pages/welcome/welcome.module').then(
            (m) => m.WelcomePageModule
          ),
        canActivate: [GoToRootGuardService], //Si el usuario está logeado a la home
      },
      //LOGIN TWO OPTIONS
      {
        path: MERZ_ROUTES.loginTwoOptions,
        loadChildren: () =>
          import(
            './pages/login/login-two-options/login-two-options.module'
          ).then((m) => m.LoginTwoOptionsPageModule),
        canActivate: [GoToRootGuardService], //Si el usuario está logeado a la home
      },
      //LOGIN PASSWORD
      {
        path: MERZ_ROUTES.login,
        loadChildren: () =>
          import('./pages/login/login/login.module').then(
            (m) => m.LoginPageModule
          ),
        canActivate: [GoToRootGuardService], //Si el usuario está logeado a la home
      },
      //REGISTER EMAIL
      {
        path: MERZ_ROUTES.loginByEmail,
        loadChildren: () =>
          import('./pages/login/login-by-email/login-by-email.module').then(
            (m) => m.LoginByEmailPageModule
          ),
        canActivate: [GoToRootGuardService], //Si el usuario está logeado a la home
      },
      //REGISTER PHONE
      {
        path: MERZ_ROUTES.loginByPhone,
        loadChildren: () =>
          import('./pages/login/login-by-phone/login-by-phone.module').then(
            (m) => m.LoginByPhonePageModule
          ),
        canActivate: [GoToRootGuardService], //Si el usuario está logeado a la home
      },
      //REGISTER
      {
        path: MERZ_ROUTES.register,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/register/register.module').then(
                (m) => m.RegisterPageModule
              ),
            canActivate: [GoToRootGuardService], //Si el usuario está logeado a la home
          },
          {
            path: MERZ_ROUTES.registerPhoneConfirm,
            loadChildren: () =>
              import(
                './pages/register/confirm-phone/confirm-phone.module'
              ).then((m) => m.ConfirmPhonePageModule),
          },
          {
            path: MERZ_ROUTES.registerSelectCountry,
            resolve: {
              countries: CountriesResolver,
            },
            loadChildren: () =>
              import(
                './pages/register/register-select-countries/register-select-countries.module'
              ).then((m) => m.RegisterSelectCountriesPageModule),
          },
          {
            path: MERZ_ROUTES.registerContinueSelection,
            resolve: {
              countries: CountriesResolver
            },
            loadChildren: () =>
              import(
                './pages/register/register-continue-selection/register-continue-selection.module'
              ).then((m) => m.RegisterContinueSelectionPageModule),
          },
          {
            path: MERZ_ROUTES.registerOcupation,
            loadChildren: () =>
              import(
                './pages/register/register-ocupation/register-ocupation.module'
              ).then((m) => m.RegisterOcupationPageModule),
          },
          {
            path: MERZ_ROUTES.registerStatement,
            loadChildren: () =>
              import(
                './pages/register/register-statement/register-statement.module'
              ).then((m) => m.RegisterStatementPageModule),
          },
          {
            path: MERZ_ROUTES.registerSpecialty,
            loadChildren: () =>
              import(
                './pages/register/register-specialty/register-specialty.module'
              ).then((m) => m.RegisterSpecialtyPageModule),
          },
          {
            path: MERZ_ROUTES.registerWorkcenter,
            resolve: {
              kamWorkCenters: KamWorkCentersResolver,
              user: UserResolver,
            },
            loadChildren: () =>
              import(
                './pages/register/register-workcenter/register-workcenter.module'
              ).then((m) => m.RegisterWorkcenterPageModule),
          },
          {
            path: MERZ_ROUTES.registerWorkcenterAdd,
            resolve: {
              user: UserResolver,
            },
            loadChildren: () =>
              import(
                './pages/register/register-workcenter-add/register-workcenter-add.module'
              ).then((m) => m.RegisterWorkcenterAddPageModule),
          },
          {
            path: MERZ_ROUTES.registerMaster,
            loadChildren: () =>
              import(
                './pages/register/register-master/register-master.module'
              ).then((m) => m.RegisterMasterPageModule),
          },
          {
            path: MERZ_ROUTES.registerMasterEndate,
            loadChildren: () =>
              import(
                './pages/register/register-master-endate/register-master-endate.module'
              ).then((m) => m.RegisterMasterEndatePageModule),
          },
          {
            path: MERZ_ROUTES.registerPassword,
            loadChildren: () =>
              import(
                './pages/register/register-password/register-password.module'
              ).then((m) => m.RegisterPasswordPageModule),
          },
          {
            path: MERZ_ROUTES.registerFunction,
            loadChildren: () =>
              import(
                './pages/register/register-function/register-function.module'
              ).then((m) => m.RegisterFunctionPageModule),
          },
          {
            path: MERZ_ROUTES.registerChangePhone,
            loadChildren: () =>
              import(
                './pages/register/register-change-phone/register-change-phone.module'
              ).then((m) => m.RegisterChangePhonePageModule),
          },
          {
            path: MERZ_ROUTES.registerEmail,
            loadChildren: () =>
              import(
                './pages/register/register-email/register-email.module'
              ).then((m) => m.RegisterEmailPageModule),
          },
        ],
      },
      //REGISTER-CODE
      {
        path: MERZ_ROUTES.registerCode,
        loadChildren: () =>
          import('./pages/register-code/register-code.module').then(
            (m) => m.RegisterCodePageModule
          ),
      },
      //forgot
      {
        path: MERZ_ROUTES.forgot,
        loadChildren: () =>
          import('./pages/login/forgot/forgot.module').then(
            (m) => m.ForgotPageModule
          ),
        canActivate: [GoToRootGuardService], //Si el usuario está logeado a la home
      },
      //forgotConfirm
      {
        path: MERZ_ROUTES.forgotConfirm,
        loadChildren: () =>
          import('./pages/login/forgot-confirm/forgot-confirm.module').then(
            (m) => m.ForgotConfirmPageModule
          ),
        canActivate: [GoToRootGuardService], //Si el usuario está logeado a la home
      },
      //LOGGED ROUTES
      {
        path: '',
        resolve: {
          user: UserResolver,
          language: LanguageResolver,
          plans: PlansResolver,
        },
        runGuardsAndResolvers: (
          curr: ActivatedRouteSnapshot,
          future: ActivatedRouteSnapshot
        ) => {
          return false;
        },
        canActivate: [AuthGuard],
        children: [
          //HOME VIEW
          {
            path: MERZ_ROUTES.home,
            loadChildren: () =>
              import('../app/pages/home/home.module').then(
                (m) => m.HomePageModule
              ),
          },
          //FORMATIONS VIEW
          {
            path: MERZ_ROUTES.formation,
            loadChildren: () =>
              import('../app/pages/formation/formation.module').then(
                (m) => m.FormationPageModule
              ),
          },
          //PRODUCTS VIEW
          {
            path: MERZ_ROUTES.products,
            canActivate: [ProductBasicGuardGuard],
            loadChildren: () =>
              import('../app/pages/products/products.module').then(
                (m) => m.ProductsPageModule
              ),
          },
          //EVENTS VIEW
          {
            path: MERZ_ROUTES.events,
            loadChildren: () =>
              import('../app/pages/events/events.module').then(
                (m) => m.EventsPageModule
              ),
          },
          //CONTENTS (GALLERY) DESKTOP
          {
            path: MERZ_ROUTES.contents,
            loadChildren: () =>
              import(
                '../app/pages/gallery-contents-desktop/gallery-contents-desktop.module'
              ).then((m) => m.GalleryContentsDesktopPageModule),
          },
          //VIDEO
          {
            path: MERZ_ROUTES.video,
            children: [
              {
                path: MERZ_ROUTES.videoId,
                resolve: {
                  video: VideoResolver,
                },
                loadChildren: () =>
                  import('./pages/video/video.module').then(
                    (m) => m.VideoPageModule
                  ),
              },
            ],
          },
          //SPEAKER
          {
            path: MERZ_ROUTES.speaker,
            children: [
              {
                path: MERZ_ROUTES.speakerId,
                resolve: {
                  speaker: SpeakerResolver,
                },
                loadChildren: () =>
                  import('./pages/speaker/speaker.module').then(
                    (m) => m.SpeakerPageModule
                  ),
              },
            ],
          },
          //FORMATION ON-LINE
          {
            path: MERZ_ROUTES.formacionOnline,
            children: [
              {
                path: MERZ_ROUTES.formacionOnlineId,
                resolve: {
                  formation: FormationResolver,
                },
                loadChildren: () =>
                  import(
                    './pages/formation-online/formation-online.module'
                  ).then((m) => m.FormationOnlinePageModule),
              },
            ],
          },
          //FORMATION ON-SITE
          {
            path: MERZ_ROUTES.formacionOnsite,
            children: [
              {
                path: MERZ_ROUTES.formacionOnsiteId,
                resolve: {
                  formation: FormationResolver,
                },
                loadChildren: () =>
                  import(
                    './pages/formation-onsite/formation-onsite.module'
                  ).then((m) => m.FormationOnsitePageModule),
              },
            ],
          },
          //EVENT
          {
            path: MERZ_ROUTES.event,
            children: [
              {
                path: MERZ_ROUTES.eventId,
                resolve: {
                  event: EventResolver,
                },
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import('./pages/event/event.module').then(
                        (m) => m.EventPageModule
                      ),
                  },
                  {
                    path: MERZ_ROUTES.eventSchedule,
                    loadChildren: () =>
                      import(
                        './pages/event/event-schedule/event-schedule.module'
                      ).then((m) => m.EventSchedulePageModule),
                  },
                  {
                    path: MERZ_ROUTES.eventActionsMerz,
                    loadChildren: () =>
                      import(
                        './pages/event/event-actions-merz/event-actions-merz.module'
                      ).then((m) => m.EventActionsMerzPageModule),
                  },
                  {
                    path: MERZ_ROUTES.eventSocialNetworks,
                    loadChildren: () =>
                      import(
                        './pages/event/event-social-networks/event-social-networks.module'
                      ).then((m) => m.EventSocialNetworksPageModule),
                  },
                  {
                    path: MERZ_ROUTES.eventMoves,
                    loadChildren: () =>
                      import(
                        './pages/event/event-moves/event-moves.module'
                      ).then((m) => m.EventMovesPageModule),
                  },
                  {
                    path: MERZ_ROUTES.eventHotels,
                    loadChildren: () =>
                      import(
                        './pages/event/event-hotels/event-hotels.module'
                      ).then((m) => m.EventHotelsPageModule),
                  },
                  {
                    path: MERZ_ROUTES.eventFlights,
                    children: [
                      {
                        path: '',
                        loadChildren: () =>
                          import(
                            './pages/event/event-flights/event-flights.module'
                          ).then((m) => m.EventFlightsPageModule),
                      },
                      {
                        path: MERZ_ROUTES.eventFlight,
                        loadChildren: () =>
                          import(
                            './pages/event/event-flights/event-flight/event-flight.module'
                          ).then((m) => m.EventFlightPageModule),
                      },
                    ],
                  },
                  {
                    path: MERZ_ROUTES.eventInscriptions,
                    children: [
                      {
                        path: '',
                        loadChildren: () =>
                          import(
                            './pages/event/event-inscriptions/event-inscriptions.module'
                          ).then((m) => m.EventInscriptionsPageModule),
                      },
                      {
                        path: MERZ_ROUTES.eventInscription,
                        loadChildren: () =>
                          import(
                            './pages/event/event-inscriptions/event-inscription/event-inscription.module'
                          ).then((m) => m.EventInscriptionPageModule),
                      },
                    ],
                  },
                  {
                    path: MERZ_ROUTES.eventFoods,
                    children: [
                      {
                        path: '',
                        loadChildren: () =>
                          import(
                            './pages/event/event-foods/event-foods.module'
                          ).then((m) => m.EventFoodsPageModule),
                      },
                      {
                        path: MERZ_ROUTES.eventFoodDetail,
                        loadChildren: () =>
                          import(
                            './pages/event/event-foods/event-food-detail/event-food-detail.module'
                          ).then((m) => m.EventFoodDetailPageModule),
                      },
                    ],
                  },
                  {
                    path: MERZ_ROUTES.eventDinners,
                    children: [
                      {
                        path: '',
                        loadChildren: () =>
                          import(
                            './pages/event/event-dinners/event-dinners.module'
                          ).then((m) => m.EventDinnersPageModule),
                      },
                      {
                        path: MERZ_ROUTES.eventDinnerDetail,
                        loadChildren: () =>
                          import(
                            './pages/event/event-dinners/event-dinner-detail/event-dinner-detail.module'
                          ).then((m) => m.EventDinnerDetailPageModule),
                      },
                    ],
                  },
                  {
                    path: MERZ_ROUTES.eventSpeakers,
                    loadChildren: () =>
                      import(
                        './pages/event/event-speakers/event-speakers.module'
                      ).then((m) => m.EventSpeakersPageModule),
                  },
                  {
                    path: MERZ_ROUTES.eventCovid,
                    loadChildren: () =>
                      import(
                        './pages/event/event-covid/event-covid.module'
                      ).then((m) => m.EventCovidPageModule),
                  },
                  {
                    path: MERZ_ROUTES.eventParkings,
                    loadChildren: () =>
                      import(
                        './pages/event/event-parkings/event-parkings.module'
                      ).then((m) => m.EventParkingsPageModule),
                  },
                  {
                    path: MERZ_ROUTES.eventDocuments,
                    children: [
                      {
                        path: '',
                        loadChildren: () =>
                          import(
                            './pages/event/event-documents/event-documents.module'
                          ).then((m) => m.EventDocumentsPageModule),
                      },
                      {
                        path: MERZ_ROUTES.eventDocumentDetail,
                        loadChildren: () =>
                          import(
                            './pages/event/event-documents/event-documents-detail/event-document-detail.module'
                          ).then((m) => m.EventDocumentDetailPageModule),
                      },
                    ],
                  },
                ],
              },
            ],
          },

          //WORKCENTER
          {
            path: MERZ_ROUTES.workCenter,
            children: [
              {
                path: MERZ_ROUTES.workCenterNew,
                loadChildren: () =>
                  import(
                    './pages/work-center/work-center-new/work-center-new.module'
                  ).then((m) => m.WorkCenterNewPageModule),
              },
              {
                path: MERZ_ROUTES.workCenterId,
                resolve: {
                  workCenter: UserWorkCenterResolver,
                },
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import('./pages/work-center/work-center.module').then(
                        (m) => m.WorkCenterPageModule
                      ),
                  },
                  {
                    path: MERZ_ROUTES.workCenterName,
                    loadChildren: () =>
                      import(
                        './pages/work-center/work-center-edit-name/work-center-edit-name.module'
                      ).then((m) => m.WorkCenterEditNamePageModule),
                  },
                  {
                    path: MERZ_ROUTES.workCenterAddress,
                    loadChildren: () =>
                      import(
                        './pages/work-center/work-center-edit-address/work-center-edit-address.module'
                      ).then((m) => m.WorkCenterEditAddressPageModule),
                  },
                  {
                    path: MERZ_ROUTES.workCenterPhone,
                    loadChildren: () =>
                      import(
                        './pages/work-center/work-center-edit-phone/work-center-edit-phone.module'
                      ).then((m) => m.WorkCenterEditPhonePageModule),
                  },
                ],
              },
            ],
          },
          //KAM WORKCENTER
          {
            path: MERZ_ROUTES.kamWorkCenter,
            children: [
              {
                path: MERZ_ROUTES.kamWorkCenterId,
                resolve: {
                  kamWorkCenter: KamWorkCenterResolver,
                },
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import(
                        './pages/kam-workcenter/kam-workcenter.module'
                      ).then((m) => m.KamWorkcenterPageModule),
                  },
                  {
                    path: MERZ_ROUTES.kamWorkCenterAddress,
                    loadChildren: () =>
                      import(
                        './pages/kam-workcenter/kam-workcenter-edit-address/kam-workcenter-edit-address.module'
                      ).then((m) => m.KamWorkcenterEditAddressPageModule),
                  },
                  {
                    path: MERZ_ROUTES.kamWorkCenterName,
                    loadChildren: () =>
                      import(
                        './pages/kam-workcenter/kam-workcenter-edit-name/kam-workcenter-edit-name.module'
                      ).then((m) => m.KamWorkcenterEditNamePageModule),
                  },
                  {
                    path: MERZ_ROUTES.kamWorkCenterPhone,
                    loadChildren: () =>
                      import(
                        './pages/kam-workcenter/kam-workcenter-edit-phone/kam-workcenter-edit-phone.module'
                      ).then((m) => m.KamWorkcenterEditPhonePageModule),
                  },
                ],
              },
            ],
          },
          //PROFILE
          {
            path: MERZ_ROUTES.profile,
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./pages/profile/profile.module').then(
                    (m) => m.ProfilePageModule
                  ),
              },
              //KAM MY USERS
              {
                path: MERZ_ROUTES.myUsers,
                resolve: {
                  kamUsers: KamUsersResolver,
                },
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import(
                        './pages/profile/profile-my-users/profile-my-users.module'
                      ).then((m) => m.ProfileMyUsersPageModule),
                  },
                  //NEW USER
                  {
                    path: MERZ_ROUTES.profileMyUserNew,
                    loadChildren: () =>
                      import(
                        './pages/profile/profile-my-users/my-users-new-user/my-users-new-user.module'
                      ).then((m) => m.MyUsersNewUserPageModule),
                  },
                  //SEARCH USERS
                  {
                    path: MERZ_ROUTES.profileMyUsersSearch,
                    loadChildren: () =>
                      import(
                        './pages/profile/profile-my-users/my-users-search/my-users-search.module'
                      ).then((m) => m.MyUsersSearchPageModule),
                  },
                  //EDIT USER
                  {
                    path: MERZ_ROUTES.profileMyUserEdit,
                    children: [
                      {
                        path: MERZ_ROUTES.profileMyUserEditId,
                        resolve: {
                          kamUser: KamUserResolver,
                        },
                        runGuardsAndResolvers: 'always',
                        children: [
                          {
                            path: '',
                            loadChildren: () =>
                              import(
                                './pages/profile/profile-my-users/my-users-edit-user/my-users-edit-user.module'
                              ).then((m) => m.MyUsersEditUserPageModule),
                          },
                          {
                            path: MERZ_ROUTES.profileMyUserEditIdVeeva,
                            loadChildren: () =>
                              import(
                                './pages/profile/profile-my-users/my-users-edit-user/edit-user-veeva/edit-user-veeva.module'
                              ).then((m) => m.EditUserVeevaPageModule),
                          },
                          {
                            path: MERZ_ROUTES.profileMyUserEditOceInstitution,
                            loadChildren: () =>
                              import(
                                './pages/profile/profile-my-users/my-users-edit-user/edit-user-oce-id-institution/edit-user-oce-id-institution.module'
                              ).then(
                                (m) => m.EditUserOceIdInstitutionPageModule
                              ),
                          },
                          {
                            path: MERZ_ROUTES.profileMyUserEditEdit,
                            loadChildren: () =>
                              import(
                                './pages/profile/profile-my-users/my-users-edit-user/edit-user-edit/edit-user-edit.module'
                              ).then((m) => m.EditUserEditPageModule),
                          },
                          {
                            path: MERZ_ROUTES.profileMyUserEditIdPersonalData,
                            loadChildren: () =>
                              import(
                                './pages/profile/profile-my-users/my-users-edit-user/edit-user-personal-data/edit-user-personal-data.module'
                              ).then((m) => m.EditUserPersonalDataPageModule),
                          },
                          {
                            path: MERZ_ROUTES.profileMyUserEditIdWorkData,
                            children: [
                              {
                                path: '',
                                loadChildren: () =>
                                  import(
                                    './pages/profile/profile-my-users/my-users-edit-user/edit-user-work-data/edit-user-work-data.module'
                                  ).then((m) => m.EditUserWorkDataPageModule),
                              },
                              {
                                path: MERZ_ROUTES.profileMyUserEditIdWorkDataOcupation,
                                loadChildren: () =>
                                  import(
                                    './pages/profile/profile-my-users/my-users-edit-user/edit-user-work-data/edit-user-work-data-ocupation/edit-user-work-data-ocupation.module'
                                  ).then(
                                    (m) => m.EditUserWorkDataOcupationPageModule
                                  ),
                              },
                              {
                                path: MERZ_ROUTES.profileMyUserEditIdWorkDataMaster,
                                loadChildren: () =>
                                  import(
                                    './pages/profile/profile-my-users/my-users-edit-user/edit-user-work-data/edit-user-work-data-master/edit-user-work-data-master.module'
                                  ).then(
                                    (m) => m.EditUserWorkDataMasterPageModule
                                  ),
                              },
                              {
                                path: MERZ_ROUTES.profileMyUserEditIdWorkDataMasterEndate,
                                loadChildren: () =>
                                  import(
                                    './pages/profile/profile-my-users/my-users-edit-user/edit-user-work-data/edit-user-work-data-master-endate/edit-user-work-data-master-endate.module'
                                  ).then(
                                    (m) =>
                                      m.EditUserWorkDataMasterEndatePageModule
                                  ),
                              },
                              {
                                path: MERZ_ROUTES.profileMyUserEditIdWorkDataMembershipId,
                                loadChildren: () =>
                                  import(
                                    './pages/profile/profile-my-users/my-users-edit-user/edit-user-work-data/edit-user-work-data-membership-id/edit-user-work-data-membership-id.module'
                                  ).then(
                                    (m) =>
                                      m.EditUserWorkDataMembershipIdPageModule
                                  ),
                              },
                              {
                                path: MERZ_ROUTES.profileMyUserEditIdWorkDataSpecialty,
                                loadChildren: () =>
                                  import(
                                    './pages/profile/profile-my-users/my-users-edit-user/edit-user-work-data/edit-user-work-data-specialty/edit-user-work-data-specialty.module'
                                  ).then(
                                    (m) => m.EditUserWorkDataSpecialtyPageModule
                                  ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              //KAM WORK CENTERS
              {
                path: MERZ_ROUTES.kamWorkCenters,
                children: [
                  {
                    path: '',
                    resolve: {
                      kamWorkCenters: KamWorkCentersResolver,
                    },
                    loadChildren: () =>
                      import(
                        './pages/profile/profile-kam-work-centers/profile-kam-work-centers.module'
                      ).then((m) => m.ProfileKamWorkCentersPageModule),
                  },
                  {
                    path: MERZ_ROUTES.kamWorkCentersNew,
                    loadChildren: () =>
                      import(
                        './pages/profile/profile-kam-work-centers/profile-kam-new-workcenter/profile-kam-new-workcenter.module'
                      ).then((m) => m.ProfileKamNewWorkcenterPageModule),
                  },
                ],
              },
              // WORK CENTERS
              {
                path: MERZ_ROUTES.workCenters,
                resolve: {
                  workCenters: UserWorkCentersResolver,
                },
                loadChildren: () =>
                  import(
                    './pages/profile/profile-work-centers/profile-work-centers.module'
                  ).then((m) => m.ProfileWorkCentersPageModule),
              },
              // FAVORITES
              {
                path: MERZ_ROUTES.favorites,
                loadChildren: () =>
                  import(
                    './pages/profile/profile-favorites/profile-favorites.module'
                  ).then((m) => m.ProfileFavoritesPageModule),
              },
              // COURSES
              {
                path: MERZ_ROUTES.profileCourses,
                loadChildren: () =>
                  import(
                    './pages/profile/profile-courses/profile-courses.module'
                  ).then((m) => m.ProfileCoursesPageModule),
              },
              // CONTACT MERZ
              {
                path: MERZ_ROUTES.contact,
                loadChildren: () =>
                  import(
                    './pages/profile/profile-contact/profile-contact.module'
                  ).then((m) => m.ProfileContactPageModule),
              },
              // SETTINGS
              {
                path: MERZ_ROUTES.settings,
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import(
                        './pages/profile/profile-settings/profile-settings.module'
                      ).then((m) => m.ProfileSettingsPageModule),
                  },
                  {
                    path: MERZ_ROUTES.personalInfo,
                    resolve: {
                      user: UserResolver,
                      countries: CountriesResolver,
                    },
                    loadChildren: () =>
                      import(
                        './pages/profile/profile-settings/profile-settings-personal-info/profile-settings-personal-info.module'
                      ).then((m) => m.ProfileSettingsPersonalInfoPageModule),
                  },
                  {
                    path: MERZ_ROUTES.workInfo,
                    resolve: {
                      user: UserResolver,
                    },
                    runGuardsAndResolvers: 'always',
                    children: [
                      {
                        path: '',
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-work-info/profile-settings-work-info.module'
                          ).then((m) => m.ProfileSettingsWorkInfoPageModule),
                      },
                      {
                        path: MERZ_ROUTES.profileWorkOcupation,
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-work-info/work-info-ocupation/work-info-ocupation.module'
                          ).then((m) => m.WorkInfoOcupationPageModule),
                      },
                      {
                        path: MERZ_ROUTES.profileWorkMaster,
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-work-info/work-info-master/work-info-master.module'
                          ).then((m) => m.WorkInfoMasterPageModule),
                      },
                      {
                        path: MERZ_ROUTES.profileWorkSpecialty,
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-work-info/work-info-specialty/work-info-specialty.module'
                          ).then((m) => m.WorkInfoSpecialtyPageModule),
                      },
                      {
                        path: MERZ_ROUTES.profileWorkMasterEndate,
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-work-info/work-info-master-endate/work-info-master-endate.module'
                          ).then((m) => m.WorkInfoMasterEndatePageModule),
                      },
                      {
                        path: MERZ_ROUTES.profileWorkMembershipId,
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-work-info/work-info-merbership-id/work-info-merbership-id.module'
                          ).then((m) => m.WorkInfoMerbershipIdPageModule),
                      },
                    ],
                  },
                  {
                    path: MERZ_ROUTES.changePassword,
                    loadChildren: () =>
                      import(
                        './pages/profile/profile-settings/profile-settings-change-password/profile-settings-change-password.module'
                      ).then((m) => m.ProfileSettingsChangePasswordPageModule),
                  },
                  {
                    path: MERZ_ROUTES.userDevices,
                    resolve: {
                      userDevices: UserDevicesResolver,
                    },
                    loadChildren: () =>
                      import(
                        './pages/profile/profile-settings/profile-settings-user-device/profile-settings-user-device.module'
                      ).then((m) => m.ProfileSettingsUserDevicePageModule),
                  },
                  {
                    path: MERZ_ROUTES.userPreferences,
                    children: [
                      {
                        path: '',
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-user-preferences/profile-settings-user-preferences.module'
                          ).then(
                            (m) => m.ProfileSettingsUserPreferencesPageModule
                          ),
                      },
                      {
                        path: MERZ_ROUTES.userPreferencesContent,
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-user-preferences/user-preferences-content/user-preferences-content.module'
                          ).then((m) => m.UserPreferencesContentPageModule),
                      },
                      {
                        path: MERZ_ROUTES.userPreferencesTreatments,
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-user-preferences/user-preferences-treatments/user-preferences-treatments.module'
                          ).then((m) => m.UserPreferencesTreatmentsPageModule),
                      },
                      {
                        path: MERZ_ROUTES.userPreferencesProducts,
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-user-preferences/user-preferences-products/user-preferences-products.module'
                          ).then((m) => m.UserPreferencesProductsPageModule),
                      },
                      {
                        path: MERZ_ROUTES.userPreferencesCommercialCommunications,
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-user-preferences/user-preferences-commercial-communication/user-preferences-commercial-communication.module'
                          ).then(
                            (m) =>
                              m.UserPreferencesCommercialCommunicationPageModule
                          ),
                      },
                    ],
                  },
                  {
                    path: MERZ_ROUTES.deleteAccount,
                    children: [
                      {
                        path: '',
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-delete-account/profile-settings-delete-account.module'
                          ).then(
                            (m) => m.ProfileSettingsDeleteAccountPageModule
                          ),
                      },
                      {
                        path: MERZ_ROUTES.deleteAccountForm,
                        resolve: {
                          deleteAccountCauses: DeleteAccountCausesResolver,
                        },
                        loadChildren: () =>
                          import(
                            './pages/profile/profile-settings/profile-settings-delete-account/profile-settings-delete-account-form/profile-settings-delete-account-form.module'
                          ).then(
                            (m) => m.ProfileSettingsDeleteAccountFormPageModule
                          ),
                      },
                    ],
                  },
                  {
                    path: MERZ_ROUTES.changeLanguage,
                    loadChildren: () =>
                      import(
                        './pages/profile/profile-settings/profile-settings-change-language/profile-settings-change-language.module'
                      ).then((m) => m.ProfileSettingsChangeLanguagePageModule),
                  },
                ],
              },
            ],
          },
          //POLL
          {
            path: MERZ_ROUTES.poll,
            children: [
              {
                path: MERZ_ROUTES.pollId,
                children: [
                  {
                    path: '',
                    resolve: {
                      poll: PollResolver,
                    },
                    loadChildren: () =>
                      import('./pages/poll/poll.module').then((m) => m.PollPageModule),
                  },
                  {
                    path: MERZ_ROUTES.pollFinish,
                    resolve: {
                      pollFinish: PollFinishResolver,
                    },
                    loadChildren: () =>
                      import('./pages/poll/poll-finish/poll-finish.module').then( m => m.PollFinishPageModule)
                  }
                ],
              },
            ],
          },
          //PREFERENCES
          {
            path: MERZ_ROUTES.preferences,
            loadChildren: () =>
              import('./pages/preferences/preferences.module').then(
                (m) => m.PreferencesPageModule
              ),
          },
          //COMPLETE PROFILE
          {
            path: MERZ_ROUTES.completeProfile,
            loadChildren: () =>
              import('./pages/complete-profile/complete-profile.module').then(
                (m) => m.CompleteProfilePageModule
              ),
          },
          //PRODUCT
          {
            path: MERZ_ROUTES.product,
            canActivate: [ProductBasicGuardGuard],
            children: [
              {
                path: MERZ_ROUTES.productId,
                resolve: {
                  product: ProductResolver,
                },
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import('./pages/product/product.module').then(
                        (m) => m.ProductPageModule
                      ),
                  },
                  {
                    path: MERZ_ROUTES.successStory,
                    loadChildren: () =>
                      import(
                        './pages/product/product-story/product-story.module'
                      ).then((m) => m.ProductStoryPageModule),
                  },
                ],
              },
            ],
          },
          //FREE-CONTENT
          {
            path: MERZ_ROUTES.freeContent,
            children: [
              {
                path: MERZ_ROUTES.freeContentId,
                resolve: {
                  freeContent: FreeContentResolver,
                },
                loadChildren: () =>
                  import('./pages/free-content/free-content.module').then(
                    (m) => m.FreeContentPageModule
                  ),
              },
            ],
          },
          //NEWS
          {
            path: MERZ_ROUTES.news,
            children: [
              {
                path: MERZ_ROUTES.newsId,
                resolve: {
                  news: newsResolver,
                },
                loadChildren: () =>
                  import('./pages/news/news.module').then(
                    (m) => m.NewsPageModule
                  ),
              },
            ],
          },
          //NOTIFICATIONS
          {
            path: MERZ_ROUTES.notifications,
            resolve: {
              notifications: NotificationsResolver,
            },
            runGuardsAndResolvers: 'always',
            loadChildren: () =>
              import('./pages/notifications/notifications.module').then(
                (m) => m.NotificationsPageModule
              ),
          },
          //STATIC PAGE
          {
            path: MERZ_ROUTES.static,
            loadChildren: () =>
              import('./pages/static/static.module').then(
                (m) => m.StaticPageModule
              ),
          },
          //MEDIA REFERENCE DETAIL
          {
            path: MERZ_ROUTES.mediaReference,
            children: [
              {
                path: MERZ_ROUTES.mediaReferenceId,
                loadChildren: () =>
                  import(
                    './pages/home/home-media-reference-detail/home-media-reference-detail.module'
                  ).then((m) => m.HomeMediaReferenceDetailPageModule),
              },
            ],
          },
          //MEXZ IBERIA
          {
            path: MERZ_ROUTES.mexzIberia,
            loadChildren: () =>
              import('./pages/mexz-iberia/mexz-iberia.module').then(
                (m) => m.MexzIberiaPageModule
              ),
          },
          {
            path: '',
            redirectTo: MERZ_ROUTES.home,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: '',
        redirectTo: MERZ_ROUTES.welcomeComplete,
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: MERZ_ROUTES.homeComplete,
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
