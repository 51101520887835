import { Component, Input, OnInit } from '@angular/core';
import { ProductCategory } from 'src/app/models/product-category';

@Component({
  selector: 'merz-product-category-card',
  templateUrl: './product-category-card.component.html',
  styleUrls: ['./product-category-card.component.scss']
})
export class ProductCategoryCardComponent implements OnInit {
  @Input('product-category') productCategory: ProductCategory;
  constructor() { }

  ngOnInit(): void {
  }

}
