import { Base } from './base';
import { Document } from './document';
import { Speaker } from './speaker';
import { VideoHighlight } from './video-highlight';

export class Video extends Base {
  createdDate?: string;
  duration?: number;
  image?: string;
  name?: string;
  speaker?: Speaker;
  speakers?: Speaker[];
  relatedVideos?: Video[];
  favorite?: boolean;
  finished?: boolean;
  favorites?: number;
  score?: number;
  likes?: number;
  scoreTotal?: number;
  shares?: number;
  accessLevel?: string;
  zone?: string;
  type?: string;
  description?: string;
  updatedDate?: string;
  url?: string;

  products?: any[];
  videoHighlights?: VideoHighlight[];

  component?: any;
  treatment?: any;

  currentPosition?: number;

  canSee?: boolean;
  planName?: string;
  vote?: number;

  buttonAction?: string;
  buttonActionValue?: string;

  disclaimer?: boolean;
  userAcceptDisclaimer?: boolean;

  documents?: Document[];

  verticalImage?: string;
}
