import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'merz-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {
  @Input() event: Event;
  @Input() small = false;
  constructor() { }

  ngOnInit(): void {
  }

  get seePremiumTag(){
    return this.event?.canSee && (this.event?.accessLevel==='premium' || this.event?.accessLevel==='student');
  }
}
