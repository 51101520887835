import { Component, OnInit } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent extends InputComponent {
}

