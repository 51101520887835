// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}

ion-card {
  display: flex;
  padding: 6px 14px 6px 6px;
  margin-top: 0;
}
ion-card ion-thumbnail {
  --size: 114px;
  --border-radius: 5px;
  background: var(--ion-color-background);
  min-width: 114px;
  margin-right: 15px;
}
ion-card ion-card-content {
  padding: 5px 0 5px;
}
ion-card ion-card-content .margin5 {
  margin-bottom: 2px;
}
ion-card ion-card-content .ellipsis {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/horizontal-product-card/horizontal-product-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;;AAGA;EACE,aAAA;EACA,yBAAA;EACA,aAAA;AAAF;AAEE;EACA,aAAA;EACA,oBAAA;EACA,uCAAA;EACE,gBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAII;EACE,qBAAA;EACA,4BAAA;EACA,oBAAA;EACA,gBAAA;EACA,iBAAA;AAFN","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n}\n\n\nion-card {\n  display: flex;\n  padding: 6px 14px 6px 6px;\n  margin-top: 0;\n\n  ion-thumbnail {\n\t\t--size: 114px;\n\t\t--border-radius: 5px;\n\t\tbackground: var(--ion-color-background);\n    min-width: 114px;\n    margin-right: 15px;\n\t}\n\n  ion-card-content {\n    padding: 5px 0 5px;\n\n    .margin5 {\n      margin-bottom: 2px;\n    }\n\n    .ellipsis {\n      -webkit-line-clamp: 4;\n      -webkit-box-orient: vertical;\n      display: -webkit-box;\n      overflow: hidden;\n      line-height: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
