import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'merz-time-small-block',
  templateUrl: './time-small-block.component.html',
  styleUrls: ['./time-small-block.component.scss']
})
export class TimeSmallBlockComponent implements OnInit {
  @Input() duration = 0;
  constructor() { }

  ngOnInit(): void {
  }

  get time() {
    if (!this.duration) {
      return '-';
    }
    if(this.duration < 3600){
      return new Date(this.duration*1000).toISOString().substr(14,5);
    }
    else {
      return new Date(this.duration*1000).toISOString().substr(11,8);
    }
  }
}
