import { Component, Input, OnInit } from '@angular/core';
import { News } from 'src/app/models/news';

@Component({
  selector: 'merz-news-short-card',
  templateUrl: './news-short-card.component.html',
  styleUrls: ['./news-short-card.component.scss'],
})
export class NewsShortCardComponent implements OnInit {
  @Input() news: News;
  @Input() large = false;
  constructor() { }

  ngOnInit() { }

  // get time(){
  //   return `${this.timeMin} min`;
  // }

  // get timeMin() {
  //   if(!this.news?.readingTime){
  //     return '-';
  //   }
  //   const time = new Date(this.news?.readingTime*1000).toISOString();
  //   if(this.news?.readingTime < 3600){
  //     return Number(time.slice(14,16));
  //   }
  //   else {
  //     return Number(time.slice(11,13))*60 + Number(time.slice(14,16)) ;
  //   }
  // }
}
