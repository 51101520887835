import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { VideoCardComponent } from './video-card/video-card.component';
import { IonicModule } from '@ionic/angular';
import { AvatarNameSectionModule } from 'src/app/sections/avatar-name-section/avatar-name-section.module';
import { TimeSmallBlockModule } from 'src/app/sections/time-small-block/time-small-block.module';
import { FormationCardComponent } from './formation-card/formation-card.component';
import { TextModule } from '../text/text.module';
import { DateCardBoxModule } from '../date-card-box/date-card-box.module';
import { ShortVideoCardComponent } from './short-video-card/short-video-card.component';
import { SpeakerCardComponent } from './speaker-card/speaker-card.component';
import { LateralVideoCardComponent } from './lateral-video-card/lateral-video-card.component';
import { ProductCategoryCardComponent } from './product-category-card/product-category-card.component';
import { HorizontalProductCardComponent } from './horizontal-product-card/horizontal-product-card.component';
import { HorizontalFormationCardComponent } from './horizontal-formation-card/horizontal-formation-card.component';
import { EventCardComponent } from './event-card/event-card.component';
import { PremiumTagModule } from '../premium-tag/premium-tag.module';
import { EventFormationCardComponent } from './event-formation-card/event-formation-card.component';
import { HorizontalEventCardComponent } from './horizontal-event-card/horizontal-event-card.component';
import { FavoriteVideoCardComponent } from './favorite-video-card/favorite-video-card.component';
import { ProfileCourseCardComponent } from './profile-course-card/profile-course-card.component';
import { WorkCenterCardComponent } from './work-center-card/work-center-card.component';
import { GalleryCardComponent } from './gallery-card/gallery-card.component';
import { NewsShortCardComponent } from './news-short-card/news-short-card.component';
import { MediaReferenceShortCardComponent } from './media-reference-short-card/media-reference-short-card.component';
import { PhaseCardComponent } from './phase-card/phase-card.component';
import { FileCardComponent } from './file-card/file-card.component';
import { SuccessStoryCardComponent } from './success-story-card/success-story-card.component';
import { ProfilePlanCardComponent } from './profile-plan-card/profile-plan-card.component';
import { ButtonModule } from '../button/button.module';
import { CanSeePlanTagModule } from '../can-see-plan-tag/can-see-plan-tag.module';
import { SpeakersSmallBlockModule } from 'src/app/sections/speakers-small-block/speakers-small-block.module';
import { FreeContentCardComponent } from './free-content-card/free-content-card.component';
import { FreeTagComponent } from '../free-tag/free-tag.component';
import { GalleryByProductoCardComponent } from './gallery-by-producto-card/gallery-by-producto-card.component';
import { KamUserCardComponent } from './kam-user-card/kam-user-card.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { EventHighlightCardComponent } from './event-highlight-card/event-highlight-card.component';
import { MbaFormationCardComponent } from './mba-formation-card/mba-formation-card.component';
import { VideoTopCardComponent } from './video-top-card/video-top-card.component';
import { SupportProductCardComponent } from './support-product-card/support-product-card.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    VideoCardComponent,
    FormationCardComponent,
    ShortVideoCardComponent,
    SpeakerCardComponent,
    LateralVideoCardComponent,
    ProductCategoryCardComponent,
    HorizontalProductCardComponent,
    HorizontalFormationCardComponent,
    EventCardComponent,
    EventFormationCardComponent,
    HorizontalEventCardComponent,
    FavoriteVideoCardComponent,
    ProfileCourseCardComponent,
    WorkCenterCardComponent,
    GalleryCardComponent,
    NewsShortCardComponent,
    MediaReferenceShortCardComponent,
    PhaseCardComponent,
    FileCardComponent,
    SuccessStoryCardComponent,
    ProfilePlanCardComponent,
    FreeContentCardComponent,
    FreeTagComponent,
    GalleryByProductoCardComponent,
    KamUserCardComponent,
    ProductCardComponent,
    EventHighlightCardComponent,
    MbaFormationCardComponent,
    VideoTopCardComponent,
    SupportProductCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    AvatarNameSectionModule,
    TimeSmallBlockModule,
    TextModule,
    DateCardBoxModule,
    PremiumTagModule,
    ButtonModule,
    CanSeePlanTagModule,
    SpeakersSmallBlockModule,
    TranslateModule,
    NgOptimizedImage,
  ],
  exports: [
    VideoCardComponent,
    FormationCardComponent,
    ShortVideoCardComponent,
    SpeakerCardComponent,
    LateralVideoCardComponent,
    ProductCategoryCardComponent,
    HorizontalProductCardComponent,
    HorizontalFormationCardComponent,
    EventCardComponent,
    EventFormationCardComponent,
    HorizontalEventCardComponent,
    FavoriteVideoCardComponent,
    ProfileCourseCardComponent,
    WorkCenterCardComponent,
    GalleryCardComponent,
    NewsShortCardComponent,
    MediaReferenceShortCardComponent,
    PhaseCardComponent,
    FileCardComponent,
    SuccessStoryCardComponent,
    ProfilePlanCardComponent,
    FreeContentCardComponent,
    FreeTagComponent,
    GalleryByProductoCardComponent,
    KamUserCardComponent,
    ProductCardComponent,
    EventHighlightCardComponent,
    MbaFormationCardComponent,
    VideoTopCardComponent,
    SupportProductCardComponent,
  ],
})
export class CardModule {}
