import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Filter } from '../../models/filter/filter';
import { FilterEmitComponent } from '../../models/filter/filter-component';

@Component({
	selector: 'dnest-core-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class InputComponent extends FilterEmitComponent implements OnInit, OnChanges {
	/**
	 * Primary by default
	 */
	@Input('theme') theme: string = 'primary';

	@Input('type') inputType = 'text';
	@Input('input-controller') inputController: UntypedFormControl = new UntypedFormControl('');
	@Input('filter') override filter: any = '';
	@Input('title') title = '';
	@Input('placeholder') placeholder = '';
	@Input('autocomplete') autocomplete = '';
	@Input('enable') enable = true;
	@Input('required') required: boolean = false;
	@Input('min') min: any;
	@Input('max') max: any;
  @Input('list') list: string = '';

	@Input('margin-left-icon') marginLeftIcon = 12;
	@Input('icon-size') iconSize = 24;
	@Input('margin-left') marginLeft = 7;
	@Input('margin-right') marginRight = 15;
	@Input('margin-top-clone') marginTopClone = -15;

	@Input('autofocus') autofocus = false;
	@Input('focused') focused: boolean = false;
	@Input('has-hint') hasHint: boolean = false;
	@Input('hints') hints: string[] = [];
	@Input('read-only') readOnly: boolean = false;

	@Output('on-enter') onEnterEvent = new EventEmitter<any>();
	@Output('on-focus') onFocusEvent = new EventEmitter<any>();
	@Output('on-blur') onBlurEvent = new EventEmitter<any>();
	@Output('on-key-down') onKeyDownEvent = new EventEmitter<any>();
	@Output('on-key-up') onKeyUpEvent = new EventEmitter<any>();

	inputPaddingTop = 1;
	inputPaddingBottom = 1;
	marginTopCloneSecond = this.marginTopClone;

	ngOnInit() {
		this.calculate();

		this.inputController.valueChanges.subscribe((value) => {
			this.filterEmit(value);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.calculate();
	}

	calculate() {
		this.inputPaddingTop = 1;
		this.inputPaddingBottom = 1;
		this.marginTopCloneSecond = this.marginTopClone;
	}

	onEnter(event: any) {
		this.onEnterEvent.emit(event);
	}

	onFocus(event: any) {
		this.focused = true;
		this.onFocusEvent.emit(event);
	}

	onBlur(event: any) {
		this.focused = false;
		this.onBlurEvent.emit(event);
	}

	onKeyDown(event: any) {
		this.onKeyDownEvent.emit(event);
	}
	onKeyUp(event: any) {
		this.onKeyUpEvent.emit(event);
	}

	get hasTitle() {
		return this.title.length > 0;
	}
	get cssClasses() {
		let classes = {
			'container-error': this.inputController && this.inputController.invalid && this.inputController.touched && (this.hasRemoteError || this.hasRequiredError || this.hasEmailError || this.hasHourError || this.hasMinLengthError || this.hasMaxLengthError || this.hasPatternError),
			focused: this.focused,
			'input-disabled': !this.enable,
			'container-hint': this.hasHint,
		};
		(classes as any)[this.theme] = true;
		return classes;
	}

	// VALIDATIONS
	get hasRemoteError() {
		return (this.inputController.errors as any).remote;
	}
	get hasRequiredError() {
		return (this.inputController.errors as any).required;
	}
	get hasEmailError() {
		return (this.inputController.errors as any).email;
	}
	get hasHourError() {
		return (this.inputController.errors as any).hour;
	}
	get hasPatternError() {
		return (this.inputController.errors as any).pattern;
	}

	get hasMinLengthError() {
		return (this.inputController.errors as any).minlength;
	}
	get textMinLengthError() {
		return (this.inputController.errors as any).minlength?.requiredLength;
	}

	get hasMaxLengthError() {
		return (this.inputController.errors as any).maxlength;
	}
	get textMaxLengthError() {
		return (this.inputController.errors as any).maxlength?.requiredLength;
	}

	get errorMessage() {
		return (this.inputController.errors as any).value;
	}

	get hasError() {
		if (!!this.inputController.errors) {
			console.log(this.inputController.errors);
			return this.hasRemoteError || this.hasRequiredError || this.hasEmailError || this.hasHourError || this.hasMinLengthError || this.hasMaxLengthError || this.hasPatternError;
		}
		return false;
	}
}
