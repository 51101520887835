import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dnest-core-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input('text') text: string = "";
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";

  @Input('uppercase') uppercase: boolean = false;

  @Input('unactive') unactive: boolean = false;

  @Input('large') large: boolean = false;

  @Input('is-loading') isLoading: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
