import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Filter, FilterType } from 'src/app/models/filter';
import { User } from 'src/app/models/user';
import { ComponentApiClientService } from 'src/app/services/api/component-api-client/component-api-client.service';
import { VideoApiClientService } from 'src/app/services/api/video-api-client/video-api-client.service';
import { FiltersGetOptionsService } from 'src/app/services/filters-get-options-service/filters-get-options.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { FiltersService } from 'src/app/services/filters-service/filters.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'merz-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent implements OnInit {
  @Input() user: User;

  filters: Filter[];
  routerEvent: Subscription;

  langChangeSubscription: Subscription;

  constructor(
    public componentApiClientService: ComponentApiClientService,
    protected filtersGetOptionsService: FiltersGetOptionsService,
    protected videoApiClientService: VideoApiClientService,
    protected navController: NavController,
    protected filtersService: FiltersService,
    protected router: Router,
    private translateService: TranslateService
  ) {
    this.routerEvent = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tryCreateFilters();
      }
    });
  }

  ngOnInit() {
    this.tryCreateFilters();

    this.langChangeSubscription = this.translateService.onLangChange.subscribe(
      () => {
        this.createFilters();
      }
    );
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
  }

  tryCreateFilters() {
    if (
      this.filtersGetOptionsService?.speakersOptions?.length > 0 &&
      this.filtersGetOptionsService?.treatmentsOptions?.length > 0 &&
      this.filtersGetOptionsService?.productCategoriesOptions?.length > 0
    ) {
      this.createFilters();
    } else {
      setTimeout(() => {
        this.tryCreateFilters();
      }, 100);
    }
  }

  get filterParams() {
    return this.filtersService.filterParams;
  }

  createFilters() {
    this.filters = [
      // new Filter({
      //   type: FilterType.RADIO_BUTTON, field: 'type', options: [
      //     { text: 'Todos', value: '' },
      //     { text: 'Técnica', value: 'technique' },
      //     { text: 'Teoría', value: 'theory' },
      //   ], title: 'Tipos de formación', optionSelectedValue: this.filterParams['type']
      // }),
      // new Filter(
      //   { type: FilterType.CHECKBOX, filterService: this.componentApiClientService, field: 'component', title: 'Componente', optionSelectedValue: this.filterParams['component']
      // }),
      new Filter({
        type: FilterType.CHECKBOX,
        field: 'zone',
        options: [
          { text: this.translateService.instant('FILTERS.FORMATION.ZONE.OPTIONS.UPPER'), value: 'upper_zone' },
          { text: this.translateService.instant('FILTERS.FORMATION.ZONE.OPTIONS.MIDDLE'), value: 'middle_zone' },
          { text: this.translateService.instant('FILTERS.FORMATION.ZONE.OPTIONS.LOWER'), value: 'lower_zone' },
        ],
        title: this.translateService.instant('FILTERS.FORMATION.ZONE.TITLE'),
        optionSelectedValue: this.filterParams['zone'],
      }),
      new Filter({
        type: FilterType.SELECT,
        field: 'product_category',
        options: this.filtersGetOptionsService.productCategoriesOptions,
        title: this.translateService.instant('FILTERS.FORMATION.PRODUCT'),
        placeholder: this.translateService.instant('FILTERS.PLACEHOLDER'),
        optionSelectedValue: this.filterParams['product_category'],
      }),
      new Filter({
        type: FilterType.SELECT,
        field: 'treatments',
        options: this.filtersGetOptionsService.treatmentsOptions,
        title: this.translateService.instant('FILTERS.FORMATION.TREATMENT'),
        placeholder: this.translateService.instant('FILTERS.PLACEHOLDER'),
        optionSelectedValue: this.filterParams['treatments'],
      }),
      new Filter({
        type: FilterType.SELECT,
        field: 'speakers',
        options: this.filtersGetOptionsService.speakersOptions,
        title: this.translateService.instant('FILTERS.FORMATION.SPEAKERS'),
        placeholder: this.translateService.instant('FILTERS.PLACEHOLDER'),
        optionSelectedValue: this.filterParams['speakers'],
      }),
      new Filter({
        type: FilterType.CHECKBOX,
        field: 'duration',
        options: [
          // { text: 'Menos de 5 min', value: 'true' },
          { text: this.translateService.instant('FILTERS.FORMATION.DURATION.OPTIONS.0-5'), value: '0-5' },
          { text: this.translateService.instant('FILTERS.FORMATION.DURATION.OPTIONS.5-10'), value: '5-10' },
          { text: this.translateService.instant('FILTERS.FORMATION.DURATION.OPTIONS.10-20'), value: '10-20' },
          { text: this.translateService.instant('FILTERS.FORMATION.DURATION.OPTIONS.20_MORE'), value: '20_more' },
        ],
        title: this.translateService.instant('FILTERS.FORMATION.DURATION.TITLE'),
        optionSelectedValue: this.filterParams['duration'],
      }),
    ];

    const premiumFilter = new Filter({
      type: FilterType.CHECKBOX,
      field: 'accessLevel',
      options: [
        {
          text: this.translateService.instant('FILTERS.FORMATION.ACCESS_LEVEL.OPTIONS.PREMIUM'),
          value: 'premium',
        },
        {
          text: this.translateService.instant('FILTERS.FORMATION.ACCESS_LEVEL.OPTIONS.STUDENT'),
          value: 'student',
        },
      ],
      optionSelectedValue: this.filterParams['accessLevel'],
    });
    const masterFilter = new Filter({
      type: FilterType.CHECKBOX,
      field: 'accessLevel',
      options: [
        {
          text: this.translateService.instant(
          'FILTERS.FORMATION.ACCESS_LEVEL.OPTIONS.STUDENT'
          ),
          value: 'student',
        },
      ],
      optionSelectedValue: this.filterParams['accessLevel'],
    });

    const kamFilter = new Filter({
      type: FilterType.CHECKBOX,
      field: 'accessLevel',
      options: [
        { text: this.translateService.instant('FILTERS.FORMATION.ACCESS_LEVEL.OPTIONS.BASIC'), value: 'basic' },
        { text: this.translateService.instant('FILTERS.FORMATION.ACCESS_LEVEL.OPTIONS.ADVANCE'), value: 'advance' },
        { text: this.translateService.instant('FILTERS.FORMATION.ACCESS_LEVEL.OPTIONS.STUDENT'), value: 'student' },
        { text: this.translateService.instant('FILTERS.FORMATION.ACCESS_LEVEL.OPTIONS.PREMIUM'), value: 'premium' },
      ],
      title: this.translateService.instant('FILTERS.FORMATION.ACCESS_LEVEL.TITLE'),
      optionSelectedValue: this.filterParams['accessLevel'],
    });

    if (!this.user?.isKam && this.user?.accessLevel === 'premium') {
      this.filters.splice(1, 0, premiumFilter);
    } else if (!this.user?.isKam && this.user?.accessLevel === 'student') {
      this.filters.splice(1, 0, masterFilter);
    } else if (this.user?.isKam) {
      this.filters.splice(2, 0, kamFilter);
    }
  }
}
