import { Component, Input, OnInit } from '@angular/core';
import { Video } from 'src/app/models/video';

@Component({
  selector: 'merz-short-video-card',
  templateUrl: './short-video-card.component.html',
  styleUrls: ['./short-video-card.component.scss']
})
export class ShortVideoCardComponent implements OnInit {
  @Input() video: Video;
  @Input('show-progress') showProgress = false;
  constructor() { }

  ngOnInit(): void {
  }

  get percent(){
    return  ((this.video?.currentPosition) / (this.video?.duration || 1)).toFixed(2);
  }

  get seePremiumTag(){
    return this.video?.canSee && (this.video?.accessLevel==='premium' || this.video?.accessLevel==='student');
  }
}
