import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Filter } from '../../models/filter/filter';

@Component({
  selector: 'dnest-core-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input('placeholder') placeholder: string = "";
  @Input('theme') theme: string = "primary";
  @Input('input-controller') inputController: UntypedFormControl = new UntypedFormControl('');
  @Input('arrow') arrow: string = "" ;
  get backgroundImage() { return `url(${this.arrow})`}
  @Input('background-positionY') backgroundPositionY: string = "50%" ;

  _filter: any;
  @Input('filter')
  set filter(filter: Filter) {
    this._filter = filter;
    this.options = filter.options;
    filter.downloadServiceList.subscribe(
      filterEvent => {
        this.options = filterEvent.filter.options;
      }
    );
  }
  get filter() {
    return this._filter;
  }

  _options: any[] = [];
  @Input('options')
  set options (options: any[]) {
    this._options = options;
    if (!options) {
      return;
    }
    for (let index = 0; index < options.length; index++) {
      const option = options[index];
      if (option['selected']) {
        this.inputController.setValue(option);
        break;
      }
    }
  }
  get options() {
    return this._options;
  }

  constructor() { }

  ngOnInit(): void {
    this.inputController.valueChanges.subscribe(
      value => {
        this.selectOption(value);
        this.filterEmit(value?.value);
      }
    );
  }

  compareValues(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  filterEmit(value: any) {
    if(this.filter) {
      this.filter.changeValue(value);
    }
  }

  @Output('selected-option') selectedOptionEvent = new EventEmitter<any>();
  selectOption(value: any) {
    this.selectedOptionEvent.emit(value);
  }

  get cssClasses() {
    let classes = {
      'container-error': this.inputController && this.inputController.invalid &&
        this.inputController.touched && this.hasRequiredError,
    };
    (classes as any)[this.theme] = true;
    if (this.inputController.value == '') {
      (classes as any)['placeholder'] = true;
    }
    return classes;
  }

  get hasRequiredError() { return (this.inputController.errors as any).required }

}
