import { Component, Input } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'merz-input-radio-nps',
  templateUrl: './input-radio-nps.component.html',
  styleUrls: ['./input-radio-nps.component.scss'],
})
export class InputRadioNpsComponent extends InputComponent {
  _options: Option[] = [];
  @Input() set options(options) {
    this._options = options;
    this.getSelectedValue();
  }

  get options() {
    return this._options;
  }

  @Input('first-title') firstTitle = 'Poco probable';
  @Input('last-title') lastTitle = 'Muy probable';

  valueSelected = '';

  ngOnInit(): void {
    this.getSelectedValue();
  }

  getSelectedValue() {
    const selectedOption = this.options.find((option) => option.checked);
    if (selectedOption) {
      this.valueSelected = selectedOption.value;
    } else {
      this.valueSelected = '';
    }
  }

  selectOption(selectedValue: any) {
    this.valueSelected = selectedValue.value;
    this.formControl.setValue(selectedValue.value);
  }
}
