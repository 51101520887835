import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Plan } from 'src/app/models/plan';
import Swiper from 'swiper';

@Component({
  selector: 'merz-plans-modal',
  templateUrl: './plans-modal.component.html',
  styleUrls: ['./plans-modal.component.scss']
})
export class PlansModalComponent {
  @Input() userPlanId = '';
  @Input() kamUserId = '';
  @Input() plans: Plan[] = [];
  @Input() adminPhone = '';
  @Input() fromKam = false;

  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  swiper?: Swiper;


  constructor(
    protected modalController: ModalController,
  ) {
  }


  dismiss() {
    this.modalController.dismiss();
  }

  changePlan() {
    this.modalController.dismiss({}, 'change');
  }

}
