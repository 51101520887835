import { Component, Input, OnInit } from '@angular/core';
import { Video } from 'src/app/models/video';

@Component({
  selector: 'merz-video-top-card',
  templateUrl: './video-top-card.component.html',
  styleUrls: ['./video-top-card.component.scss'],
})
export class VideoTopCardComponent implements OnInit {
  @Input() video: Video;
  @Input() number: number;

  isHover = false;

  constructor() {}

  ngOnInit(): void {}
}
