import { Option } from './option';

export enum FilterType {
	SEARCH = 'search',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  RADIO_BUTTON = 'radioButton'
}

export class Filter {
	type: FilterType;
  title: string;
	placeholder: string;
  filterService: any;
  field: string;
  options: Option[];
  optionSelectedValue: any;

	constructor(values: { type?: string; title?: string; placeholder?: string; filterService?: any; field?: string; options?: Option[]; optionSelectedValue?: any }) {
		let initValues = {
			type: FilterType.SEARCH,
      title: '',
			placeholder: '',
      filterService: null,
      field: '',
      options: [],
      optionSelectedValue: null,
		};
		if (values) {
			initValues = Object.assign(initValues, values);
		}
		this.type = initValues['type'];
		this.title = initValues['title'];
		this.placeholder = initValues['placeholder'];
		this.filterService = initValues['filterService'];
		this.field = initValues['field'];
		this.options = initValues['options'];
		this.optionSelectedValue = initValues['optionSelectedValue'];
	}
}
