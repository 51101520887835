// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-button {
  display: flex;
}
.filters-button .filter-button {
  display: grid;
  place-items: center;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid var(--ion-color-lines);
}
.filters-button .filter-button.selected {
  background: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
}

@media only screen and (min-width: 1200px) {
  .filters-button {
    flex-direction: column;
  }
  .filters-button .filter-button {
    display: flex;
    height: 36px;
    border-radius: 5px;
    padding-left: 15px;
    border: none;
    background: rgba(246, 249, 254, 0);
  }
  .filters-button .filter-button:hover {
    background: rgb(246, 249, 254);
  }
  .filters-button .filter-button.selected {
    background: transparent linear-gradient(96deg, #0736B9 0%, #AE00CE 100%) 0% 0% no-repeat padding-box;
    border: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/filter/filter-radio-button/filter-radio-button.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,wCAAA;AACJ;AACI;EACE,oCAAA;EACA,0CAAA;AACN;;AAMA;EACE;IACE,sBAAA;EAHF;EAKE;IACE,aAAA;IACA,YAAA;IACA,kBAAA;IACA,kBAAA;IACA,YAAA;IACA,kCAAA;EAHJ;EAKI;IACE,8BAAA;EAHN;EAMI;IACE,oGAAA;IACA,YAAA;EAJN;AACF","sourcesContent":[".filters-button {\n  display: flex;\n\n  .filter-button {\n    display: grid;\n    place-items: center;\n    width: 100%;\n    height: 48px;\n    border-radius: 4px;\n    border: 1px solid var(--ion-color-lines);\n\n    &.selected {\n      background: var(--ion-color-primary);\n      border: 1px solid var(--ion-color-primary);\n    }\n  }\n}\n\n\n\n@media only screen and (min-width: 1200px) {\n  .filters-button {\n    flex-direction: column;\n\n    .filter-button {\n      display: flex;\n      height: 36px;\n      border-radius: 5px;\n      padding-left: 15px;\n      border: none;\n      background: rgb(246, 249, 254, 0);\n\n      &:hover {\n        background: rgb(246, 249, 254);\n      }\n\n      &.selected {\n        background: transparent linear-gradient(96deg, #0736B9 0%, #AE00CE 100%) 0% 0% no-repeat padding-box;\n        border: none;\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
