import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'merz-enroll-event-modal',
  templateUrl: './enroll-event-modal.component.html',
  styleUrls: ['./enroll-event-modal.component.scss'],
})
export class EnrollEventModalComponent implements OnInit {
  @Input('is-enroll') isEnroll = false;
  @Input('is-waiting-list') isWaitingList = false;
  @Input('is-attendanced') isAttendance = false;
  @Input('is-cancelled') isCancelled = false;

  get title() {
    if (this.isEnroll) {
      return this.translateService.instant('MODALS.ENROLL.TITLE');
    } else if (this.isWaitingList) {
      return this.translateService.instant('MODALS.WAITING_LIST.TITLE');
    } else if (this.isAttendance) {
      return this.translateService.instant('MODALS.ATTENDANCE.TITLE');
    } else if (this.isCancelled) {
      return this.translateService.instant(
        'MODALS.CANCEL_ASSISTANCE.TITLE_TOP'
      );
    }
  }

  get titleBottom() {
    return this.translateService.instant(
      'MODALS.CANCEL_ASSISTANCE.TITLE_BOTTOM'
    );
  }

  get titleSize() {
    return this.isCancelled ? '20' : '32';
  }

  get description() {
    if (this.isEnroll) {
      return this.translateService.instant('MODALS.ENROLL.DESC');
    } else if (this.isWaitingList) {
      return this.translateService.instant('MODALS.WAITING_LIST.DESC');
    } else if (this.isAttendance) {
      return this.translateService.instant('MODALS.ATTENDANCE.DESC');
    } else if (this.isCancelled) {
      return this.translateService.instant('MODALS.CANCEL_ASSISTANCE.DESC');
    }
  }

  constructor(
    private modalController: ModalController,
    protected navController: NavController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss();
  }

  cancellAssistance() {
    this.modalController.dismiss({}, 'cancel');
  }
}
