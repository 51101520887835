import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Paginator } from 'src/app/models/paginator';

@Injectable({
  providedIn: 'root'
})
export class BaseListResolver<T>  {
  protected service: any;
  protected pageSize = 20;

  resolve(): Observable<any> {
    return this.service.pageSize(this.pageSize).all().query.execute().pipe(
      map(paginator => {
        return paginator['results'];
      }),
      catchError((error) => {
        return of(undefined);
      })
    );
  }
}
