import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dnest-core-loading-logo',
  templateUrl: './loading-logo.component.html',
  styleUrls: ['./loading-logo.component.scss']
})
export class LoadingLogoComponent implements OnInit {
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";

  constructor() { }

  ngOnInit(): void {
  }

}
