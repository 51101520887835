import { Injectable } from '@angular/core';
import { UserWorkCenter } from 'src/app/models/user-work-center';
import { KamWorkcenterApiClientService } from '../api/kam-workcenter-api-client/kam-workcenter-api-client.service';
import { BaseListResolver } from './base-list.resolver';

@Injectable({
  providedIn: 'root'
})
export class KamWorkCentersResolver extends BaseListResolver<UserWorkCenter> {
  constructor(
    protected service: KamWorkcenterApiClientService,
  ) {
    super();
  }
}
