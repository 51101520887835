// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `merz-button-icon ion-img {
  width: 25px;
}
merz-button-icon ion-icon {
  font-size: 25px;
}

.select {
  padding: 7px 16px 7px 13px;
  border-radius: 5px;
  background: rgba(var(--ion-color-primary-rgb), 0.1);
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header-users/header-users.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAGE;EACI,eAAA;AADN;;AAKA;EACE,0BAAA;EACA,kBAAA;EACA,mDAAA;AAFF","sourcesContent":["merz-button-icon {\n  ion-img{\n    width: 25px;\n  }\n\n  ion-icon {\n      font-size: 25px;\n  }\n}\n\n.select {\n  padding: 7px 16px 7px 13px;\n  border-radius: 5px;\n  background: rgba(var(--ion-color-primary-rgb), .1);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
