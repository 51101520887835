// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-card {
  position: relative;
  display: block;
  border-radius: 5px;
}
ion-card ion-card-content {
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 160px;
}

.texts {
  margin-top: 6px;
}
.texts .margin {
  margin-top: -6px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/gallery-by-producto-card/gallery-by-producto-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAGA;EACE,kBAAA;EACA,cAAA;EACA,kBAAA;AAAF;AAEE;EACE,UAAA;EACA,2BAAA;EACA,4BAAA;EACA,sBAAA;EACA,iBAAA;AAAJ;;AAIA;EACE,eAAA;AADF;AAGE;EACE,gBAAA;AADJ","sourcesContent":[":host {\n  display: block;\n}\n\n\nion-card {\n  position: relative;\n  display: block;\n  border-radius: 5px;\n\n  ion-card-content {\n    padding: 0;\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n    min-height: 160px;\n  }\n}\n\n.texts {\n  margin-top: 6px;\n\n  .margin {\n    margin-top: -6px;\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
