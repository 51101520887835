// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-card {
  display: block;
  width: 222px;
  position: relative;
}
ion-card.large {
  width: 100%;
  max-width: 336px;
  margin: 24px auto;
}
ion-card img {
  min-height: 134px;
  height: 134px;
  width: 100%;
}
ion-card img.large {
  min-height: 212px;
  height: 212px;
}
ion-card ion-card-content {
  padding: 5px 14px 12px;
}
ion-card ion-card-content .title {
  font-size: 16px;
  font-weight: 500;
  color: var(--ion-color-description-text);
  line-height: 21px;
  text-align: left;
  display: block;
  display: -webkit-box;
  height: 62px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card ion-card-content .title.large {
  font-size: 18px;
  color: --ion-color-title-text;
}
ion-card ion-card-content .time {
  margin-top: 7px;
  display: flex;
  align-items: center;
}
ion-card ion-card-content .time ion-icon {
  margin-right: 8px;
}

@media only screen and (min-width: 1200px) {
  ion-card {
    width: 229px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/news-short-card/news-short-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAGA;EACE,cAAA;EACA,YAAA;EACA,kBAAA;AAAF;AAGE;EACE,WAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAIE;EACE,iBAAA;EACA,aAAA;EACA,WAAA;AAFJ;AAII;EACE,iBAAA;EACA,aAAA;AAFN;AAME;EACE,sBAAA;AAJJ;AAMI;EACE,eAAA;EACA,gBAAA;EACA,wCAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;EACA,YAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;AAJN;AAMM;EACE,eAAA;EACA,6BAAA;AAJR;AASI;EACE,eAAA;EACA,aAAA;EACA,mBAAA;AAPN;AASM;EACE,iBAAA;AAPR;;AAeA;EACE;IACE,YAAA;EAZF;AACF","sourcesContent":[":host {\n  display: block;\n}\n\n\nion-card {\n  display: block;\n  width: 222px;\n  position: relative;\n\n\n  &.large {\n    width: 100%;\n    max-width: 336px;\n    margin: 24px auto;\n  }\n\n  img {\n    min-height: 134px;\n    height: 134px;\n    width: 100%;\n\n    &.large {\n      min-height: 212px;\n      height: 212px;\n    }\n  }\n\n  ion-card-content {\n    padding: 5px 14px 12px;\n\n    .title {\n      font-size: 16px;\n      font-weight: 500;\n      color: var(--ion-color-description-text);\n      line-height: 21px;\n      text-align: left;\n      display: block;\n      display: -webkit-box;\n      height: 62px;\n      -webkit-line-clamp: 3;\n      -webkit-box-orient: vertical;\n      overflow: hidden;\n      text-overflow: ellipsis;\n\n      &.large {\n        font-size: 18px;\n        color: (--ion-color-title-text);\n      }\n    }\n\n\n    .time {\n      margin-top: 7px;\n      display: flex;\n      align-items: center;\n\n      ion-icon {\n        margin-right: 8px;\n      }\n    }\n  }\n}\n\n\n\n@media only screen and (min-width: 1200px) {\n  ion-card {\n    width: 229px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
