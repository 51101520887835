import { Component, Input } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'dnest-core-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent extends InputComponent {

  passwordInputType: string = PasswordInputType.PW;
  // PasswordInputType: PasswordInputType;

  @Input('show-input-img') showInputImg: string = "";
  @Input('hide-input-img') hideInputImg: string = "";

  showPassword() {
    this.passwordInputType = PasswordInputType.TEXT;
  }
  hidePassword() {
    this.passwordInputType = PasswordInputType.PW;
  }
}

export enum PasswordInputType {
  PW = 'password',
  TEXT = 'text'
}
