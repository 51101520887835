import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Kam } from 'src/app/models/kam';
import { User } from 'src/app/models/user';
import { UserDetailService } from 'src/app/services/user-detail-service/user-detail.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'merz-header-kams',
  templateUrl: './header-kams.component.html',
  styleUrls: ['./header-kams.component.scss'],
})
export class HeaderKamsComponent implements OnInit {
  showKamInfo = false;
  @ViewChild('menu') menu: any;

  get user() {
    return this.userDetailService.userDetail;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private userDetailService: UserDetailService,
    private translateService: TranslateService
    ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.addEventListener('click', (event) => {
        const withinBoundaries = event
          .composedPath()
          .includes(this.menu.nativeElement);
        if (withinBoundaries) {
          this.showKamInfo = !this.showKamInfo;
        } else {
          this.showKamInfo = false;
        }
      });
    }
  }

  get isSeveralKams() {
    return this.user?.kams?.length > 1;
  }

  get kam(): Kam {
    return this.user?.kams[0];
  }

  get kams() {
    return this.user?.kams;
  }

  get isMainComercial() {
    return this.kam?.isMainComercial;
  }
  get isSpecialistComercial() {
    return this.kam?.isSpecialistComercial;
  }

  get image() {
    return this.kam?.photo;
  }

  get name() {
    return `${this.kam?.firstName} ${this.kam?.lastName}`;
  }

  /**
   *
   * @param kam
   * @returns el nombre completo del kam firstName + lastName
   */
  getKamName(kam: Kam) {
    return `${kam?.firstName} ${kam?.lastName}`;
  }

  /**
   *
   * @param kam
   * @returns Retorna el tipo de comercial que es el kam (especialista, principal, de zona)
   */
  getKamTextName(kam: Kam){
    return kam?.isSpecialistComercial
    ? `${this.translateService.instant('MAIN_PROFILE.KAM_CARD.SPECIALIST_COMMERCIAL')}`
    : kam?.isMainComercial
      ? `${this.translateService.instant('MAIN_PROFILE.KAM_CARD.MAIN_COMMERCIAL')}`
      : `${this.translateService.instant('MAIN_PROFILE.KAM_CARD.AREA_COMMERCIAL')}`;
  }

  sendEmail(email: string) {
    if (window) {
      const emailTo = email;
      window.open(`mailto:${emailTo}`, '_self');
    }
  }
}
