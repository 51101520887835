import { Component, Input, OnInit } from '@angular/core';
import { Option } from 'src/app/models/option';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-checkbox-list',
  templateUrl: './input-checkbox-list.component.html',
  styleUrls: ['./input-checkbox-list.component.scss'],
})
export class InputCheckboxListComponent extends InputComponent {
	@Input() options: Option[] = [];
	@Input() count = 0;
  @Input('new-style') newStyle = false;

  get results(){
    return this.options.filter((option) => option.checked).map((option) => option.value);
  }

  get countMarked(){
    return this.results.length;
  }

  get isDisabled(){
    return this.count > 0 && this.countMarked === this.count;
  }

  mark(entry){
    if(entry.checked || (!entry.cheched && !this.isDisabled)){
      entry.checked = !entry.checked;
      this.formControl.setValue(this.results);
    }
  }
}
