import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { UserDetailService } from '../../user-detail-service/user-detail.service';

@Injectable({
  providedIn: 'root',
})
export class AppLanguageInterceptor implements HttpInterceptor {
  language = 'es';
  constructor(
    private platform: Platform,
    protected userDetailService: UserDetailService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.platform.ready().then(() => {
      Device.getLanguageCode().then((language) => {
        this.language = language.value;
      });
    });

    if (this.userDetailService.language) {
      this.language = this.userDetailService.language;
    }

    let updateAppReq = request;
    let headers = request.headers;
    headers = headers.set('Accept-Language', this.language);

    updateAppReq = request.clone({
      headers,
    });

    return next.handle(updateAppReq).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
}
