// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `merz-button-icon ion-icon {
  font-size: 25px;
}

ion-title {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}
ion-title merz-text {
  text-align: center;
}

.go-next {
  margin-right: 24px;
}

.arrow-white {
  color: var(--ion-color-white);
}

ion-toolbar.line {
  --border-bottom-header: var(--ion-color-lines);
}
ion-toolbar.dark {
  --border-bottom-header: var(--ion-color-primary-dark);
}
ion-toolbar.b-transparent {
  /* --background: transparent; */
}
ion-toolbar.b-no-bg {
  --background: transparent;
}
ion-toolbar.b-white {
  --background: var(--ion-color-white);
}
ion-toolbar.b-background {
  --background: var(--ion-color-background);
}
ion-toolbar.b-primary {
  --background: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header-back/header-back.component.scss"],"names":[],"mappings":"AACE;EACI,eAAA;AAAN;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,+BAAA;AADF;AAGE;EACI,kBAAA;AADN;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,6BAAA;AADF;;AAME;EACE,8CAAA;AAHJ;AAME;EACE,qDAAA;AAJJ;AAOE;EACE,+BAAA;AALJ;AAQE;EACE,yBAAA;AANJ;AASE;EACE,oCAAA;AAPJ;AAUE;EACE,yCAAA;AARJ;AAWE;EACE,sCAAA;AATJ","sourcesContent":["merz-button-icon {\n  ion-icon {\n      font-size: 25px;\n  }\n}\n\nion-title {\n  position: absolute;\n  right: 50%;\n  top: 50%;\n  transform: translate(50%, -50%);\n\n  merz-text {\n      text-align: center;\n  }\n}\n.go-next {\n  margin-right: 24px;\n}\n\n.arrow-white {\n  color: var(--ion-color-white);\n}\n\nion-toolbar {\n\n  &.line {\n    --border-bottom-header: var(--ion-color-lines);\n  }\n\n  &.dark {\n    --border-bottom-header: var(--ion-color-primary-dark);\n  }\n\n  &.b-transparent {\n    /* --background: transparent; */\n  }\n\n  &.b-no-bg {\n    --background: transparent;\n  }\n\n  &.b-white {\n    --background: var(--ion-color-white);\n  }\n\n  &.b-background {\n    --background: var(--ion-color-background);\n  }\n\n  &.b-primary {\n    --background: var(--ion-color-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
