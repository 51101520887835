import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Kam } from 'src/app/models/kam';


@Component({
  selector: 'merz-kam-user-card',
  templateUrl: './kam-user-card.component.html',
  styleUrls: ['./kam-user-card.component.scss']
})
export class KamUserCardComponent implements OnInit {
  @Input() kam: Kam;

  constructor(
    private translateService: TranslateService
    ) { }

  ngOnInit() {
  }

  get textName(){
    return this.isSpecialistComercial
      ? `${this.translateService.instant('MAIN_PROFILE.KAM_CARD.SPECIALIST_COMMERCIAL')}`
      : this.isMainComercial
        ? `${this.translateService.instant('MAIN_PROFILE.KAM_CARD.MAIN_COMMERCIAL')}`
        : `${this.translateService.instant('MAIN_PROFILE.KAM_CARD.AREA_COMMERCIAL')}`;
  }

  get isMainComercial(){
    return this.kam?.isMainComercial;
  }
  get isSpecialistComercial(){
    return this.kam?.isSpecialistComercial;
  }

  get image(){
    return this.kam?.photo;
  }

  get name() {
    return `${this.kam?.firstName} ${this.kam?.lastName}`;
  }

  sendEmail(){
    if(window){
      const emailTo = this.kam?.email;
      window.open(`mailto:${emailTo}`, '_self');
    }
  }

}
