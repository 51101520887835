// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-card {
  width: 100%;
  max-width: 175px;
}
ion-card.small {
  max-width: 100%;
}
ion-card img {
  min-height: 234px;
  height: 234px;
  width: 100%;
}
ion-card ion-card-content {
  text-align: left;
  padding: 5px 14px 12px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
ion-card ion-card-content .ellipsis {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: 21px;
  margin-top: 0;
}
ion-card ion-card-content merz-text {
  margin-top: 7px;
}

@media only screen and (min-width: 1200px) {
  ion-card {
    max-width: 187px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/media-reference-short-card/media-reference-short-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAIA;EACE,WAAA;EACA,gBAAA;AADF;AAGE;EACE,eAAA;AADJ;AAIE;EACE,iBAAA;EACA,aAAA;EACA,WAAA;AAFJ;AAKE;EACE,gBAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAHJ;AAKI;EACE,qBAAA;EACA,4BAAA;EACA,oBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;AAHN;AAMI;EACE,eAAA;AAJN;;AAWA;EACE;IACE,gBAAA;EARF;AACF","sourcesContent":[":host {\n  display: block;\n  // margin-top: -20px;\n}\n\n\nion-card {\n  width: 100%;\n  max-width: 175px;\n\n  &.small {\n    max-width: 100%;\n  }\n\n  img {\n    min-height: 234px;\n    height: 234px;\n    width: 100%;\n  }\n\n  ion-card-content {\n    text-align: left;\n    padding: 5px 14px 12px;\n    height: 90px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n\n    .ellipsis {\n      -webkit-line-clamp: 2;\n      -webkit-box-orient: vertical;\n      display: -webkit-box;\n      overflow: hidden;\n      line-height: 21px;\n      margin-top: 0;\n    }\n\n    merz-text {\n      margin-top: 7px;\n    }\n  }\n}\n\n\n\n@media only screen and (min-width: 1200px) {\n  ion-card {\n    max-width: 187px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
