// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  height: 293px;
  position: absolute;
  bottom: 0;
  background: var(--ion-color-white);
  z-index: 5;
  overflow: auto;
  width: 100%;
  padding: 18px 23px 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.modal-container .icon-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.modal-container .icon-container ion-icon {
  cursor: pointer;
  font-size: 25px;
  margin-left: -8px;
}
.modal-container .title {
  line-height: 39px;
  margin-bottom: 10px;
  margin-top: -9px;
}
.modal-container .title.line-height20 {
  line-height: 20px;
  margin-bottom: 2px;
}
.modal-container .title-bottom {
  line-height: 20px;
  margin-bottom: 8px;
}
.modal-container .buttons {
  margin-top: 23px;
}
.modal-container .buttons-cancelled {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/enroll-event-modal/enroll-event-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,SAAA;EACA,kCAAA;EACA,UAAA;EACA,cAAA;EACA,WAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,yBAAA;AACJ;AACI;EACE,eAAA;EACA,eAAA;EACA,iBAAA;AACN;AAGE;EACE,iBAAA;EACA,mBAAA;EACA,gBAAA;AADJ;AAGI;EACE,iBAAA;EACA,kBAAA;AADN;AAKE;EACE,iBAAA;EACA,kBAAA;AAHJ;AAME;EACE,gBAAA;AAJJ;AAOE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AALJ","sourcesContent":[".modal-container {\n  height: 293px;\n  position: absolute;\n  bottom: 0;\n  background: var(--ion-color-white);\n  z-index: 5;\n  overflow: auto;\n  width: 100%;\n  padding: 18px 23px 22px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n\n  .icon-container {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n\n    ion-icon {\n      cursor: pointer;\n      font-size: 25px;\n      margin-left: -8px;\n    }\n  }\n\n  .title {\n    line-height: 39px;\n    margin-bottom: 10px;\n    margin-top: -9px;\n\n    &.line-height20 {\n      line-height: 20px;\n      margin-bottom: 2px;\n    }\n  }\n\n  .title-bottom {\n    line-height: 20px;\n    margin-bottom: 8px;\n  }\n\n  .buttons {\n    margin-top: 23px;\n  }\n\n  .buttons-cancelled {\n    margin-top: 12px;\n    display: flex;\n    flex-direction: column;\n    gap: 13px;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
