import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BaseDetailResolver<T>  {
  protected service: any;
  protected idParam = 'id';

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<any> {
    return this.service.get(route.paramMap.get(this.idParam)).pipe(
      catchError((error) => {
        return of(undefined);
      })
    );
  }
}
