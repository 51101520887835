import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'merz-date-card-box',
  templateUrl: './date-card-box.component.html',
  styleUrls: ['./date-card-box.component.scss']
})
export class DateCardBoxComponent implements OnInit {
  @Input() date = '';
  @Input() endate = '';

  constructor(protected utilsService: UtilsService) { }

  ngOnInit(): void {
  }

  get weekDay(){
    return this.utilsService.daysToText[new Date(this.date).getDay()];
  }
  get monthDay(){
    return new Date(this.date).getDate();
  }
  get month(){
    return this.utilsService.monthsToText[new Date(this.date).getMonth()];
  }

  get weekEndDay(){
    return this.utilsService.daysToText[new Date(this.endate).getDay()];
  }
  get monthEndDay(){
    return new Date(this.endate).getDate();
  }
  get monthEnd(){
    return this.utilsService.monthsToText[new Date(this.endate).getMonth()];
  }
}
