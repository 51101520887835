import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { debounceTime } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { InputComponent } from '../input/input.component';
import { Country } from 'src/app/models/country';
import { UserDetailService } from 'src/app/services/user-detail-service/user-detail.service';

@Component({
  selector: 'merz-input-countries',
  templateUrl: './input-countries.component.html',
  styleUrls: ['./input-countries.component.scss'],
})
export class InputCountriesComponent extends InputComponent {
  optionsSearch: Country[] = [];

  searchControl = new UntypedFormControl();

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected navController: NavController,
    protected localStorageService: LocalStorageService,
    protected userDetailService: UserDetailService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.searchControl.valueChanges
      .pipe(debounceTime(800))
      .subscribe((value) => {
        this.searchInOptions(value);
      });
    this.optionsSearch = this.countries.map((country: Country) => {
      country.checked = false;
      return country;
    });
  }

  get countries() {
    return this.userDetailService.countries.filter(
      (country) => !country.needZipCode
    );
  }

  get empty() {
    return this.optionsSearch.length === 0;
  }

  searchInOptions(value: string) {
    value = value?.toLowerCase().trim();
    this.optionsSearch = this.countries.filter((item) =>
      item.name?.toLowerCase().includes(value)
    );
  }

  selectCountry(country: Country) {
    this.countries.forEach((option: any) => {
      option.checked = false;
    });
    country.checked = true;
    this.formControl.setValue(country.id);
  }
}
