import { Component, Input, OnInit } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
})
export class InputCheckboxComponent extends InputComponent {
  @Input('link-text') linkText = '';
  @Input('link-href') linkHref = '';

  mark(){
    this.formControl.setValue(!this.formControl.value);
  }

  goTo(e: Event){
    e.stopPropagation();
    if (window) {
      window.open(this.linkHref, '_blank');
    }
  }
}

