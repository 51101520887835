import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Speaker } from 'src/app/models/speaker';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'merz-speakers-modal',
  templateUrl: './speakers-modal.component.html',
  styleUrls: ['./speakers-modal.component.scss']
})
export class SpeakersModalComponent implements OnInit {
  @Input() speakers: Speaker[] = [];
  @Input() fromFormation = false;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService
    ) {}

	ngOnInit() {
  }

  get text() {
    return this.fromFormation ? this.translateService.instant('MODALS.SPEAKERS.SPEAKERS') : this.translateService.instant('MODALS.SPEAKERS.RECEIVE_NOTIFICATIONS');
  }

	dismiss() {
		this.modalController.dismiss();
	}
}
