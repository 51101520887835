// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.free-tag {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(var(--ion-color-dark-rgb), 0.1);
  border-radius: 5px;
  padding: 6px 11px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/free-tag/free-tag.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,mCAAA;EACA,2BAAA;EACA,sDAAA;EACA,kBAAA;EACA,iBAAA;AACF","sourcesContent":[":host {\n  display: block;\n}\n\n.free-tag {\n  -webkit-backdrop-filter: blur(10px);\n  backdrop-filter: blur(10px);\n  background-color: rgba(var(--ion-color-dark-rgb), 0.1);\n  border-radius: 5px;\n  padding: 6px 11px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
