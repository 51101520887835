import { Injectable } from '@angular/core';
import { BaseDetailResolver } from './base-detail.resolver';
import { FreeContentApiClientService } from '../api/free-content-api-client/free-content-api-client.service';
import { FreeContent } from 'src/app/models/free-content';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotFoundService } from '../not-found/not-found.service';

@Injectable({
  providedIn: 'root',
})
export class FreeContentResolver extends BaseDetailResolver<FreeContent> {
  protected idParam = 'freeContentId';

  constructor(
    protected service: FreeContentApiClientService,
    protected notFoundService: NotFoundService
  ) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.service.get(route.paramMap.get(this.idParam)).pipe(
      map((data) => {
        this.notFoundService.showNotFound = false;
        return data;
      }),
      catchError((error) => {
        if (error.status === 404) {
          this.notFoundService.showNotFound = true;
        }
        return of(undefined);
      })
    );
  }
}
