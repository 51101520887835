import { Component, OnInit } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent extends InputComponent {
  showPassword = false;


  seePassword(){
    this.showPassword = !this.showPassword;
    this.inputField.nativeElement.type = this.showPassword ? 'text': 'password';
  }
}
