import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { IBaseLink } from '../../models/base-link';

@Component({
  selector: 'lib-sidenav-bar-item',
  templateUrl: './sidenav-bar-item.component.html',
  styleUrls: ['./sidenav-bar-item.component.scss']
})
export class SidenavBarItemComponent implements OnInit {

  hovered: boolean = false;
  @Input('button-info') buttonInfo: any;
  @Input('theme') theme: string = "primary";

  constructor(
    protected router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
  }

  get active() { return this.router.url === `/${this.buttonInfo?.link}` }

  goTo(event: any) {
    if (this.buttonInfo?.externalLink  && isPlatformBrowser(this.platformId)) {
      window.open(this.buttonInfo?.link , '_blank')
    } else {
      this.router.navigate([this.buttonInfo?.link ])
    }
  }
}
