import { Injectable } from '@angular/core';
import { UserDevice } from 'src/app/models/user-device';
import { UserDeviceApiClientService } from '../api/user-device-api-cliente/user-device-api-client.service';
import { BaseListResolver } from './base-list.resolver';

@Injectable({
  providedIn: 'root'
})
export class UserDevicesResolver extends BaseListResolver<UserDevice> {
  constructor(
    protected service: UserDeviceApiClientService,
  ) {
    super();
  }
}
