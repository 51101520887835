import { Injectable } from '@angular/core';
import { ProductCategoryApiClientService } from '../api/product-category-api-client/product-category-api-client.service';
import { TreatmentApiClientService } from '../api/treatment-api-client/treatment-api-client.service';
import { SpeakerApiClientService } from '../api/speaker-api-client/speaker-api-client.service';
import { EventtypeApiClientService } from '../api/eventtype-api-client/eventtype-api-client.service';
import { EventsubjectApiClientService } from '../api/eventsubject-api-client/eventsubject-api-client.service';
import { ProductCategory } from 'src/app/models/product-category';
import { PlanApiClientService } from '../api/plan-api-client/plan-api-client.service';
import { UserWorkCenterApiClientService } from '../api/user-work-center-api-client/user-work-center-api-client.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersGetOptionsService {
  //FILTERS OPTIONS
  productCategoriesOptions: any[] = [];
  treatmentsOptions: any[] = [];
  speakersOptions: any[] = [];
  eventTypesOptions: any[] = [];
  eventSubjectsOptions: any[] = [];
  planOptions: any[] = [];
  userWorkCenterOptions: any[] = [];

  //INFO API
  productCategories: ProductCategory[] = [];

  constructor(
    protected productCategoryApiClientService: ProductCategoryApiClientService,
    protected treatmentApiClientService: TreatmentApiClientService,
    protected speakerApiClientService: SpeakerApiClientService,
    protected eventtypeApiClientService: EventtypeApiClientService,
    protected eventsubjectApiClientService: EventsubjectApiClientService,
    protected planApiClientService: PlanApiClientService,
    protected userWorkCenterApiClientService: UserWorkCenterApiClientService,
  ) { }


  getOptionsFilters() {
    this.getEventTypesOptions();
    this.getEventSubjectsOptions();
    this.getProductCategoryOptions();
    this.getTreatmentOptions();
    this.getSpeakerOptions();
    this.getPlansOptions();
    this.getUserWorkCenterOptions();
  }

  getProductCategoryOptions() {
    this.productCategoryApiClientService.pageSize(100).all().subscribe((data) => {
      this.productCategories = data['results']; //aqui se guardan las categorias de productos

      //luego se crean las opciones para el filtro
      this.productCategoriesOptions = [];
      data['results'].forEach((item) => {
        this.productCategoriesOptions.push({ text: item.name, value: item.id });
      });
      this.productCategoriesOptions.unshift({ text: 'Ninguno', value: '' });
    });
  }

  getTreatmentOptions() {
    this.treatmentApiClientService.pageSize(100).all().subscribe((data) => {
      this.treatmentsOptions = [];
      data['results'].forEach((item) => {
        this.treatmentsOptions.push({ text: item.name, value: item.id });
      });
      this.treatmentsOptions.unshift({ text: 'Ninguno', value: '' });
    });
  }

  getSpeakerOptions() {
    this.speakerApiClientService.pageSize(100).all().subscribe((data) => {
      this.speakersOptions = [];
      data['results'].forEach((item) => {
        this.speakersOptions.push({ text: item.name, value: item.id });
      });
      this.speakersOptions.unshift({ text: 'Ninguno', value: '' });
    });
  }

  getEventTypesOptions() {
    this.eventtypeApiClientService.pageSize(100).all().subscribe((data) => {
      this.eventTypesOptions = [];
      data['results'].forEach((item) => {
        this.eventTypesOptions.push({ text: item.name, value: item.id });
      });
    });
  }

  getEventSubjectsOptions() {
    this.eventsubjectApiClientService.pageSize(100).all().subscribe((data) => {
      this.eventSubjectsOptions = [];
      data['results'].forEach((item) => {
        this.eventSubjectsOptions.push({ text: item.name, value: item.id });
      });
    });
  }

  getPlansOptions() {
    this.planApiClientService.pageSize(100).all().subscribe((data) => {

      this.planOptions = [];
      data['results'].forEach((item) => {
        this.planOptions.push({ text: item.name, value: item.id });
      });
    });
  }

  getUserWorkCenterOptions() {
    this.userWorkCenterApiClientService.pageSize(100).all().subscribe((data) => {

      this.userWorkCenterOptions = [];
      data['results'].forEach((item) => {
        this.userWorkCenterOptions.push({ text: item.name, value: item.id });
      });
      this.userWorkCenterOptions.unshift({ text: 'Ninguno', value: '' });
    });
  }


}
