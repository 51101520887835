import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'merz-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnInit {

  @Input() text;
  @Input() color = 'medium';
  @Input() size = '16';
  @Input() weight = 'regular';
  @Input() uppercase = false;
  @Input() ellipsis = false;
  @Input() stroke = false;

  constructor() { }

  ngOnInit() {}

}
