import { Component, Input, OnInit } from '@angular/core';
import { Gallery } from 'src/app/models/gallery';

@Component({
  selector: 'merz-gallery-card',
  templateUrl: './gallery-card.component.html',
  styleUrls: ['./gallery-card.component.scss'],
})
export class GalleryCardComponent implements OnInit {
  @Input() gallery: Gallery;
  @Input('show-select') showSelect = false;
  @Input() selected = false;
  @Input('on-slide') onSlide = false;
  @Input('center-logo') logo = '';
  constructor() { }

  ngOnInit() {}

}
