import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Video } from 'src/app/models/video';
import { VideoApiClientService } from 'src/app/services/api/video-api-client/video-api-client.service';

@Component({
  selector: 'merz-unfavorite-popover',
  templateUrl: './unfavorite-popover.component.html',
  styleUrls: ['./unfavorite-popover.component.scss'],
})
export class UnfavoritePopoverComponent implements OnInit {
  @Input() videoId = '';
  @Input() highlightId = '';

  constructor(protected popoverController: PopoverController, protected videoApiClientService: VideoApiClientService,
    protected translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  dismiss() {
    this.popoverController.dismiss();
  }

  get text() {
    return !!this.highlightId ? this.translateService.instant('POPOVER.UNFAVORITE.TITLE_HIGHLIGHTID') : this.translateService.instant('POPOVER.UNFAVORITE.TITLE');
  }

  unfavorite() {
    const video = new Video();
    video.id = this.videoId;

    let req = !!this.highlightId ? this.videoApiClientService.filter({ highlight: this.highlightId }).deleteAction(video, 'favorite') : this.videoApiClientService.deleteAction(video, 'favorite');

    req.subscribe((data) => {
      if (data['success']) {
        this.videoApiClientService.videoFavoritesEvent.next('');
        this.dismiss();
      }
    });
  }

}
