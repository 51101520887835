import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HeaderProfileMenuComponent } from './header-profile-menu.component';
import { TextModule } from '../text/text.module';
import { RouterModule } from '@angular/router';
import { SmallSeePlansComponentModule } from '../small-see-plans/small-see-plans.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TextModule,
    RouterModule,
    SmallSeePlansComponentModule,
    TranslateModule,
    NgOptimizedImage,
  ],
  declarations: [HeaderProfileMenuComponent],
  exports: [HeaderProfileMenuComponent],
})
export class HeaderProfileMenuComponentModule {}
