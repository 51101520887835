// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}

ion-card {
  display: flex;
  margin-top: 0;
  width: 100%;
}
ion-card ion-thumbnail {
  object-fit: cover;
  height: 170px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/mba-formation-card/mba-formation-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;;AAGA;EACE,aAAA;EACA,aAAA;EACA,WAAA;AAAF;AAEE;EACE,iBAAA;EACA,aAAA;EACA,WAAA;AAAJ","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n}\n\n\nion-card {\n  display: flex;\n  margin-top: 0;\n  width: 100%;\n\n  ion-thumbnail {\n    object-fit: cover;\n    height: 170px;\n    width: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
