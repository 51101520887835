import { Component, OnInit } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-empty',
  templateUrl: './input-empty.component.html',
  styleUrls: ['./input-empty.component.scss'],
})
export class InputEmptyComponent extends InputComponent {
}

