import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { UserDetailService } from 'src/app/services/user-detail-service/user-detail.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'merz-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
})
export class HeaderDesktopComponent implements OnInit {
  MERZ_ROUTES = MERZ_ROUTES;

  constructor(
    private navController: NavController,
    private userDetailService: UserDetailService
  ) {}

  ngOnInit() {}

  get user() {
    return this.userDetailService.userDetail;
  }

  goToHome() {
    this.navController.navigateForward([MERZ_ROUTES.homeComplete]);
  }
}
