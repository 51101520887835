import { Component, Input } from '@angular/core';
import { Option } from 'src/app/models/option';
import { InputComponent } from '../input/input.component';


@Component({
  selector: 'merz-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
})
export class InputRadioComponent extends InputComponent {
  _options: Option[] = [];
  @Input() set options(options) {
    this._options = options;
    this.getSelectedValue();
  }

  get options() {
    return this._options;
  }

  @Input('new-style') newStyle = false;

  valueSelected = '';

  ngOnInit(): void {
    this.getSelectedValue();
  }

  getSelectedValue() {
    const selectedOption = this.options.find((option) => option.checked);
    if (selectedOption) {
      this.valueSelected = selectedOption.value;
    } else {
      this.valueSelected = '';
    }
  }

  selectOption(selectedValue: any) {
    this.valueSelected = selectedValue.value;
    this.formControl.setValue(selectedValue.value);
  }
}

