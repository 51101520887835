import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-textarea-restricted-words',
  templateUrl: './input-textarea-restricted-words.component.html',
  styleUrls: ['./input-textarea-restricted-words.component.scss'],
})
export class InputTextareaRestrictedWordsComponent extends InputComponent {
  @Input('max-text-length') maxTextLength: any = 150;
  @Input('rows') rows = 10;

  @Output('on-key-up') onKeyUpEvent = new EventEmitter<any>();

  /**Tomar número de palabras del textarea del mensaje */
  get numberWordsMessage() {
    return this.formControl?.value?.length > this.maxTextLength
      ? `${this.maxTextLength}`
      : this.formControl?.value?.length;
  }

  override ngOnInit() {
    this.formControl?.valueChanges.subscribe(() => {
      if (this.formControl?.value?.length > this.maxTextLength) {
        this.formControl?.setValue(
          this.formControl?.value.slice(0, this.maxTextLength)
        );
      }
    });
  }

  onKeyUp(event: any) {
    this.onKeyUpEvent.emit(event);
  }
}
