import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ActionSheetController, NavController } from '@ionic/angular';
import { LogoutApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { User } from 'src/app/models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'merz-header-profile-menu',
  templateUrl: './header-profile-menu.component.html',
  styleUrls: ['./header-profile-menu.component.scss'],
})
export class HeaderProfileMenuComponent implements OnInit {
  @Input() user: User;
  showMenu = false;
  @ViewChild('menu') menu: any;

  get itemsCurrent() {
    return this.user?.isKam ? this.itemsKams : this.items;
  }

  itemsKams = [];

  getItemsKams() {
    this.itemsKams = [
      {
        text: this.translateService.instant('MAIN_PROFILE.MY_USERS.TITLE'),
        icon: '/assets/svg/personal-info-icon.svg',
        link: MERZ_ROUTES.myUsersComplete,
      },
      {
        text: this.translateService.instant('MAIN_PROFILE.MY_CLINICS.TITLE'),
        icon: '/assets/svg/work-center-icon.svg',
        link: MERZ_ROUTES.kamWorkCentersComplete,
      },
    ];
  }

  items = [];

  getItems() {
    this.items = [
      {
        text: this.translateService.instant('MAIN_PROFILE.MY_CLINICS.TITLE'),
        icon: '/assets/svg/work-center-icon.svg',
        link: MERZ_ROUTES.workCentersComplete,
      },
    ];
  }

  itemsBoth = [];

  getItemsBoth() {
    this.itemsBoth = [
      {
        text: this.translateService.instant('MAIN_PROFILE.FAVORITES.TITLE'),
        icon: '/assets/svg/save-icon.svg',
        link: MERZ_ROUTES.favoritesComplete,
      },
    ];
  }

  itemsSecond = [];

  getItemsSecond() {
    this.itemsSecond = [
      {
        text: this.translateService.instant('MAIN_PROFILE.CONTACT.TITLE'),
        icon: '/assets/svg/contact-icon.svg',
        link: MERZ_ROUTES.contactComplete,
      },
      {
        text: this.translateService.instant('PROFILE.CONFIGURATION'),
        icon: '/assets/svg/settings-icon.svg',
        link: MERZ_ROUTES.settingsComplete,
      },
    ];
  }

  closeSession = [];

  getCloseSession() {
    this.closeSession = [
      {
        text: this.translateService.instant('PROFILE.LOGOUT.TITLE'),
        icon: '/assets/svg/close-icon.svg',
        logoutBool: true,
      },
    ];
  }

  constructor(
    private logoutApiClientService: LogoutApiClientService,
    private actionSheetController: ActionSheetController,
    @Inject(PLATFORM_ID) private platformId: Object,
    private navController: NavController,
    protected translateService: TranslateService
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      document.addEventListener('click', (event) => {
        this.getItemsKams();
        this.getItems();
        this.getItemsBoth();
        this.getItemsSecond();
        this.getCloseSession();
        const withinBoundaries = event
          .composedPath()
          .includes(this.menu.nativeElement);
        if (withinBoundaries) {
          this.showMenu = !this.showMenu;
        } else {
          this.showMenu = false;
        }
      });
    }

    if (this.user?.isKam) {
      this.items[0]!.link! = MERZ_ROUTES.kamWorkCentersComplete;
      this.items.unshift({
        text: this.translateService.instant('MAIN_PROFILE.MY_USERS.TITLE'),
        icon: '/assets/svg/personal-info-icon.svg',
        link: MERZ_ROUTES.myUsersComplete,
      });
    }
  }

  openMerzContigo(e) {
    e.stopPropagation();
    if (window) {
      window.open('https://merzcontigo.es/', '_blank');
    }
  }

  async presentActionSheet(event) {
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'more-options',
      header: '',
      subHeader: this.translateService.instant('PROFILE.LOGOUT.INFO_MESSAGE'),
      buttons: [
        {
          text: this.translateService.instant('PROFILE.LOGOUT.TITLE'),
          handler: () => {
            this.navController.navigateRoot(MERZ_ROUTES.welcomeComplete);
            this.logoutApiClientService.logout();
          },
        },
        {
          text: this.translateService.instant('BUTTONS.CANCEL'),
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
}
