import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterCheckboxComponent } from './filter-checkbox/filter-checkbox.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TextModule } from '../text/text.module';
import { FilterRadioButtonComponent } from './filter-radio-button/filter-radio-button.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';



@NgModule({
  declarations: [FilterCheckboxComponent, FilterRadioButtonComponent, FilterSelectComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TextModule
  ],
  exports: [FilterCheckboxComponent, FilterRadioButtonComponent, FilterSelectComponent]
})
export class FilterModule { }
