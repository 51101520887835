// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.premium {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 6px 8px;
  background: rgba(var(--ion-color-primary-rgb), 0.75);
}
.premium .margin {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/can-see-plan-tag/can-see-plan-tag.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,oDAAA;AAAF;AAGE;EACE,gBAAA;AADJ","sourcesContent":[":host {\n  display: block;\n}\n\n\n.premium {\n  display: flex;\n  align-items: center;\n  border-radius: 5px;\n  padding: 6px 8px;\n  background: rgba(var(--ion-color-primary-rgb), .75);\n\n\n  .margin {\n    margin-left: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
