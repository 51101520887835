// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-text-container {
  padding: 5px 14px;
  border-radius: var(--border-radius);
  margin-bottom: 15px;
}

.text-container {
  padding: 5px 0;
  border-top: none !important;
  border-radius: var(--border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/input/input-error/input-error.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mCAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,2BAAA;EACA,mCAAA;EACA,yBAAA;EACA,0BAAA;AACF","sourcesContent":[".top-text-container {\n  padding: 5px 14px;\n  border-radius: var(--border-radius);\n  margin-bottom: 15px;\n}\n\n.text-container {\n  padding: 5px 0;\n  border-top: none !important;\n  border-radius: var(--border-radius);\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n}\n\n.error-container {\n  // border: 1px solid var(--ion-color-danger);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
