import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  showCalendar: boolean = false;
  showCalendarEvent = new Subject<boolean>();
  openedCalendarID: string = '';
  listOfCalendars: any[] = [];

  constructor() { }

  openCalendar(id: string) {
    const calendar = document.getElementById(id);
    const arrow = document.getElementById(`${id}-arrow`);

    calendar?.classList.add('show-calendar');
    arrow?.classList.add('open-select');

    this.showCalendar = true;
    this.showCalendarEvent.next(true);
  }

  closeCalendar(id: string) {
    const calendar = document.getElementById(id);
    const arrow = document.getElementById(`${id}-arrow`);

    calendar?.classList.remove('show-calendar');
    arrow?.classList.remove('open-select');

    this.showCalendar = false;
    this.showCalendarEvent.next(false);
  }
}
