import { Injectable } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { ChangePassword } from '../../../models/change-password';

@Injectable({
  providedIn: 'root'
})
export class UserPasswordApiClientService extends ApiClientService<ChangePassword> {
  override endpoint = 'rest-auth/password/change';

  changePassword(payload: any) {
    return this.create(
      payload,
      true
    );
  }

  /* get passwordQuestions(): Question<any>[] {
    return [
      new InputQuestion({
        value: '',
        key: 'old_password',
        label: 'Contraseña actual',
        type: 'password',
        required: true,
        placeholder: 'Contraseña actual'
      }),
      new InputQuestion({
        value: '',
        key: 'new_password1',
        label: 'Contraseña nueva',
        type: 'password',
        required: true,
        placeholder: 'Contraseña nueva'
      }),
      new InputQuestion({
        value: '',
        key: 'new_password2',
        label: 'Repetir contraseña nueva',
        type: 'password',
        required: true,
        placeholder: 'Repetir contraseña nueva'
      })
    ];
  } */
}
