import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { enterAnimation, leaveAnimation } from 'src/app/animations/enter';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { FilterUserService } from 'src/app/services/filter-user/filter-user.service';
import { FilterUsersComponent } from '../../modal/filter-users/filter-users.component';

@Component({
  selector: 'merz-header-users',
  templateUrl: './header-users.component.html',
  styleUrls: ['./header-users.component.scss']
})
export class HeaderUsersComponent implements OnInit {

  constructor(
    protected navController: NavController,
    protected modalController: ModalController,
    protected filterUserService: FilterUserService
    ) {
	}
	ngOnInit() {}

	goBack() {
    return this.navController.navigateBack(MERZ_ROUTES.profileComplete);
  }

  goToAddUser(){
    this.navController.navigateForward([MERZ_ROUTES.profileMyUserNewComplete]);

  }

  async openFilter(event) {
		const modal = await this.modalController.create({
			component: FilterUsersComponent,
      enterAnimation,
      leaveAnimation,
			cssClass: 'modal-options',
		});

		return await modal.present();
	}

  get filtersIcon(){
    return this.filterUserService.isFilterEmpty ? '/assets/svg/options-outline.svg' : '/assets/svg/options-outline-mark.svg';
  }
}
