import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { Video } from 'src/app/models/video';
import { VideoHighlight } from 'src/app/models/video-highlight';
import { VideoApiClientService } from 'src/app/services/api/video-api-client/video-api-client.service';
import { UnfavoritePopoverComponent } from '../../popover/unfavorite-popover/unfavorite-popover.component';

@Component({
  selector: 'merz-favorite-video-card',
  templateUrl: './favorite-video-card.component.html',
  styleUrls: ['./favorite-video-card.component.scss'],
})
export class FavoriteVideoCardComponent implements OnInit {
  @Input() video: Video;

  isHover = false;

  constructor(
    protected popoverController: PopoverController,
    protected videoApiClientService: VideoApiClientService,
    protected navController: NavController
  ) {}

  ngOnInit(): void {}

  get speakerName() {
    return this.video?.speaker?.title
      ? `${this.video?.speaker?.title}. ${this.video?.speaker?.name}`
      : this.video?.speaker?.name;
  }

  get countText() {
    return this.highlightsFavorites.length;
  }

  get markText() {
    return this.highlightsFavorites?.length === 1 ? 'Marcador' : 'Marcadores';
  }

  async presentPopover(ev: Event, highlightId = '') {
    ev.stopPropagation();
    const popover = await this.popoverController.create({
      component: UnfavoritePopoverComponent,
      componentProps: {
        videoId: this.video.id,
        highlightId,
      },
      cssClass: 'popover-class',
      event: ev,
    });
    await popover.present();
  }

  getTimestamp(time) {
    if (time < 3600) {
      return new Date(time * 1000).toISOString().substr(14, 5);
    } else {
      return new Date(time * 1000).toISOString().substr(11, 8);
    }
  }

  goToVideo() {
    this.navController.navigateForward([
      MERZ_ROUTES.videoIdComplete.replace('${videoId}', this.video?.id),
    ]);
  }

  get highlightsFavorites() {
    return this.video?.videoHighlights.filter((high) => high.favorite);
  }

  // unBookmark(ev: Event, item: VideoHighlight) {
  //   ev.stopPropagation();
  //   console.log("Un bookmark");
  //   this.presentPopover(ev);

  //   // const video = new Video();
  //   // video.id = item.video;
  //   // this.videoApiClientService.deleteAction(video, 'favorite', {highlight: item.id}).subscribe((data) => {
  //   //   if(data['success']){
  //   //     //Volver a pedir los videos favoritos
  //   //   }
  //   // });
  // }

  goToHighligth(ev: Event) {
    ev.stopPropagation();
  }
}
