import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'merz-disclaimer-modal',
  templateUrl: './disclaimer-modal.component.html',
  styleUrls: ['./disclaimer-modal.component.scss']
})
export class DisclaimerModalComponent implements OnInit {
  @Input() textMessage = this.translateService.instant('MODALS.DISCLAIMER.TEXT');

  constructor(
    private modalController: ModalController,
    protected translateService: TranslateService
  ) {}

	ngOnInit() {
  }

	dismiss() {
		this.modalController.dismiss();
	}

  accept() {
    this.modalController.dismiss({}, 'complete');
  }
}
