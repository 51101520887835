import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IonDatetime, ModalController } from '@ionic/angular';
import { add, format } from 'date-fns';
import { InputComponent } from '../input/input.component';
import { es } from 'date-fns/locale';
import { TranslateService } from '@ngx-translate/core';
import { DatetimeModalComponent } from '../../modal/datetime-modal/datetime-modal.component';
import { enterAnimation, leaveAnimation } from 'src/app/animations/enter';

@Component({
  selector: 'merz-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent extends InputComponent {
  @Input('picker-format') pickerFormat = '';
  @Input('is-block') isBlock = false;
  @ViewChild('pickerDate') pickerDate: IonDatetime;

  formatConvert = {
    'month-year': 'MMMM yyyy',
    date: 'd MMMM yyyy',
  };


  constructor(
    private translateService: TranslateService,
    protected modalController: ModalController,
  ) {
    super();
  }

  ngOnInit() {
    if (this.formControl.value) {
      this.dateToInputController();
      this.date = this.formControl.value;
    }
  }

  inputController = new UntypedFormControl();
  customPickerOptions: any;

  //date to toISOString
  date: Date;


  get cancelText() {
    return this.translateService.instant('BUTTONS.CANCEL');
  }

  get doneText() {
    return this.translateService.instant('BUTTONS.SELECT');
  }

  get maxDate() {
    return this.isBlock
      ? new Date().toISOString()
      : add(new Date(), { years: 10 }).toISOString();
  }

  async openDateTime() {
    const modal = await this.modalController.create({
      component: DatetimeModalComponent,
      enterAnimation,
      leaveAnimation,
      animated: true,
      cssClass: 'datetime-modal',
      componentProps: {
        doneText: this.doneText,
        cancelText: this.cancelText,
        maxDate: this.maxDate,
        date: this.date,
        pickerFormat: this.pickerFormat,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.date = new Date(data.data.date);
        this.formControl.setValue(format(this.date, 'yyyy-MM-dd'));
        this.dateToInputController();
      }
    });
    return await modal.present();
  }

  //format date api format to ion-datetime format
  dateToInputController() {
    let newDate = new Date(this.formControl.value);
    this.inputController.setValue(
      format(newDate, this.formatConvert[this.pickerFormat], { locale: es })
    );
  }

}
