import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'dnest-core-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";
  @Input('text') text = "";
  @Input('text-theme') textTheme: string = "primary";
  @Input('text-type') textType: string = "p-1";
  @Input('selected') selected = false;

  _checkboxController: UntypedFormControl = new UntypedFormControl('');
  @Input('input-controller') set checkboxController(controller: UntypedFormControl) {
    this._checkboxController = controller;
    this.selected = controller.value;
    this._checkboxController.setValue(this.selected);
  }
  get checkboxController(): UntypedFormControl {
    return this._checkboxController;
  }


  constructor() { }

  ngOnInit() {
    this.checkboxController.setValue(this.selected);
  }

  onClick(event: any) {
    this.selected = !this.selected;
    this.checkboxController.setValue(this.selected);
  }

  get hasRequiredError() { return (this.checkboxController.errors as any).required }
  get errorMessage() { return (this.checkboxController.errors as any).value }
  get hasError() {
    if (!!this.checkboxController.errors) {
      this.hasRequiredError
    }
    return false
  }

}
