import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { PollsService } from 'src/app/services/polls-service/polls-service.service';

@Component({
  selector: 'merz-header-polls',
  templateUrl: './header-polls.component.html',
  styleUrls: ['./header-polls.component.scss'],
})
export class HeaderPollsComponent implements OnInit {
  @Input('hide-back-button') hideBackButton = false;

  constructor(
    protected navController: NavController,
    protected pollsService: PollsService
  ) {}

  get percentBar() {
    return this.pollsService.percentBar;
  }

  get indexQuestion() {
    return this.pollsService.indexQuestion;
  }

  ngOnInit(): void {}

  goBack() {
    this.pollsService.goQuestionBack();
  }

  close() {
    this.navController.navigateBack(MERZ_ROUTES.homeComplete, {
      animated: false,
    });
  }
}
