import { Base } from './base';

export class UserWorkCenter extends Base {
  createdDate: string;
  updatedDate: string;
  name: string;
  description: string;
  address: string;
  zipCode: string;
  state: string;
  country: string;
  check:	boolean;
  logo: string;
  changeLogo: string;
  phone: string;
}
