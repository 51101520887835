import { Component, Input, OnInit } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-checkbox-password',
  templateUrl: './input-checkbox-password.component.html',
  styleUrls: ['./input-checkbox-password.component.scss'],
})
export class InputCheckboxPasswordComponent extends InputComponent {
  @Input('comunications') comunication = false;
  @Input('from-configuration') fromConfiguration = false;

  mark(e:Event) {
    e.stopPropagation();
    e.stopImmediatePropagation()
    this.formControl.setValue(!this.formControl.value);
  }

  get privacyLink() {
    return 'https://merzapp.es/politica-de-privacidad';
  }

  get TermsLink() {
    return 'https://merzapp.es/terminos-y-condiciones';
  }

  goToTerms(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (window) {
      window.open(this.TermsLink, '_blank');
    }
  }

  goToPrivacy(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (window) {
      window.open(this.privacyLink, '_blank');
    }
  }
}

