import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookiesSectionComponent } from './cookies-section.component';



@NgModule({
  declarations: [
    CookiesSectionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CookiesSectionComponent
  ]
})
export class CookiesSectionModule { }
