import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'merz-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
})
export class FilterSelectComponent implements OnInit {
  @Output('filter-data') filterDataEvent = new EventEmitter<any>();
  @Input('filter-service') filterService: any;
  @Input() field: string;
  @Input() placeholder: string;
	@Input() options: Option[] = [];
	@Input('option-selected-value') optionSelectedValue: string;

  constructor() { }

  ngOnInit() {
    if(this.filterService){
      this.filterService.pageSize(100).all().subscribe((data) => {
        data['results'].forEach((item) => {
          this.options.push({ text: item.name, value: item.id });
        });
        this.options.unshift({text: 'Ninguno', value: ''});
        this.updateSelected();
      });
    }
    else {
      this.updateSelected();
    }
  }

  updateSelected(){
    if (this.optionSelectedValue) {
      this.options.find(
        (option) => option.value == this.optionSelectedValue
      ).checked = true;
    } else {
      this.options.forEach((option) => (option.checked = false));
    }
  }

  get optionSelected(){
    return this.options.find((option) => option.checked);
  }

  compareWith(o1: Option, o2: Option) {
    return o1 && o2 ? o1.value == o2.value : o1 === o2;
  }

  selectOption(e){
    const option: Option = e.target.value;
    const response = {};
    response[this.field] = option.value;
    this.filterDataEvent.emit(response);
  }
}
