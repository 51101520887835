import { Component, NgZone, OnInit } from '@angular/core';
import { LoadAnimationService } from 'src/app/services/load-animation/load-animation.service';
import "@lottiefiles/lottie-player";
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'merz-load-animation',
  templateUrl: './load-animation.component.html',
  styleUrls: ['./load-animation.component.scss']
})
export class LoadAnimationComponent implements OnInit {

  showLoading = false;

  constructor(
    private loadingService: LoadAnimationService,
    private zone: NgZone,
    public alertService: AlertService
  ) { }

  ngOnInit() {
    this.loadingService.showLoadingEvent.subscribe(
      showLoading => {
        if (showLoading != this.showLoading) {
          this.showLoading = showLoading;
          if (!this.showLoading) {
            setTimeout(() => {
              this.zone.run(() => {});
            }, 1000);
          }
        }
      }
    );
  }

}
