import { Component, Input, OnInit } from '@angular/core';
import { Filter, FilterType } from 'src/app/models/filter';
import { FiltersService } from 'src/app/services/filters-service/filters.service';

@Component({
  selector: 'merz-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent implements OnInit {
	@Input() filters: Filter[] = [];
	filterTypes = FilterType;

  constructor(
    protected filtersService: FiltersService
  ) {}

	ngOnInit() {}

  getfilterData(e) {
    this.filtersService.getfilterData(e);
	}
}

