import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class LogApiClientService extends ApiClientService<any> {
  endpoint = 'log';
}

