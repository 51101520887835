import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeakerFollowBlockComponent } from './speaker-follow-block.component';
import { TextModule } from 'src/app/components/text/text.module';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [
    SpeakerFollowBlockComponent,
  ],
  imports: [
    CommonModule,
    TextModule,
    IonicModule
  ],
  exports: [
    SpeakerFollowBlockComponent,
  ]
})
export class SpeakerFollowBlockModule { }
