// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.block {
  display: flex;
  align-items: center;
  background: var(--ion-color-white);
  padding: 5.5px 8.4px 6.5px 7.5px;
  border-radius: 6px;
  min-width: 49px;
}
.block.double {
  min-width: 98px;
}
.block .sep {
  font-size: 24px;
}
.block .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.block .box merz-text {
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/app/components/date-card-box/date-card-box.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,kCAAA;EACA,gCAAA;EACA,kBAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;AADJ;AAGI;EACE,yBAAA;AADN","sourcesContent":[":host {\n  display: block;\n}\n\n.block {\n  display: flex;\n  align-items: center;\n  background: var(--ion-color-white);\n  padding: 5.5px 8.4px 6.5px 7.5px;\n  border-radius: 6px;\n  min-width: 49px;\n\n  &.double {\n    min-width: 98px;\n  }\n\n  .sep {\n    font-size: 24px;\n  }\n\n  .box {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n\n    merz-text{\n      text-transform: uppercase;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
