import { Injectable, InjectionToken } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { ResetPassword } from '../../../models/reset-password';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordApiClientService extends ApiClientService<ResetPassword> {
  override endpoint = 'rest-auth/password/reset';

  reset(payload: any) {
    return this.create(payload);
  }

  /* get resetPasswordQuestions(): Question<any>[] {
    return [
      new InputQuestion({
        key: 'email',
        label: 'Correo electrónico',
        required: true,
        type: 'email'
      }),
    ];
  } */
}
