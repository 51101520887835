import { Platform } from '@ionic/angular';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  translateService = inject(TranslateService);
  plans; //se guarda el plans aquí del resolver para usar en product-basic-guard

  // galleryCategories = {
  //   do_you_know: '¿Sabías qué…?',
  //   before_after: 'Antes & después',
  //   clippings: 'Clippings',
  //   quote_of_day: 'Frase del día',
  //   lifestyle: 'Lifestyle',
  //   myths_realities: 'Mitos y realidades',
  // };

  get ocupations() {
    return this.currentLanguage === 'es'
      ? this.ocupationsSpanish
      : this.ocupationsPortuguese;
  }

  ocupationsSpanish = {
    esthetic_medicine: 'Medicina estética',
    plastic_surgery: 'Cirugía plástica',
    maxillofacial_surgery: 'Cirugía maxilofacial',
    dermatology: 'Dermatología',
    clinic_owner: 'Propietario de clinica',
    clinic_employee: 'Empleado de clínica',
    merz_staff: 'Personal de Merz',
    ophthalmology: 'Oftalmología',
    other: 'Otra',
  };

  ocupationsPortuguese = {
    esthetic_medicine: 'Medicina Estética',
    plastic_surgery: 'Cirugía Plástica',
    maxillofacial_surgery: 'Cirugia Maxilofacial',
    dermatology: 'Dermatologia',
    clinic_owner: 'Dono da clínica',
    clinic_employee: 'Empregado da clínica',
    merz_staff: 'Personal de Merz',
    ophthalmology: 'Oftalmologia',
    other: 'Outra',
  };

  get ocupationsNoMedical() {
    return this.currentLanguage === 'es'
      ? this.ocupationsNoMedicalSpanish
      : this.ocupationsNoMedicalPortuguese;
  }

  ocupationsNoMedicalSpanish = {
    owner: 'Dueño de clínica',
    employee: 'Empleado de clínica',
    personal_merz: 'Personal Merz',
    other: 'Otra',
  };

  ocupationsNoMedicalPortuguese = {
    owner: 'Dono da clínica',
    employee: 'Empregado da clínica',
    personal_merz: 'Personal Merz',
    other: 'Outra',
  };

  get currentLanguage() {
    return this.translateService.currentLang;
  }

  get monthsToText() {
    return this.currentLanguage === 'es'
      ? this.monthsToTextSpanish
      : this.monthsToTextPortuguese;
  }

 monthsToTextSpanish = {
    0: 'ene',
    1: 'feb',
    2: 'mar',
    3: 'abr',
    4: 'may',
    5: 'jun',
    6: 'jul',
    7: 'agto',
    8: 'sept',
    9: 'oct',
    10: 'nov',
    11: 'dic',
  };

  monthsToTextPortuguese = {
    0: 'jan',
    1: 'fev',
    2: 'mar',
    3: 'abr',
    4: 'maio',
    5: 'jun',
    6: 'jul',
    7: 'ago',
    8: 'set',
    9: 'out',
    10: 'nov',
    11: 'dez',
  };

  get monthsToTextComplete() {
    return this.currentLanguage === 'es'
      ? this.monthsToTextCompleteSpanish
      : this.monthsToTextCompletePortuguese;
  }

  monthsToTextCompleteSpanish = {
    0: 'enero',
    1: 'febrero',
    2: 'marzo',
    3: 'abril',
    4: 'mayo',
    5: 'junio',
    6: 'julio',
    7: 'agosto',
    8: 'septiembre',
    9: 'octubre',
    10: 'noviembre',
    11: 'diciembre',
  };

  monthsToTextCompletePortuguese = {
    0: 'janeiro',
    1: 'fevereiro',
    2: 'março',
    3: 'abril',
    4: 'maio',
    5: 'junho',
    6: 'julho',
    7: 'agosto',
    8: 'setembro',
    9: 'outubro',
    10: 'novembro',
    11: 'dezembro',
  };

  get monthsToTextCompleteUppercase() {
    return this.currentLanguage === 'es'
      ? this.monthsToTextCompleteUppercaseSpanish
      : this.monthsToTextCompleteUppercasePortuguese;
  }

  monthsToTextCompleteUppercaseSpanish = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre',
  };

  monthsToTextCompleteUppercasePortuguese = {
    0: 'Janeiro',
    1: 'Fevereiro',
    2: 'Março',
    3: 'Abril',
    4: 'Maio',
    5: 'Junho',
    6: 'Julho',
    7: 'Agosto',
    8: 'Setembro',
    9: 'Outubro',
    10: 'Novembro',
    11: 'Dezembro',
  };

  get daysToText() {
    return this.currentLanguage === 'es'
      ? this.daysToTextSpanish
      : this.daysToTextPortuguese;
  }

  daysToTextSpanish = {
    0: 'dom',
    1: 'lun',
    2: 'mar',
    3: 'mier',
    4: 'juev',
    5: 'vier',
    6: 'sab',
  };

  daysToTextPortuguese = {
    0: 'dom',
    1: 'seg',
    2: 'ter',
    3: 'qua',
    4: 'qui',
    5: 'sex',
    6: 'sáb',
  };

  get daysToTextComplete() {
    return this.currentLanguage === 'es'
      ? this.daysToTextCompleteSpanish
      : this.daysToTextCompletePortuguese;
  }

  daysToTextCompleteSpanish = {
    0: 'Domingo',
    1: 'Lunes',
    2: 'Martes',
    3: 'Miércoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sábado',
  };

  daysToTextCompletePortuguese = {
    0: 'Domingo',
    1: 'Segunda-feira',
    2: 'Terça-feira',
    3: 'Quarta-feira',
    4: 'Quinta-feira',
    5: 'Sexta-feira',
    6: 'Sábado',
  };


  constructor(
    protected plataform: Platform
  ) { }


  get isDesktop(){
    return true;
  }
}
