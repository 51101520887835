// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --min-height: 22px;
  --padding-start: 0;
  --inner-border-width: 0;
  --inner-padding-end: 0;
  --background-activated: var(--ion-color-white);
  margin-bottom: 15px;
}
ion-item:last-child {
  margin-bottom: 0;
}

ion-checkbox {
  --border-radius: 3px;
  --size: 22px;
  --checkmark-width: 3px;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/filter/filter-checkbox/filter-checkbox.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,uBAAA;EACA,sBAAA;EACA,8CAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAIA;EACE,oBAAA;EACA,YAAA;EACA,sBAAA;EACA,SAAA;AADF","sourcesContent":["ion-item {\n  --min-height: 22px;\n  --padding-start: 0;\n  --inner-border-width: 0;\n  --inner-padding-end: 0;\n  --background-activated: var(--ion-color-white);\n  margin-bottom: 15px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n}\n\nion-checkbox {\n  --border-radius: 3px;\n  --size: 22px;\n  --checkmark-width: 3px;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
