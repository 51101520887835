import { Injectable } from '@angular/core';
import { PollApiClientService } from '../api/poll-api-client/poll-api-client.service';
import { ModalController, NavController } from '@ionic/angular';
import { PollModalComponent } from 'src/app/components/modal/poll-modal/poll-modal.component';
import { Poll } from 'src/app/models/poll';
import { MERZ_ROUTES } from 'src/app/merz-routes';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PollQuestion } from 'src/app/models/poll-question';
import { Option } from 'src/app/models/option';
import { PollQuestionOption } from 'src/app/models/poll-question-option';
import { QuestionType } from 'src/app/models/question-type';
import { PollResult } from 'src/app/models/poll-result';

@Injectable({
  providedIn: 'root',
})
export class PollsService {
  pollResult: PollResult;
  polls: Poll[] = [];
  poll: Poll;
  currentQuestion: PollQuestion;
  questionOptionsToInputs: Option[] = [];
  indexQuestion = 0;

  formGroup: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    protected pollApiClientService: PollApiClientService,
    protected modalController: ModalController,
    protected navController: NavController
  ) {}

  get questionsCount() {
    return this.poll?.questions?.length;
  }

  get totalPartsPercentBar() {
    return this.questionsCount + 1;
  }

  get percentBar() {
    return ((this.indexQuestion + 1) * 100) / this.totalPartsPercentBar;
  }

  updateCurrentQuestionAndData() {
    this.currentQuestion = this.poll.questions[this.indexQuestion];
    this.getQuestionOptions(this.currentQuestion);
  }

  goQuestionBack() {
    if (!this.currentQuestion.isMainFlow) {
      this.updateCurrentQuestionAndData();
      return;
    }

    if (this.indexQuestion === 0) {
      this.navController.navigateBack(MERZ_ROUTES.homeComplete, {
        animated: false,
      });
    } else {
      this.indexQuestion -= 1;
      this.updateCurrentQuestionAndData();
    }
  }

  get optionSelectOfCurrentQuestion(): PollQuestionOption {
    return this.currentQuestion.options.find(
      (option) =>
        option.title ===
        this.formGroup.get(String(this.currentQuestion.id)).value
    );
  }

  goQuestionNext() {
    if (
      this.currentQuestion.isMainFlow &&
      this.currentQuestion.questionType === QuestionType.RADIO &&
      this.optionSelectOfCurrentQuestion &&
      this.optionSelectOfCurrentQuestion.isConditional
    ) {
      //Vamos a ir la pregunta fuera del flujo principal
      this.currentQuestion = this.poll.questionsAlternative.find(
        (question) =>
          question.id === this.optionSelectOfCurrentQuestion.gotoQuestion
      );
      this.getQuestionOptions(this.currentQuestion);
    } else if (this.indexQuestion === this.questionsCount - 1) {
      //Si es la ultima pregunta, enviamos las respuestas
      this.submitPoll();
    } else {
      this.indexQuestion += 1;
      this.updateCurrentQuestionAndData();
    }
  }

  getQuestionOptions(question: PollQuestion): void {
    const formGroupValue = this.formGroup.get(String(question.id)).value;

    this.questionOptionsToInputs = question.options.map((option) => {
      const isChecked = Array.isArray(formGroupValue)
        ? formGroupValue.includes(option.title)
        : formGroupValue === option.title;

      return {
        text: option.title || '',
        value: option.title,
        checked: isChecked,
        order: option.order,
      };
    });
  }

  createFormGroup() {
    let group = {};
    this.poll?.questions.forEach((question) => {
      group[String(question.id)] = new UntypedFormControl('', [
        Validators.required,
      ]);
    });
    this.poll?.questionsAlternative.forEach((question) => {
      group[String(question.id)] = new UntypedFormControl('', [
        Validators.required,
      ]);
    });
    this.formGroup = new UntypedFormGroup(group);

    this.updateCurrentQuestionAndData();
  }

  submitPoll() {
    const results = this.formGroup.getRawValue();

    let answersResult = [];
    Object.keys(results).forEach((key) => {
      let result = {
        question: key,
        text: Array.isArray(results[key]) ? results[key].join('##') : results[key],
      };
      answersResult.push(result);
    });

    this.pollApiClientService
      .action({ id: this.poll.id }, 'finish', { answers: answersResult })
      .subscribe({
        next: () => {
          this.indexQuestion =  this.questionsCount;
          //Go to finish poll page
          this.navController.navigateForward([MERZ_ROUTES.pollFinishComplete.replace('${pollId}', this.poll.id)]);
        },
      });
  }

  async openPollsModal(title, subtitle, pollId) {
    const modal = await this.modalController.create({
      component: PollModalComponent,
      componentProps: {
        title,
        subtitle,
        pollId,
      },
      cssClass: 'modal-polls',
    });
    return await modal.present();
  }

  /**
   * Obtiene todas las encuestas y si existe alguna, abre el modal de la primera encuesta
   *
   * @returns void
   */
  getPolls() {
    this.pollApiClientService.all().subscribe((data) => {
      this.polls = data.results;
      //si existe alguna encuesta, abrimos el modal y le pasamos el titulo y el subtitulo de la primera encuesta
      if (this.polls.length) {
        this.openPollsModal(
          this.polls[0]?.title,
          this.polls[0]?.subtitle,
          this.polls[0]?.id
        );
      }
    });
  }
}
