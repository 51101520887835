// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --min-height: 48px;
  --padding-start: 14px;
  --inner-border-width: 0;
  --inner-padding-end: 14px;
  --background-activated: var(--ion-color-white);
  border-radius: 5px;
  border: 1px solid var(--ion-color-lines);
}

ion-select {
  width: 100%;
  max-width: 100%;
}

ion-select::part(placeholder) {
  width: 100vw;
  opacity: 0.33;
}

ion-select::part(text) {
  width: 100vw;
}

ion-select::part(icon) {
  color: var(--ion-color-black);
  opacity: 0.33;
  transform: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/filter/filter-select/filter-select.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,uBAAA;EACA,yBAAA;EACA,8CAAA;EACA,kBAAA;EACA,wCAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,6BAAA;EACA,aAAA;EACA,eAAA;AACF","sourcesContent":["ion-item {\n  --min-height: 48px;\n  --padding-start: 14px;\n  --inner-border-width: 0;\n  --inner-padding-end: 14px;\n  --background-activated: var(--ion-color-white);\n  border-radius: 5px;\n  border: 1px solid var(--ion-color-lines);\n}\n\nion-select {\n  width: 100%;\n  max-width: 100%;\n}\n\nion-select::part(placeholder) {\n  width: 100vw;\n  opacity: 0.33;\n}\n\nion-select::part(text) {\n  width: 100vw;\n}\n\nion-select::part(icon) {\n  color: var(--ion-color-black);\n  opacity: 0.33;\n  transform: none;\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
