// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-card {
  display: block;
  padding: 5px;
  width: 169px;
  height: 188px;
}
ion-card img {
  min-height: 119px;
  height: 119px;
  width: 100%;
  object-fit: cover;
}
ion-card ion-card-content {
  padding: 0 9px 6px;
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/success-story-card/success-story-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAGA;EACE,cAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;AAAF;AAGE;EACE,iBAAA;EACA,aAAA;EACA,WAAA;EACA,iBAAA;AADJ;AAIE;EACE,kBAAA;EACA,iBAAA;AAFJ","sourcesContent":[":host {\n  display: block;\n}\n\n\nion-card {\n  display: block;\n  padding: 5px;\n  width: 169px;\n  height: 188px;\n\n\n  img {\n    min-height: 119px;\n    height: 119px;\n    width: 100%;\n    object-fit: cover;\n  }\n\n  ion-card-content {\n    padding: 0 9px 6px;\n    text-align: start;\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
