// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--ion-color-black);
  padding: 14px;
  border-radius: 5px;
}
.row .button {
  border-radius: 5px;
  display: grid;
  place-items: center;
  width: 98px;
  height: 31px;
  background: var(--ion-color-plans-bg);
}`, "",{"version":3,"sources":["webpack://./src/app/components/small-see-plans/small-see-plans.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,wCAAA;EACA,aAAA;EACA,kBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,qCAAA;AAAN","sourcesContent":["\n  .row {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: var(--ion-color-black);\n    padding: 14px;\n    border-radius: 5px;\n\n    .button {\n      border-radius: 5px;\n      display: grid;\n      place-items: center;\n      width: 98px;\n      height: 31px;\n      background: var(--ion-color-plans-bg);\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
