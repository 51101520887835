import { Injectable } from '@angular/core';
import { AddressFormatterService } from '../address-formatter/address-formatter.service';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAutocompleteService {

  constructor(
    protected addressFormatterService: AddressFormatterService,
  ) { }

  getPlaceAutocomplete(addressInput: any, inputController: any) {
    const autocomplete =
      new google.maps.places.Autocomplete(addressInput.nativeElement, { componentRestrictions: { country: 'ES' } });
    //TODO: BAD PRACTICE: Se debe crear un observable desde FromEvent y todo queda en observables
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place, inputController);
    });
  }

  invokeEvent(place: any, inputController: any) {
    inputController.setValue(place['formatted_address']);
    this.addressFormatterService.splitAddress(place['address_components'], place['name'], place['formatted_address']);
  }
}
