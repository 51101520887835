import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'merz-filter-checkbox',
  templateUrl: './filter-checkbox.component.html',
  styleUrls: ['./filter-checkbox.component.scss'],
})
export class FilterCheckboxComponent implements OnInit {
  @Output('filter-data') filterDataEvent = new EventEmitter<any>();
  @Input('filter-service') filterService: any;
  @Input() field: string;
  @Input() options: Option[] = [];
  @Input('option-selected-value') optionSelectedValue: string[];

  constructor() { }

  ngOnInit() {
    if (this.filterService) {
      this.filterService.all().subscribe((data) => {
        data['results'].forEach((item) => {
          this.options.push({ text: item.name, value: item.id });
        });
        this.updateSelectedOptions();
      });
    }
    else {
      this.updateSelectedOptions();
    }
  }

  updateSelectedOptions() {
    if (this.optionSelectedValue) {
      this.options.filter((option) => this.optionSelectedValue.includes(option.value)).map((option) => option.checked = true);
    } else {
      this.options.forEach((option) => (option.checked = false));
    }
  }

  filter() {
    const response = {};
    const idResults = this.options.filter((option) => option.checked).map((option) => option.value);
    response[this.field] = idResults;


    this.filterDataEvent.emit(response);
  }
}
