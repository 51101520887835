import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { Video } from 'src/app/models/video';

@Injectable({
  providedIn: 'root',
})
export class VideoTopApiClientService extends ApiClientService<Video> {
  endpoint = 'topcarousel/video';
}

