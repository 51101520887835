import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  storage: Storage = localStorage;

  REGISTER_PASSWORD_BACK = 'register-password-back';
  REGISTER_WORKCENTER_BACK = 'register-workcenter-back';
  CREATE_WORKCENTER_NAME = 'create-workcenter-name';
  SHOW_POLL = 'show-poll';
  SUCCESS_STORY_IMAGE = 'success-story-image';
  SUCCESS_STORY_IMAGE2 = 'success-story-image2';
  RECENTLY_SEARCHS = 'recently-searchs';

  LOGIN_TYPE = 'login-type';
  LOGIN_BY_EMAIL = 'login-by-email';
  LOGIN_BY_PHONE = 'login-by-phone';
  RECOVER_PASSWORD_CODE = 'recover-password-code';
  SEND_CODE_FROM = 'send-code-from';

  LOGIN_BY_FIRST_NAME = 'login-by-first-name';
  LOGIN_BY_LAST_NAME = 'login-by-last-name';
  LOGIN_BY_COUNTRY = 'login-by-country';

  PAGE_TO_GO_AFTER_LOGIN = 'page-to-go-after-login';

  constructor() {}

  getObject(name: string) {
    try {
      return JSON.parse(this.storage.getItem(name));
    } catch {
      return undefined;
    }
  }

  saveObject(name: string, value) {
    this.storage.setItem(name, JSON.stringify(value));
  }

  deleteObject(name: string) {
    this.storage.removeItem(name);
  }

  deleteSessionData() {
    this.storage.clear();
  }
}
