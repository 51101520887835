import { Component, OnInit, Input } from '@angular/core';
import { Filter, FilterType } from '../../models/filter/filter';

@Component({
  selector: 'dnest-core-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  host: {
    "(window:click)": "onWindowClick()"
  }
})
export class FiltersComponent implements OnInit {
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";
  @Input("filters") filters: Filter[] = [];
  @Input("no-padding") noPadding: boolean = false;
  @Input('arrow') arrow: string = "" ;


  hideMoreFiltersTimeout: any;
  searchFilter: any;

  filterType = FilterType;

  constructor() { }

  ngOnInit() {
  }

  onFocusInSearch(event: any, parentFilter: Filter) {
    this.searchFilter = undefined;
    console.log('in', this.searchFilter);
    if (this.hideMoreFiltersTimeout) {
      clearTimeout(this.hideMoreFiltersTimeout);
    }
  }

  onFocusOutSearch(event: any, parentFilter: Filter) {
    this.searchFilter = parentFilter;
    console.log('out', this.searchFilter.showMoreFilters);
  }

  toggleMoreFilterShow(event: any, parentFilter: Filter) {
    event.stopPropagation();
    parentFilter.showMoreFilters = !parentFilter.showMoreFilters;
    if (parentFilter.showMoreFilters) {
      this.searchFilter = parentFilter;
    } else {
      this.searchFilter = undefined;
    }
  }

  onWindowClick() {
    if (this.searchFilter) {
      console.log('window', this.searchFilter.showMoreFilters);
      this.hideMoreFiltersTimeout = setTimeout(() => {
        this.searchFilter.showMoreFilters = false;
        console.log('event', this.searchFilter.showMoreFilters);
        this.searchFilter = undefined;
      }, 250);
    }
  }

}
