import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-recaptcha',
  templateUrl: './input-recaptcha.component.html',
  styleUrls: ['./input-recaptcha.component.scss'],
})
export class InputRecaptchaComponent extends InputComponent {
  YOUR_SITE_KEY = '6LdRFNYqAAAAAFlaIebwbEv4MWxzUA1T0BGUZCu7';
  constructor(
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  resolved(captchaResponse: string) {
    this.formControl.setValue(captchaResponse);
  }
}
