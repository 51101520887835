import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found.component';
import { TextModule } from 'src/app/components/text/text.module';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [NotFoundComponent],
  imports: [CommonModule, TextModule, IonicModule],
  exports: [NotFoundComponent],
})
export class NotFoundModule {}
