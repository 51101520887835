import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Plan } from 'src/app/models/plan';
import { User } from 'src/app/models/user';
import { PlanApiClientService } from 'src/app/services/api/plan-api-client/plan-api-client.service';
import { PlansModalComponent } from '../modal/plans-modal/plans-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'merz-small-see-plans',
  templateUrl: './small-see-plans.component.html',
  styleUrls: ['./small-see-plans.component.scss'],
})
export class SmallSeePlansComponent implements OnInit {
  @Input() user: User;
  plans: Plan[] = [];

  constructor(
    protected planApiClientService: PlanApiClientService,
    protected modalController: ModalController,
    protected translateService: TranslateService
  ) {}

  ngOnInit() {
    this.planApiClientService.all().subscribe({
      next: (result) => (this.plans = result.results),
    });
  }

  get planName() {
    return `Plan ${this.user?.plan?.name}`;
  }

  get topText() {
    return this.user?.isKam
      ? `${this.translateService.instant('MAIN_PROFILE.COMMERCIAL_ACCOUNT')}`
      : this.planName;
  }

  async openPlans(event?) {
    const modal = await this.modalController.create({
      component: PlansModalComponent,
      componentProps: {
        userPlanId: this.user?.plan?.id,
        plans: this.plans,
        adminPhone: this.user?.kam?.phoneMobile,
      },
      cssClass: 'modal-plans-options',
    });
    return await modal.present();
  }
}
