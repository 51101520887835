import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InputErrorComponent } from '../input-error/input-error.component';

@Component({
  selector: 'merz-input-readonly',
  templateUrl: './input-readonly.component.html',
  styleUrls: ['./input-readonly.component.scss'],
})
export class InputReadonlyComponent extends InputErrorComponent {

  @Input() label = '';
	@Input() description = '';
	@Input() type = 'text';
	@Input() hint = '';
	@Input() placeholder = '';
	@Input() format = '';
	@Input() autofocus = false;
	@Input() tabindex;
	@Input('is-block') isBlock = false;
	@Input('no-floated-label') nofloatedLabel = true;
	@Input('margin-right') marginRight = 15;

	@Output() enter = new EventEmitter<boolean>();
  @ViewChild('inputField') inputField: ElementRef;

	focus = false;

	ngOnInit() {}

	onFocus(event) {
		this.focus = event.returnValue;
	}

	ngAfterViewInit() {
		if (this.autofocus) {
			setTimeout(() => this.inputField.nativeElement.focus(), 200);
		}
	}
}

