import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { UserWorkCenter } from 'src/app/models/user-work-center';

@Injectable({
  providedIn: 'root'
})
export class UserWorkCenterApiClientService extends ApiClientService<UserWorkCenter> {
  endpoint = 'userworkcenter';
}

