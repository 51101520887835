import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterGroupComponent } from './filter-group.component';
import { FilterModule } from '../filter/filter.module';
import { TextModule } from '../text/text.module';



@NgModule({
  declarations: [FilterGroupComponent],
  imports: [
    CommonModule,
    FilterModule,
    TextModule
  ],
  exports: [FilterGroupComponent]
})
export class FilterGroupModule { }
