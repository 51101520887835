import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dnest-core-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {
  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";
  @Input('type') type:string = "p-1";
  @Input('text') text: string = "";
  @Input('text-align') align: string = "left";
  @Input('font-size') size: any;
  @Input('weight') weight: any;
  @Input('text-transform') textTransform: string = "";
  @Input('max-width') maxWidth: any;
  @Input('ellipsis') ellipsis: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
