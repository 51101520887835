import { Injectable } from '@angular/core';
import { BaseDetailResolver } from './base-detail.resolver';
import { Video } from 'src/app/models/video';
import { VideoApiClientService } from '../api/video-api-client/video-api-client.service';
import { UserWorkCenter } from 'src/app/models/user-work-center';
import { UserWorkCenterApiClientService } from '../api/user-work-center-api-client/user-work-center-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class UserWorkCenterResolver extends BaseDetailResolver<UserWorkCenter> {
  protected idParam = 'workcenterId';

  constructor(protected service: UserWorkCenterApiClientService) {
    super();
  }
}
