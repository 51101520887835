import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CountryApiClientService } from '../api/country-api-client/country-api-client.service';
import { UserDetailService } from '../user-detail-service/user-detail.service';
import { BaseListResolver } from './base-list.resolver';

@Injectable({
  providedIn: 'root',
})
export class CountriesResolver extends BaseListResolver<any> {
  protected pageSize = 1000;

  constructor(
    protected service: CountryApiClientService,
    protected userDetailService: UserDetailService
  ) {
    super();
  }

  resolve(): Observable<any> {
    return this.service
      .pageSize(this.pageSize)
      .all()
      .query.execute()
      .pipe(
        map((data) => {
          this.userDetailService.countries = data['results'];
        }),
        catchError((error) => {
          return of(undefined);
        })
      );
  }
}
