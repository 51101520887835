import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseDetailResolver } from './base-detail.resolver';
import { UserDetailService } from '../user-detail-service/user-detail.service';
import { UserpreferencesApiClientService } from '../api/userpreferences-api-client/userpreferences-api-client.service';
import { UserPreferences } from 'src/app/models/user-preferences';
import { TranslateService } from '@ngx-translate/core';
import { FiltersGetOptionsService } from '../filters-get-options-service/filters-get-options.service';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root',
})
export class LanguageResolver extends BaseDetailResolver<any> {
  constructor(
    protected userDetailService: UserDetailService,
    protected service: UserpreferencesApiClientService,
    protected translateService: TranslateService,
    protected filtersGetOptionsService: FiltersGetOptionsService
  ) {
    super();
  }

  resolve(): Observable<any> {
    return this.service.get('1').pipe(
      map((userPreference: UserPreferences) => {
        this.userDetailService.language = userPreference.language;

        if (userPreference.language) {
          this.translateService.use(userPreference.language);
        } else {
          console.log('No language found');
          Device.getLanguageCode().then((language) => {
            let deviceLanguageIncluded =
              this.userDetailService.availableLanguages.includes(
                language.value
              );

            let languageSelected = deviceLanguageIncluded
              ? language.value
              : this.translateService.getDefaultLang();
            this.service
              .edit({ id: '1', language: languageSelected })
              .subscribe();
          });
        }

        this.filtersGetOptionsService.getOptionsFilters();
      }),
      catchError((error) => {
        Device.getLanguageCode().then((language) => {
          let deviceLanguageIncluded =
            this.userDetailService.availableLanguages.includes(language.value);
          let languageSelected = deviceLanguageIncluded
            ? language.value
            : this.translateService.getDefaultLang();
          this.service
            .create({ id: '1', language: languageSelected })
            .subscribe();
        });
        return of(undefined);
      })
    );
  }
}
