export class FilterOption {
  key: string;

  constructor(
    public value: any,
    name: string,
    public selected?: boolean /* = false */) {
    this.key = name;
  }

  get name() {
    return this.key;
  }
}
