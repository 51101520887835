import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';

@Component({
  selector: 'merz-header-edit-user',
  templateUrl: './header-edit-user.component.html',
  styleUrls: ['./header-edit-user.component.scss']
})
export class HeaderEditUserComponent implements OnInit {
  @Input() id = '';

  constructor(
    protected navController: NavController,
    ) {
	}
	ngOnInit() {}

	goBack() {
    return this.navController.navigateBack([MERZ_ROUTES.myUsersComplete]);
  }

  goToEditUser(){
    this.navController.navigateForward([MERZ_ROUTES.profileMyUserEditEditComplete.replace('${kamUserId}', this.id)]);
  }
}
