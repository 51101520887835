import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Formation } from 'src/app/models/formation';
import { UnfollowCoursePopoverComponent } from '../../popover/unfollow-course-popover/unfollow-course-popover.component';

@Component({
  selector: 'merz-profile-course-card',
  templateUrl: './profile-course-card.component.html',
  styleUrls: ['./profile-course-card.component.scss'],
})
export class ProfileCourseCardComponent implements OnInit {
  @Input() formation: Formation;
  @Input() online = false;

  constructor(protected popoverController: PopoverController) { }

  ngOnInit(): void {
  }


  get duration(){
    return `${this.formation?.start.substr(11,5)} - ${this.formation?.end.substr(11,5)}`;
  }

  async presentPopover(ev: Event) {
    ev.stopPropagation();
    const popover = await this.popoverController.create({
      component: UnfollowCoursePopoverComponent,
      cssClass: 'popover-class',
      componentProps: {
        formationId: this.formation.id
      },
      event: ev,
    });
    await popover.present();
  }
}
