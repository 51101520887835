import { Injectable } from '@angular/core';
import { AddressFormatterService } from '../address-formatter/address-formatter.service';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LocalValidationsService {

  private _isValidAddress: boolean = false;
  public get isValidAddress(): boolean { return this._isValidAddress; }
  public set isValidAddress(value: boolean) { this._isValidAddress = value; }

  constructor(
    protected addressFormatterService: AddressFormatterService,
  ) { }

  addressValidator(formGroup: UntypedFormGroup, addressContolName: string) {
    let hasAddress = this.addressFormatterService.hasAddress;

    if (hasAddress && this.addressHasNumber()) {
      this.isValidAddress = true;
    }

    if (!hasAddress) {
      this.isValidAddress = false;
      formGroup.controls[addressContolName]
        .setErrors({ addressValidation: true, value: 'Comprueba que has seleccionado correctamente una de las direcciones disponibles en el desglose' })
    }

    if (hasAddress && !this.addressHasNumber()) {
      this.isValidAddress = false;
      formGroup.controls[addressContolName]
        .setErrors({ addressValidation: true, value: 'Introduce el número de la calle' })
    }

  }

  addressHasNumber() {
    return this.addressFormatterService.hasStreetNumber;
  }
}
