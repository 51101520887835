import { Injectable } from '@angular/core';
import { BaseDetailResolver } from './base-detail.resolver';
import { User } from 'src/app/models/user';
import { KamUserApiClientService } from '../api/kam-user-api-client/kam-user-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class KamUserResolver extends BaseDetailResolver<User> {
  protected idParam = 'kamUserId';

  constructor(protected service: KamUserApiClientService
    ) {
    super();
  }
}
