import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GoogleAutocompleteService } from '../../services/google-autocomplete/google-autocomplete.service';

@Component({
  selector: 'dnest-core-input-address-autocomplete',
  templateUrl: './input-address-autocomplete.component.html',
  styleUrls: ['./input-address-autocomplete.component.scss']
})
export class InputAddressAutocompleteComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('addresstext', { static: true }) addresstext: any;

  /**
   * Primary by default
   */
  @Input('theme') theme: string = "primary";

  @Input('type') inputType = "text";
  @Input('input-controller') inputController: UntypedFormControl = new UntypedFormControl('');
  @Input('title') title = "";
  @Input('placeholder') placeholder = "";
  @Input('autocomplete') autocomplete = "";
  @Input('enable') enable = true;
  @Input('required') required: boolean = false;

  @Input('margin-left-icon') marginLeftIcon = 12;
  @Input('icon-size') iconSize = 24;
  @Input('margin-left') marginLeft = 7;
  @Input('margin-right') marginRight = 15;
  @Input('margin-top-clone') marginTopClone = -15;

  @Input('autofocus') autofocus = false;
  @Input('focused') focused: boolean = false;
  @Input('has-hint') hasHint: boolean = false;
  @Input('hints') hints: string[] = [];
  @Input('read-only') readOnly: boolean = false

  @Output('on-enter') onEnterEvent = new EventEmitter<any>();
  @Output('on-focus') onFocusEvent = new EventEmitter<any>();
  @Output('on-blur') onBlurEvent = new EventEmitter<any>();

  inputPaddingTop = 1;
  inputPaddingBottom = 1;
  marginTopCloneSecond = this.marginTopClone;

  constructor(
    protected googleAutocompleteService: GoogleAutocompleteService,
  ) {
  }

  ngOnInit() {
    this.calculate();
  }

  ngAfterViewInit() {
    this.googleAutocompleteService.getPlaceAutocomplete(this.addresstext, this.inputController);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculate();
  }

  calculate() {
    this.inputPaddingTop = 1;
    this.inputPaddingBottom = 1;
    this.marginTopCloneSecond = this.marginTopClone;
  }

  onEnter(event: any) {
    this.onEnterEvent.emit(event);
  }

  onFocus(event: any) {
    this.focused = true;
    this.onFocusEvent.emit(event);
  }

  onBlur(event: any) {
    this.focused = false;
    this.onBlurEvent.emit(event);
  }

  get hasTitle() { return this.title.length > 0; }
  get cssClasses() {
    let classes = {
      'container-error': this.inputController && this.inputController.invalid &&
        this.inputController.touched && (this.hasRequiredError ||
          this.hasRequiredError || this.hasAddressError),
      'focused': this.focused,
      'input-disabled': !this.enable,
      'container-hint': this.hasHint,
    };
    (classes as any)[this.theme] = true;
    return classes;
  }

  // VALIDATIONS
  get hasRequiredError() { return (this.inputController.errors as any).required }
  get hasAddressError() { return (this.inputController.errors as any).addressValidation }
  get errorMessage() { return (this.inputController.errors as any).value }
}
