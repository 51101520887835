// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  height: 32px;
  width: 100%;
  min-width: 367px;
  max-width: 367px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: var(--ion-color-white20);
  border-radius: 16px;
}
.input-container.focus {
  background: var(--ion-color-white);
}
.input-container ion-icon {
  min-width: 20px;
  font-size: 20px;
  margin-right: 4px;
  color: var(--ion-color-white);
}
.input-container ion-icon.focus {
  color: var(--ion-color-medium);
}
.input-container input {
  width: 100%;
  border: none;
  background: transparent;
  font-family: var(--ion-font-family);
  color: var(--ion-color-white80);
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  -webkit-appearance: none;
  outline: unset;
}
.input-container input:focus {
  color: var(--ion-color-dark);
}
.input-container input::placeholder {
  color: var(--ion-color-white80);
  font-size: 14px;
  font-weight: normal;
}
.input-container input:focus::placeholder {
  color: var(--ion-color-light);
}`, "",{"version":3,"sources":["webpack://./src/app/components/input/input-search/input-search.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,oCAAA;EACA,mBAAA;AACF;AACE;EACE,kCAAA;AACJ;AAEE;EACE,eAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;AAAJ;AAEI;EACE,8BAAA;AAAN;AAIE;EACE,WAAA;EACA,YAAA;EACA,uBAAA;EAEA,mCAAA;EACA,+BAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,wBAAA;EACA,cAAA;AAHJ;AAKI;EACE,4BAAA;AAHN;AAMI;EACE,+BAAA;EACA,eAAA;EACA,mBAAA;AAJN;AAOI;EACE,6BAAA;AALN","sourcesContent":[".input-container {\n  height: 32px;\n  width: 100%;\n  min-width: 367px;\n  max-width: 367px;\n  display: flex;\n  align-items: center;\n  padding: 0 10px;\n  background: var(--ion-color-white20);\n  border-radius: 16px;\n\n  &.focus {\n    background: var(--ion-color-white);\n  }\n\n  ion-icon {\n    min-width: 20px;\n    font-size: 20px;\n    margin-right: 4px;\n    color: var(--ion-color-white);\n\n    &.focus {\n      color: var(--ion-color-medium);\n    }\n  }\n\n  input {\n    width: 100%;\n    border: none;\n    background: transparent;\n\n    font-family: var(--ion-font-family);\n    color: var(--ion-color-white80);\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 17px;\n    -webkit-appearance: none;\n    outline: unset;\n\n    &:focus {\n      color: var(--ion-color-dark);\n    }\n\n    &::placeholder {\n      color: var(--ion-color-white80);\n      font-size: 14px;\n      font-weight: normal;\n    }\n\n    &:focus::placeholder {\n      color: var(--ion-color-light);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
