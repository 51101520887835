// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  margin-top: auto;
  display: block;
  z-index: 200;
  background-color: var(--ion-color-white);
}

.footer-content {
  width: 100%;
  background: var(--ion-color-white);
  padding: 15px 0;
}
.footer-content .links {
  display: flex;
  align-items: center;
  gap: 5px;
}
.footer-content .links merz-text {
  cursor: pointer;
}
.footer-content .texts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .footer-content .texts {
    flex-direction: row;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,cAAA;EACA,YAAA;EACA,wCAAA;AACF;;AAEA;EACE,WAAA;EACA,kCAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AACI;EACE,eAAA;AACN;AAGE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,sBAAA;AADJ;;AAKA;EAEI;IACE,mBAAA;EAHJ;AACF","sourcesContent":[":host {\n  width: 100%;\n  margin-top: auto;\n  display: block;\n  z-index: 200;\n  background-color: var(--ion-color-white);\n}\n\n.footer-content {\n  width: 100%;\n  background: var(--ion-color-white);\n  padding: 15px 0;\n\n  .links {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n\n    merz-text {\n      cursor: pointer;\n    }\n  }\n\n  .texts {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    flex-direction: column;\n  }\n}\n\n@media only screen and (min-width: 768px) {\n  .footer-content {\n    .texts {\n      flex-direction: row;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
