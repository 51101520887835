import { Injectable } from '@angular/core';
import { FormComponent } from '../../components/form/form.component';
import { HeaderBackComponent } from '../../components/header/header-back/header-back.component';

@Injectable({
  providedIn: 'root'
})
export class HeaderBackFormService {

  headerBackComponent: HeaderBackComponent;
  set form(form: FormComponent) {
    if (!this.headerBackComponent) {
      return;
    }
    this.headerBackComponent.form = form;
  }

  constructor() { }

  goNext(form: FormComponent) {
    form.nextPage();
  }

  canGoBack(form: FormComponent) {
    return !form || !form.previousPage();
  }
}
