// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `merz-button-icon ion-icon {
  font-size: 25px;
}

ion-title {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}
ion-title merz-text {
  text-align: center;
}

ion-toolbar .button {
  padding: 0px 8px;
}
ion-toolbar .button .back-icon {
  width: 30px;
  height: 30px;
}
ion-toolbar .button .close-icon {
  width: 34px;
  height: 34px;
}
ion-toolbar .progress-bar {
  width: 100%;
  height: 6px;
  background-color: var(--ion-color-lines);
}
ion-toolbar .progress-bar .progress-bar-inside {
  height: 6px;
  background-color: var(--ion-color-black);
}

.bottom-toolbar {
  --min-height: 9px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header-polls/header-polls.component.scss"],"names":[],"mappings":"AACE;EACI,eAAA;AAAN;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,+BAAA;AADF;AAGE;EACI,kBAAA;AADN;;AAME;EACE,gBAAA;AAHJ;AAKI;EACE,WAAA;EACA,YAAA;AAHN;AAMI;EACE,WAAA;EACA,YAAA;AAJN;AAQE;EACE,WAAA;EACA,WAAA;EACA,wCAAA;AANJ;AAQI;EACE,WAAA;EACA,wCAAA;AANN;;AAWA;EACE,iBAAA;AARF","sourcesContent":["merz-button-icon {\n  ion-icon {\n      font-size: 25px;\n  }\n}\n\nion-title {\n  position: absolute;\n  right: 50%;\n  top: 50%;\n  transform: translate(50%, -50%);\n\n  merz-text {\n      text-align: center;\n  }\n}\n\nion-toolbar {\n  .button {\n    padding: 0px 8px;\n\n    .back-icon {\n      width: 30px;\n      height: 30px;\n    }\n\n    .close-icon {\n      width: 34px;\n      height: 34px;\n    }\n  }\n\n  .progress-bar {\n    width: 100%;\n    height: 6px;\n    background-color: var(--ion-color-lines);\n\n    .progress-bar-inside {\n      height: 6px;\n      background-color: var(--ion-color-black);\n    }\n  }\n}\n\n.bottom-toolbar {\n  --min-height: 9px;\n}\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
