import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { Component } from 'src/app/models/component';

@Injectable({
  providedIn: 'root'
})
export class ComponentApiClientService  extends ApiClientService<Component> {
  endpoint = 'component';
}

