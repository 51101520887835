import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateCardBoxComponent } from './date-card-box.component';
import { TextModule } from '../text/text.module';



@NgModule({
  declarations: [
    DateCardBoxComponent
  ],
  imports: [
    CommonModule,
    TextModule
  ],
  exports: [
    DateCardBoxComponent
  ]
})
export class DateCardBoxModule { }
