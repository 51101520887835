// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  display: flex;
  align-items: baseline;
}
.card .number {
  line-height: 104px;
  margin-right: -28px;
  z-index: 10;
}
.card ion-card {
  width: 100%;
  max-width: 272px;
  height: 393px;
  display: flex;
  align-items: flex-end;
}
.card ion-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.card ion-card:hover {
  box-shadow: 0px 0px 30px var(--ion-color-shadow);
}
.card .img-hover-container {
  width: 70px;
  height: 70px;
  background-color: rgba(0, 35, 51, 0.4);
  border-radius: 50%;
  border: 1px solid var(--ion-color-white);
  position: absolute;
  top: 40%;
  left: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.card .img-hover-container .image {
  width: 100%;
  max-width: 32px;
  height: 34px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/video-top-card/video-top-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;AACF;AACE;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;AACJ;AAEE;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,qBAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAAN;AAGI;EACE,gDAAA;AADN;AAKE;EACE,WAAA;EACA,YAAA;EACA,sCAAA;EACA,kBAAA;EACA,wCAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;AAHJ;AAKI;EACE,WAAA;EACA,eAAA;EACA,YAAA;AAHN","sourcesContent":[".card {\n  display: flex;\n  align-items: baseline;\n\n  .number {\n    line-height: 104px;\n    margin-right: -28px;\n    z-index: 10;\n  }\n\n  ion-card {\n    width: 100%;\n    max-width: 272px;\n    height: 393px;\n    display: flex;\n    align-items: flex-end;\n\n    img {\n      width: 100%;\n      height: 100%;\n      object-fit: cover;\n      border-radius: 8px;\n    }\n\n    &:hover {\n      box-shadow: 0px 0px 30px var(--ion-color-shadow);\n    }\n  }\n\n  .img-hover-container {\n    width: 70px;\n    height: 70px;\n    background-color: rgba(0, 35, 51, 0.4);\n    border-radius: 50%;\n    border: 1px solid var(--ion-color-white);\n    position: absolute;\n    top: 40%;\n    left: 49%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1;\n\n    .image {\n      width: 100%;\n      max-width: 32px;\n      height: 34px;\n    }\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
