import { Filter } from './filter';

export class FilterEmitComponent {
    filter: any;
    filterEmitTimeout: any;

    filterEmit(value: any) {
        if (this.filterEmitTimeout) {
            clearTimeout(this.filterEmitTimeout);
        }

        if(this.filter) {
            this.filterEmitTimeout = setTimeout(() => {
                this.filter.changeValue(value);
            }, 500);
        }
    }
  }
