// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
  --inner-border-width: 0;
}

.speakers {
  display: flex;
}
.speakers .number {
  display: grid;
  place-items: center;
  background: var(--ion-color-primary);
  border-radius: 100%;
  border: 1px solid var(--ion-color-lines);
  width: 17px;
  min-width: 17px;
  height: 17px;
  margin-left: -27px;
  margin-top: 16px;
  margin-right: 16px;
}

ion-thumbnail {
  --size: 29px;
  --border-radius: 100%;
  background: var(--ion-color-background);
  border: 1px solid var(--ion-color-lines);
  margin: 0 16px 0 0;
}
ion-thumbnail.with-no-image {
  padding: 6px;
}
ion-thumbnail.back {
  margin-left: -30px;
  z-index: -1;
  padding: 6px;
}

.arrow {
  display: flex;
  margin-top: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/sections/speakers-small-block/speakers-small-block.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,oCAAA;EACA,mBAAA;EACA,wCAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AAGA;EACE,YAAA;EACA,qBAAA;EACA,uCAAA;EACA,wCAAA;EACA,kBAAA;AAAF;AAEE;EACE,YAAA;AAAJ;AAGE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AADJ;;AAKA;EACE,aAAA;EACA,eAAA;AAFF","sourcesContent":[":host {\n  display: block;\n}\n\nion-item{\n  --padding-start: 0;\n  --inner-padding-end: 0;\n  --inner-border-width: 0;\n}\n\n.speakers {\n  display: flex;\n\n  .number {\n    display: grid;\n    place-items: center;\n    background: var(--ion-color-primary);\n    border-radius: 100%;\n    border: 1px solid var(--ion-color-lines);\n    width: 17px;\n    min-width: 17px;\n    height: 17px;\n    margin-left: -27px;\n    margin-top: 16px;\n    margin-right: 16px;\n  }\n}\n\nion-thumbnail {\n  --size: 29px;\n  --border-radius: 100%;\n  background: var(--ion-color-background);\n  border: 1px solid var(--ion-color-lines);\n  margin: 0 16px 0 0;\n\n  &.with-no-image {\n    padding: 6px;\n  }\n\n  &.back {\n    margin-left: -30px;\n    z-index: -1;\n    padding: 6px;\n  }\n}\n\n.arrow {\n  display: flex;\n  margin-top: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
