import { Injectable } from '@angular/core';
import { CancelScrollService } from '../cancel-scroll/cancel-scroll.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  text: string = '';
  content: string = '';
  colorClass: string = '';

  constructor(
    protected cancelScrollService: CancelScrollService,
  ) { }

  showEvent = new Subject<boolean>();
  private _show: boolean = false;
  get show() { return this._show }
  set show(value: boolean) { this._show = value}

  /**
   * General method, use specific ones
   */
  showCustom(text: string, content: string, colorClass: string, closeTime = -1) {
    this.show = true;
    this.text = text;
    this.content = content;
    this.colorClass = colorClass;

    if (closeTime > 0)
      setTimeout(() => this.hide(), closeTime)
  }

  showSuccess(text: string, content: string, closeTime = 8000) {
    this.showCustom(text, content, ColorClass.SUCCESS, closeTime);
  }

  showError(text: string, content: string, closeTime = 8000) {
    this.showCustom(text, content, ColorClass.ERROR, closeTime);
  }

  showWarning(text: string, content: string, closeTime = 8000) {
    this.showCustom(text, content, ColorClass.WARNING, closeTime);
  }

  hide() {
    this._show = false;
    this.cancelScrollService.restoreBodyScroll();
  }
}

export enum ColorClass {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success'
}
