import { Component, Input, OnInit } from '@angular/core';
import { Speaker } from 'src/app/models/speaker';

@Component({
  selector: 'merz-avatar-name-section',
  templateUrl: './avatar-name-section.component.html',
  styleUrls: ['./avatar-name-section.component.scss']
})
export class AvatarNameSectionComponent implements OnInit {
  @Input() speaker: Speaker;

  constructor() { }

  ngOnInit(): void {
  }

  get image(){
    return this.speaker?.image;
  }
  get name(){
    return this.speaker?.title ? `${this.speaker?.title}. ${this.speaker?.name}`: this.speaker?.name;
  }
}
