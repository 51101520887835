import { Injectable, InjectionToken } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { ResetPasswordConfirm } from '../../../models/reset-password-confirm';

@Injectable({
  providedIn: 'root'
})
export class ResetConfirmPasswordApiClientService extends ApiClientService<ResetPasswordConfirm> {
  override endpoint = 'rest-auth/password/reset/confirm';

  resetConfirm(payload: any) {
    return this.create(payload);
  }

  /* get resetPasswordQuestions(): Question<any>[] {
    return [
      new InputQuestion({
        key: 'new_password1',
        label: 'Nueva contraseña',
        required: true,
        type: 'password'
      }),
      new InputQuestion({
        key: 'new_password2',
        label: 'Repetir nueva contraseña',
        required: true,
        type: 'password'
      })
    ];
  } */
}
