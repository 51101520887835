import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Plan } from 'src/app/models/plan';
import { DeleteAccountCausesApiClientService } from '../api/delete-account-causes-api-client/delete-account-causes-api-client.service';
import { BaseListResolver } from './base-list.resolver';

@Injectable({
  providedIn: 'root',
})
export class DeleteAccountCausesResolver extends BaseListResolver<any> {
  protected pageSize = 20;

  constructor(protected service: DeleteAccountCausesApiClientService) {
    super();
  }

  resolve(): Observable<any> {
    return this.service
      .pageSize(this.pageSize)
      .all()
      .query.execute()
      .pipe(
        map((paginator) => {
          return paginator['results'];
        }),
        catchError((error) => {
          return of(undefined);
        })
      );
  }
}
