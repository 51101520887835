import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HeaderBackComponent } from './header-back/header-back.component';
import { TextModule } from '../text/text.module';
import { ButtonModule } from '../button/button.module';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { HeaderHomeComponent } from './header-home/header-home.component';
import { HeaderGalleryComponent } from './header-gallery/header-gallery.component';
import { HeaderUsersComponent } from './header-users/header-users.component';
import { HeaderKamWorkcenterComponent } from './header-kam-workcenter/header-kam-workcenter.component';
import { HeaderWorkcenterComponent } from './header-workcenter/header-workcenter.component';
import { HeaderEditUserComponent } from './header-edit-user/header-edit-user.component';
import { HeaderGalleryContentsComponent } from './header-gallery-contents/header-gallery-contents.component';
import { HeaderDesktopComponent } from './header-desktop/header-desktop.component';
import { TabsComponentModule } from '../tabs/tabs.module';
import { HeaderProfileMenuComponentModule } from '../header-profile-menu/header-profile-menu.module';
import { InputModule } from '../input/input.module';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderPollsComponent } from './header-polls/header-polls.component';


@NgModule({
  declarations: [
    HeaderBackComponent,
    HeaderComponent,
    HeaderHomeComponent,
    HeaderGalleryComponent,
    HeaderUsersComponent,
    HeaderKamWorkcenterComponent,
    HeaderWorkcenterComponent,
    HeaderEditUserComponent,
    HeaderGalleryContentsComponent,
    HeaderDesktopComponent,
    HeaderPollsComponent,
  ],
  imports: [
    CommonModule,
    TextModule,
    ButtonModule,
    IonicModule,
    RouterModule,
    TabsComponentModule,
    HeaderProfileMenuComponentModule,
    InputModule,
    TranslateModule,
    NgOptimizedImage,
  ],
  exports: [
    HeaderBackComponent,
    HeaderComponent,
    HeaderHomeComponent,
    HeaderGalleryComponent,
    HeaderUsersComponent,
    HeaderKamWorkcenterComponent,
    HeaderWorkcenterComponent,
    HeaderEditUserComponent,
    HeaderGalleryContentsComponent,
    HeaderDesktopComponent,
    HeaderPollsComponent,
  ],
})
export class HeaderModule {}
