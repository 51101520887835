import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { MERZ_ROUTES } from 'src/app/merz-routes';

@Component({
  selector: 'merz-poll-modal',
  templateUrl: './poll-modal.component.html',
  styleUrls: ['./poll-modal.component.scss'],
})
export class PollModalComponent implements OnInit {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() pollId = '';

  constructor(
    private modalController: ModalController,
    protected navController: NavController
  ) {}

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss();
  }

  goToPoll() {
    this.dismiss();
    this.navController.navigateForward([MERZ_ROUTES.pollIdComplete.replace('${pollId}', this.pollId)]);
  }
}
