// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}

ion-card {
  display: flex;
  padding: 6px 15px 7px 6px;
  margin-top: 0;
}
ion-card ion-thumbnail {
  position: relative;
  --size: 114px;
  --border-radius: 5px;
  background: var(--ion-color-background);
  min-width: 114px;
  margin-right: 15px;
}
ion-card merz-time-small-block {
  position: absolute;
  right: 4.5px;
  bottom: 6px;
}
ion-card merz-can-see-plan-tag {
  position: absolute;
  left: 4.5px;
  top: 6px;
}
ion-card ion-card-content {
  padding: 10px 0 7px;
}
ion-card ion-card-content .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--ion-color-dark);
  line-height: 23px;
  text-align: left;
  display: block;
  display: -webkit-box;
  height: 70px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card ion-card-content merz-text {
  margin-top: 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/lateral-video-card/lateral-video-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;;AAGA;EACE,aAAA;EACA,yBAAA;EACA,aAAA;AAAF;AAEE;EACE,kBAAA;EACF,aAAA;EACA,oBAAA;EACA,uCAAA;EACE,gBAAA;EACA,kBAAA;AAAJ;AAIE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AAFJ;AAKE;EACE,kBAAA;EACA,WAAA;EACA,QAAA;AAHJ;AAOE;EACE,mBAAA;AALJ;AAOI;EACE,eAAA;EACA,gBAAA;EACA,4BAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;EACA,YAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;AALN;AAQI;EACE,eAAA;AANN","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n}\n\n\nion-card {\n  display: flex;\n  padding: 6px 15px 7px 6px;\n  margin-top: 0;\n\n  ion-thumbnail {\n    position: relative;\n\t\t--size: 114px;\n\t\t--border-radius: 5px;\n\t\tbackground: var(--ion-color-background);\n    min-width: 114px;\n    margin-right: 15px;\n\t}\n\n\n  merz-time-small-block {\n    position: absolute;\n    right: 4.5px;\n    bottom: 6px;\n  }\n\n  merz-can-see-plan-tag {\n    position: absolute;\n    left: 4.5px;\n    top: 6px;\n  }\n\n\n  ion-card-content {\n    padding: 10px 0 7px;\n\n    .title {\n      font-size: 18px;\n      font-weight: 500;\n      color: var(--ion-color-dark);\n      line-height: 23px;\n      text-align: left;\n      display: block;\n      display: -webkit-box;\n      height: 70px;\n      -webkit-line-clamp: 3;\n      -webkit-box-orient: vertical;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n\n    merz-text {\n      margin-top: 7px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
