export interface IStorageService {
    sessionToken: string;
    expirationDate: Date;
    saveObject(name: string, value: any): any;
    getObject(name: string): any;
    deleteObject(name: string): any;
    deleteSessionData(): any;
}

export interface IUserCredentials {
    email: string;
    password: string;
    userName?: string;
}

export interface IPasswordReset {
    newPassword1: string,
    newPassword2: string,
    uid: string,
    token: string
}

export interface IPasswordChange {
    oldPassword: string,
    newPassword1: string,
    newPassword2: string,
}
