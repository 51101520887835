import { Injectable } from '@angular/core';
import { ApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { UserDevice } from 'src/app/models/user-device';

@Injectable({
  providedIn: 'root'
})
export class UserDeviceApiClientService extends ApiClientService<UserDevice> {
  endpoint = 'userdevice';
}
