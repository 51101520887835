// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
}

ion-card {
  display: block;
  width: 222px;
  height: 222px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
ion-card .content {
  width: 100%;
  height: 100%;
  position: relative;
  background: transparent linear-gradient(0deg, var(--ion-color-home-slide-dg1) 0%, var(--ion-color-home-slide-dg2) 45%) 0% 0% no-repeat padding-box;
}
ion-card merz-free-tag {
  position: absolute;
  right: 16px;
  top: 16px;
}
ion-card merz-can-see-plan-tag {
  position: absolute;
  left: 16px;
  top: 16px;
}
ion-card .title {
  font-size: 16px;
  font-weight: 500;
  color: var(--ion-color-white);
  position: absolute;
  bottom: 18px;
  left: 13px;
  text-align: left;
  width: calc(100% - 26px);
}
ion-card .ellipsis {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: 21px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/free-content-card/free-content-card.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,uBAAA;EAAA,kBAAA;AACF;;AAGA;EACE,cAAA;EACA,YAAA;EACA,aAAA;EASA,2BAAA;EACA,4BAAA;EACA,sBAAA;AARF;AADE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kJAAA;AAGJ;AAIE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;AAFJ;AAKE;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AAHJ;AAME;EACE,eAAA;EACA,gBAAA;EACA,6BAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,gBAAA;EACA,wBAAA;AAJJ;AAOE;EACE,qBAAA;EACA,4BAAA;EACA,oBAAA;EACA,gBAAA;EACA,iBAAA;AALJ","sourcesContent":[":host {\n  display: block;\n  width: fit-content;\n}\n\n\nion-card {\n  display: block;\n  width: 222px;\n  height: 222px;\n\n  .content {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    background: transparent linear-gradient(0deg, var(--ion-color-home-slide-dg1) 0%, var(--ion-color-home-slide-dg2) 45%) 0% 0% no-repeat padding-box;\n  }\n\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  merz-free-tag {\n    position: absolute;\n    right: 16px;\n    top: 16px;\n  }\n\n  merz-can-see-plan-tag {\n    position: absolute;\n    left: 16px;\n    top: 16px;\n  }\n\n  .title {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--ion-color-white);\n    position: absolute;\n    bottom: 18px;\n    left: 13px;\n    text-align: left;\n    width: calc(100% - 26px);\n  }\n\n  .ellipsis {\n    -webkit-line-clamp: 3;\n    -webkit-box-orient: vertical;\n    display: -webkit-box;\n    overflow: hidden;\n    line-height: 21px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
