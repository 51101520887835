import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import { TechnicalSupport } from 'src/app/models/technical-support';

@Component({
  selector: 'merz-support-product-card',
  templateUrl: './support-product-card.component.html',
  styleUrls: ['./support-product-card.component.scss'],
})
export class SupportProductCardComponent implements OnInit {
  @Input() product: Product;
  @Input('technical-support') technicalSupport: TechnicalSupport;

  constructor() {}

  ngOnInit(): void {}

  get productSupportTitle() {
    return this.technicalSupport?.title;
  }

  get productSupportText() {
    return this.technicalSupport?.description;
  }

  get productSupportEmail() {
    return this.technicalSupport?.email;
  }

  get productSupportPhone() {
    return this.technicalSupport?.phone;
  }

  get productSupportButtonTitle() {
    return this.technicalSupport?.buttonTitle;
  }

  get productSupportButtonUrl() {
    return this.technicalSupport?.buttonUrl;
  }

  sendEmail() {
    if (window) {
      const emailTo = this.productSupportEmail;
      window.open(`mailto:${emailTo}`, '_self');
    }
  }

  openLink() {
    if (window) {
      const url = this.productSupportButtonUrl;
      window.open(url, '_blank');
    }
  }
}
