// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.premium {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px 10px;
  background: var(--ion-color-premium-bg);
  max-width: -moz-fit-content;
  max-width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/app/components/premium-tag/premium-tag.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,uCAAA;EACA,2BAAA;EAAA,sBAAA;AACF","sourcesContent":[".premium {\n  display: flex;\n  align-items: center;\n  border-radius: 5px;\n  padding: 5px 10px;\n  background: var(--ion-color-premium-bg);\n  max-width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
