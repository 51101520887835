import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadAnimationService {
  public showLoadingEvent = new Subject<boolean>();

  private timeout;
  private loading = 0;

  constructor() { }

  moreLoading() {
    this.loading += 1;
    this.event();
  }

  lessLoading() {
    this.loading -= 1;
    this.event();
  }

  private event() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    this.timeout = setTimeout(() => {
      this.showLoadingEvent.next(this.loading != 0);
    }, 200);
  }
}
