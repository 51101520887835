// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.top .text {
  padding: 33px 0 18px;
  max-width: 218px;
  margin: 0 auto;
  text-align: center;
}

.bottom {
  border-top: 1px solid var(--ion-color-lines);
  display: grid;
  place-items: center;
  height: 53px;
}

.margin-5 {
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/popover/unfavorite-popover/unfavorite-popover.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAGE;EACE,oBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,4CAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AADF;;AAIA;EACE,eAAA;AADF","sourcesContent":[":host {\n  display: block;\n}\n\n.top {\n  .text {\n    padding: 33px 0 18px;\n    max-width: 218px;\n    margin: 0 auto;\n    text-align: center;\n  }\n}\n\n.bottom {\n  border-top: 1px solid var(--ion-color-lines);\n  display: grid;\n  place-items: center;\n  height: 53px;\n}\n\n.margin-5 {\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
