import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginApiClientService } from 'projects/dnest-core-angular/src/public-api';
import { Observable } from 'rxjs';
import { MERZ_ROUTES } from 'src/app/merz-routes';

@Injectable({
  providedIn: 'root'
})
export class GoToRootGuardService {

  routes: any;

  constructor(
    protected loginApiClientService: LoginApiClientService,
    protected router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.loginApiClientService.isLoggedIn()) {
      this.router.navigate([MERZ_ROUTES.homeComplete], {
        queryParams: next.queryParams,
      });
      return false;
    } else {
      return true;
    }
  }
}
