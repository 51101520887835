import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataResolverService {

  constructor() { }

  getData(activatedRoute: ActivatedRoute, key: string) {
    let ref = activatedRoute.snapshot;
    while (ref) {
      let value = ref.data[key];
      if (value) {
        return value;
      }
      ref = ref.parent;
    }

    return undefined;
  }
}
