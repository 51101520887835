import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from './lib/components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LibraryConfigService, LibraryConfig } from './config';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    ComponentsModule,
    ClipboardModule,
  ],
  exports: [
    ComponentsModule,
  ]
})
export class DnestCoreAngularModule {
  static forRoot(config: LibraryConfig): ModuleWithProviders<DnestCoreAngularModule> {
    return {
      ngModule: DnestCoreAngularModule,
      providers: [
        {
          provide: LibraryConfigService,
          useValue: config
        }
      ]
    }
  }
}
